import React, { FC, useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { AdminNav, ButtonRow, Icon } from '../components';
import { useAdminNav } from '../hooks/useAdminNav';
import { useBranchingDragDrop } from '../hooks/useBranchingDragDrop';
import pinStore from '../stores/PinStore';
import { CollectionLogic, IPin } from '../types';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { BranchingPinView } from '../components/BranchingPinView';
import { sortPins } from '../utilities/pinOrderUtils';
import './BranchView.css';


export const BranchView: FC = observer(() => {
  const navigate = useNavigate();
  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole,
    selectedProjectDetails
  } = useAdminNav();

  // Add state for current parent pin
  const [currentParentPin, setCurrentParentPin] = useState<IPin | null>(null);
  const [navigationHistory, setNavigationHistory] = useState<IPin[]>([]);
  const [showPinEditor, setShowPinEditor] = useState(false);
  const [newPinData, setNewPinData] = useState<{
    level: number;
    parentPinKey: string | null;
  } | null>(null);

  // Memoize the sorted pins first
  const sortedPins = React.useMemo(() => 
    sortPins(Array.from(pinStore.pins.values()), CollectionLogic.Branching)
  , [pinStore.pins]);

  // Get the first root pin (branch = 0)
  const rootPins = React.useMemo(() => 
    sortedPins.filter(pin => pin.pinOrder?.branch === 0)
  , [sortedPins]);

  // Set the first root pin as current parent by default
  useEffect(() => {
    if (!currentParentPin && rootPins.length > 0) {
      setCurrentParentPin(rootPins[0]);
    }
  }, [rootPins, currentParentPin]);

  // Get current level pins and parent level pins
  const { parentLevelPins, childLevelPins } = React.useMemo(() => {
    if (!currentParentPin) {
      // At root level - show first root pin and its children
      const firstRootPin = rootPins[0];
      return {
        parentLevelPins: firstRootPin ? [firstRootPin] : [],
        childLevelPins: firstRootPin 
          ? sortedPins.filter(pin => pin.branchParentPinKey === firstRootPin.pinKey)
          : []
      };
    }

    // Show current parent's level and its children
    return {
      parentLevelPins: [currentParentPin],
      childLevelPins: sortedPins.filter(pin => 
        pin.branchParentPinKey === currentParentPin.pinKey
      )
    };
  }, [sortedPins, currentParentPin, rootPins]);

  // Memoize the current tree
  const currentTree = React.useMemo(() => {
    const tree = new Map<number, IPin[]>();
    
    if (!currentParentPin) {
      // Root view - show root and its immediate children
      const rootPin = sortedPins.find(pin => 
        pin.pinOrder?.branch === 0 && 
        !pin.branchParentPinKey // Ensure it's a root pin
      );
      
      if (rootPin) {
        tree.set(0, [rootPin]);
        
        // Get all first level children (branch 1.x)
        const firstLevelPins = sortedPins.filter(pin => 
          pin.branchParentPinKey === rootPin.pinKey &&
          pin.pinOrder?.mainBranch === 1
        );

        if (firstLevelPins.length > 0) {
          const sortedFirstLevel = [...firstLevelPins].sort((a, b) => 
            (a.pinOrder?.subBranch || 0) - (b.pinOrder?.subBranch || 0)
          );
          tree.set(1, sortedFirstLevel);
        }
      }
    } else {
      // Selected pin view - show current pin and its children
      tree.set(0, [currentParentPin]); // Current pin at level 0
      
      // Get immediate children of the current pin
      const childPins = sortedPins.filter(pin => 
        pin.branchParentPinKey === currentParentPin.pinKey
      );

      if (childPins.length > 0) {
        const sortedChildren = [...childPins].sort((a, b) => 
          (a.pinOrder?.subBranch || 0) - (b.pinOrder?.subBranch || 0)
        );
        tree.set(1, sortedChildren);
      }
    }

    
    return tree;
  }, [sortedPins, currentParentPin]);

  const handlePinClick = (pin: IPin) => {
    // Add current parent to history before changing
    if (currentParentPin) {
      setNavigationHistory(prev => [...prev, currentParentPin]);
    }
    setCurrentParentPin(pin);
  };

  const handleNavigateUp = () => {
    if (navigationHistory.length > 0) {
      // Pop the last parent from history
      const newHistory = [...navigationHistory];
      const lastParent = newHistory.pop();
      setNavigationHistory(newHistory);
      setCurrentParentPin(lastParent || null);
    } else {
      // If no history, go to root
      setCurrentParentPin(null);
    }
  };

  // Add state for sibling navigation
  const siblingPins = React.useMemo(() => {
    if (!currentParentPin?.pinOrder) return [];
    // Get all pins at the same level with the same parent
    return sortedPins.filter(pin => 
      pin.pinOrder?.id === currentParentPin.pinOrder?.id &&
      pin.branchParentPinKey === currentParentPin.branchParentPinKey
    );
  }, [sortedPins, currentParentPin]);

  const currentSiblingIndex = React.useMemo(() => {
    if (!currentParentPin) return -1;
    return siblingPins.findIndex(pin => pin.pinKey === currentParentPin.pinKey);
  }, [siblingPins, currentParentPin]);

  const handleNavigateLeft = () => {
    if (currentSiblingIndex > 0) {
      const previousSibling = siblingPins[currentSiblingIndex - 1];
      setCurrentParentPin(previousSibling);
    }
  };

  const handleNavigateRight = () => {
    if (currentSiblingIndex < siblingPins.length - 1) {
      const nextSibling = siblingPins[currentSiblingIndex + 1];
      setCurrentParentPin(nextSibling);
    }
  };

  // Use the branching-specific drag and drop hook
  const {
    isOrphanDragging,
    dragOverPinKey,
    onDragStart,
    onDragUpdate,
    onDragEnd
  } = useBranchingDragDrop({
    selectedProject,
    sortedPins
  });

  // Fetch pins only when selectedProject changes
  useEffect(() => {
    if (selectedProject) {
      pinStore.getPinKeysForProject(selectedProject);
    }
  }, [selectedProject]); // Only depend on selectedProject

  const handleAddPin = (level: number, parentPinKey: string | null) => {
    setNewPinData({
      level,
      parentPinKey
    });
    setShowPinEditor(true);
  };

  const handlePinSave = async (pin: IPin) => {
    // Your existing pin save logic
    setShowPinEditor(false);
    setNewPinData(null);
  };

  return (
    <div className="branch-view">
      <AdminNav
        imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
        projectName={projectName}
        userRole={userRole}
        projects={projects}
        selectedProject={selectedProject}
        onProjectChange={onProjectChange}
      />

      <ButtonRow
        settingsIcon={<Icon icon="settings" size={24} />}
        showLocationInput={false}
        showAddNewPinButton={true}
        handleMapViewClick={() => navigate("/map-view")}
        handleListViewClick={() => navigate("/pin-list-view")}
        defaultView="branch"
        userRole={userRole}
        selectedProjectDetails={selectedProjectDetails}
      />

      <div className="branch-content">
        {/* Top navigation */}
        <div className="branch-header" style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          position: 'relative',
          width: '100%',
          padding: '16px',
          marginTop: '2%'
        }}>
          {currentParentPin?.branchParentPinKey && (
            <IconButton 
              onClick={handleNavigateUp}
              title="Go up a level"
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: '-20px',
                backgroundColor: 'transparent'
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
          )}
          <span style={{ 
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 500
          }}>
            {currentParentPin?.name || 'Root Level'}
          </span>
        </div>

        {/* Main content - Add styling to ensure visibility */}
        <div className="branch-tree-container" style={{ 
          padding: '20px',
          minHeight: '300px',
          backgroundColor: '#f5f5f5'  // temporary background to see the container
        }}>
          <DragDropContext
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragUpdate={onDragUpdate}
          >
            {currentTree.size > 0 ? (
              <BranchingPinView
                branchingTree={currentTree}
                isOrphanDragging={isOrphanDragging}
                dragOverPinKey={dragOverPinKey}
                onPinClick={handlePinClick}
                onAddPin={handleAddPin}
              />
            ) : (
              <div>Loading pins...</div>
            )}
          </DragDropContext>
        </div>

        {/* Bottom navigation - using custom Icon component */}
        {currentParentPin && siblingPins.length > 1 && (
          <div className="bottom-nav" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            padding: '16px',
            width: '100%',
            position: 'relative'
          }}>
            <Icon 
              icon="back"
              onClick={handleNavigateLeft}
              size={36}
              style={{ 
                opacity: currentSiblingIndex <= 0 ? 0.5 : 1,
                position: 'absolute',
                left: '16px'
              }}
            />
            <span style={{
              fontSize: '1rem',
              fontWeight: 500
            }}>
              {currentSiblingIndex + 1} / {siblingPins.length}
            </span>
            <Icon 
              icon="forward"
              onClick={handleNavigateRight}
              size={36}
              style={{ 
                opacity: currentSiblingIndex >= siblingPins.length - 1 ? 0.5 : 1,
                position: 'absolute',
                right: '16px'
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default BranchView;
