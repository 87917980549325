// src/stores/UserStore.ts
import { makeAutoObservable, runInAction } from "mobx";
import { OrganisationManager } from "../models/OrganisationManager";
import { IOrganisation, IMember, MemberRole } from "../types/organisation";
import authenticationStore from "./AuthenticationStore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

interface IUser {
  userId: string;
  email?: string;
  displayName?: string;
  userRole?: MemberRole;
  organisations?: {
    [orgId: string]: IMember;
  };
  projectAdminAccess?: string[];
}

export class UserStore {
  user: IUser | null = null;
  currentOrganisation: IOrganisation | null = null;
  loading = false;
  error: Error | null = null;

  constructor() {
    makeAutoObservable(this);
    this.loadUserFromLocalStorage();
  }

  setError(error: Error | null) {
    this.error = error;
  }

  setUser(user: IUser) {
    if (!user.userRole && (!user.email || !user.displayName)) {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        user = {
          ...user,
          email: user.email || currentUser.email || '',
          displayName: user.displayName || currentUser.displayName || '',
        };
      }
    }
    
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
    if (user.userRole) {
      localStorage.setItem('userRole', user.userRole);
    }
  }

  setCurrentOrganisation(org: IOrganisation | null) {
    this.currentOrganisation = org;
    if (org) {
      localStorage.setItem('currentOrganisation', JSON.stringify(org));
    } else {
      localStorage.removeItem('currentOrganisation');
    }
  }

  clearUser() {
    this.user = null;
    this.currentOrganisation = null;
    localStorage.removeItem('user');
    localStorage.removeItem('userRole');
    localStorage.removeItem('currentOrganisation');
  }

  loadUserFromLocalStorage() {
    const userStr = localStorage.getItem('user');
    const legacyUserRole = localStorage.getItem('userRole');
    const orgStr = localStorage.getItem('currentOrganisation');
    
    if (userStr) {
      try {
        this.user = JSON.parse(userStr);
      } catch (e) {
        console.error('Failed to parse user from localStorage');
        // Fallback to legacy userRole if available
        if (legacyUserRole) {
          this.user = { 
            userId: '',
            userRole: legacyUserRole as MemberRole
          };
        }
      }
    } else if (legacyUserRole) {
      // Support legacy userRole-only storage
      this.user = { 
        userId: '',
        userRole: legacyUserRole as MemberRole
      };
    }

    if (orgStr) {
      try {
        this.currentOrganisation = JSON.parse(orgStr);
      } catch (e) {
        console.error('Failed to parse organisation from localStorage');
      }
    }
  }

  // Legacy getter for backward compatibility
  get userRole(): string {
    return this.user?.userRole || '';
  }

  get isOrgAdmin(): boolean {
    if (!this.user) return false;
    if (this.user.userRole === 'superAdmin') return true;
    if (!this.currentOrganisation) return false;
    return this.user.organisations?.[this.currentOrganisation.id]?.role === 'organisationAdmin';
  }

  get isOrgModerator(): boolean {
    if (!this.user) return false;
    if (this.user.userRole === 'superAdmin') return true;
    if (!this.currentOrganisation) return false;
    return this.user.organisations?.[this.currentOrganisation.id]?.role === 'moderator';
  }

  get currentRole(): MemberRole | null {
    if (!this.user) return null;
    if (this.user.userRole === 'superAdmin') return 'superAdmin';
    if (!this.currentOrganisation) return null;
    return this.user.organisations?.[this.currentOrganisation.id]?.role || null;
  }

  get currentUser() {
    if (!this.user) return null;
    if (this.user.userRole === 'superAdmin') {
      return {
        id: this.user.userId,
        email: this.user.email,
        role: 'superAdmin'
      };
    }
    if (!this.currentOrganisation) return null;
    return {
      id: this.user.userId,
      email: this.user.email,
      organisationId: this.currentOrganisation.id,
      role: this.currentRole
    };
  }

  async loginWithEmailPassword(email: string, password: string, role: MemberRole) {
    try {
      this.loading = true;
      this.error = null;

      // For Project Admin, we don't need to check role in auth
      if (role === 'projectAdmin') {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password);
        const currentUser = auth.currentUser;
        if (currentUser) {
          this.setUser({
            userId: currentUser.uid,
            email: currentUser.email || '',
            displayName: currentUser.displayName || '',
          });
        }
      } else {
        await authenticationStore.signInWithRole(email, password, role);
      }
    } catch (error) {
      runInAction(() => {
        this.error = error as Error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async loginWithAccessCode(accessCode: string, role?: MemberRole) {
    try {
      this.loading = true;
      this.error = null;
      
      const result = await OrganisationManager.verifyAccessCode(accessCode);
      
      if (!result) {
        throw new Error('Invalid access code');
      }

      const { organisationId, role: codeRole } = result;
      
      if (role && role !== codeRole) {
        throw new Error('Access code does not grant the required role');
      }
      
      // Get Organisation details
      const organisation = await OrganisationManager.getOrganisation(organisationId);
      this.setCurrentOrganisation(organisation);
      
    } catch (error) {
      runInAction(() => {
        this.error = error as Error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async switchOrganisation(orgId: string) {
    try {
      this.loading = true;
      const org = await OrganisationManager.getOrganisation(orgId);
      this.setCurrentOrganisation(org);
    } catch (error) {
      runInAction(() => {
        this.error = error as Error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  setSelectedProject(projectId: string) {
    if (this.user?.userId) {
      runInAction(() => {
        this.user = {
          userId: this.user!.userId,
          email: this.user?.email,
          displayName: this.user?.displayName,
          userRole: this.user?.userRole,
          organisations: this.user?.organisations,
          projectAdminAccess: [projectId]
        };
        localStorage.setItem('user', JSON.stringify(this.user));
      });
    }
  }
}

export default new UserStore();
