import { ProjectContext } from '../contexts/ProjectContext';
import { MemberRole } from '../types/organisation';

/**
 * Sends an email notification with form submission details.
 * 
 * This function sends an email to a predefined address with information about
 * a form submission, including the location description and any additional emailBody.
 * 
 * @param {string} locationDesc - The description of the location from the submitted form.
 * @param {string} emailBody - emailBody or additional information to include in the email.
 * @returns {Promise<void>} A promise that resolves when the email is sent successfully, or rejects on failure.
 * @throws {Error} Throws an error if the email sending fails.
 */
export const sendEmailNotification = async (locationDesc: string, emailBody: string): Promise<void> => {
  const response = await fetch('https://email-server-dot-overhear-2.uc.r.appspot.com/api/send-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to: 'hello@theoverhear.app',
      subject: 'FormsView Submitted',
      text: `The form has been submitted with the following details:
      - Location Description: ${locationDesc}
      - emailBody: ${emailBody}`  // Include emailBody in the email body
    }),
  });

  if (!response.ok) {
    console.error('Failed to send email');
    throw new Error('Failed to send email notification');
  }
};

/**
 * Sends an invitation email to join an organization.
 * 
 * @param {string} email - The email address of the invitee.
 * @param {string} orgName - The name of the organization.
 * @param {MemberRole} role - The role being assigned to the invitee.
 * @param {string} inviteLink - The invitation link with access code.
 * @returns {Promise<void>} A promise that resolves when the email is sent successfully.
 * @throws {Error} Throws an error if the email sending fails.
 */
export const sendOrganizationInvite = async (
  email: string,
  orgName: string,
  role: MemberRole,
  inviteLink: string
): Promise<void> => {
  // Get role display name
  const getRoleDisplay = (role: MemberRole) => {
    switch (role) {
      case 'moderator':
        return 'Teacher/Moderator';
      case 'member':
        return 'Student/Member';
      case 'organisationAdmin':
        return 'Administrator';
      default:
        return role;
    }
  };

  const response = await fetch('https://email-server-dot-overhear-2.uc.r.appspot.com/api/send-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to: email,
      subject: `Invitation to join ${orgName}`,
      text: `
Hello,

You have been invited to join ${orgName} as a ${getRoleDisplay(role)}.

To accept this invitation, please click on the following link:
${inviteLink}

This invitation link will expire in 7 days.

If you have any questions, please contact the organization administrator.

Best regards,
The Overhear Team
      `.trim()
    }),
  });

  if (!response.ok) {
    console.error('Failed to send invitation email');
    throw new Error('Failed to send invitation email');
  }
};