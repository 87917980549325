import { storage, db } from '../firebase/firebase';
import { ref, listAll, deleteObject, getMetadata, getDownloadURL } from 'firebase/storage';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { AuthorManager } from './AuthorManager';
import { IRecording } from '../types/Recording';
import { IProject } from '../types/Project';
import { IPin } from '../types/Pin';

export interface StorageFile {
  path: string;
  url?: string;
  size?: number;
  timeCreated: string;
  type: 'author' | 'recording' | 'user_recording' | 'pin' | 'project' | 'message' | 'qr';
  isOrphanedDocument?: boolean;
  isMissingFile?: boolean;
  recordingKey?: string;
  errors?: string[];
  albumKey?: string;
  pinKey?: string;
  documentId?: string;
  replacementPath?: string;
  projectIconReplacements?: Record<string, string>;
  pinIconReplacements?: Record<string, string>;
  referencingDocuments?: Array<{ id: string; name?: string; type: 'project' | 'pin' }>;
}

interface AuthorStorageFile extends StorageFile {
  type: 'author';
}

interface RecordingStorageFile extends StorageFile {
  type: 'recording';
}

export const StorageManager = {
  getOrphanedAuthorImages: async (): Promise<StorageFile[]> => {
    try {
      // Get all authors to check against
      const authors = await AuthorManager.getAllAuthors();
      
      // Get all unique image paths from authors that are actually used
      const authorImages = new Set(authors.map(author => author.image).filter(Boolean));

      // Get all files in the storage
      const path = 'images/author';
      const imagesRef = ref(storage, path);
      const result = await listAll(imagesRef);
      
      // Process all files in storage
      const itemFiles = await Promise.all(
        result.items.map(async (item) => {
          try {
            const metadata = await getMetadata(item);
            const url = await getDownloadURL(item);
            return {
              path: item.fullPath,
              url,
              size: metadata.size || 0,
              timeCreated: metadata.timeCreated || new Date().toISOString(),
              type: 'author' as const
            } as AuthorStorageFile;
          } catch (error) {
            console.error(`Error processing item ${item.fullPath}:`, error);
            return null;
          }
        })
      );

      // Process subdirectories if any
      const prefixFiles = await Promise.all(
        result.prefixes.map(async (prefix) => {
          try {
            const subResult = await listAll(prefix);
            return Promise.all(
              subResult.items.map(async (item) => {
                try {
                  const metadata = await getMetadata(item);
                  const url = await getDownloadURL(item);
                  return {
                    path: item.fullPath,
                    url,
                    size: metadata.size || 0,
                    timeCreated: metadata.timeCreated || new Date().toISOString(),
                    type: 'author' as const
                  } as AuthorStorageFile;
                } catch (error) {
                  console.error(`Error processing item ${item.fullPath}:`, error);
                  return null;
                }
              })
            );
          } catch (error) {
            console.error(`Error listing subdirectory ${prefix.fullPath}:`, error);
            return [];
          }
        })
      );

      // Combine and filter files
      const validItemFiles = itemFiles.filter((file): file is AuthorStorageFile => file !== null);
      const validPrefixFiles = prefixFiles.flat().filter((file): file is AuthorStorageFile => file !== null);
      const allFiles = [...validItemFiles, ...validPrefixFiles];

      // Find orphaned files (files in storage that are not referenced by any author)
      const orphanedFiles = allFiles.filter(file => !Array.from(authorImages).some(imagePath => 
        imagePath != null && (
          imagePath === file.path || 
          (imagePath.startsWith('/') && imagePath.substring(1) === file.path)
        )
      ));

      return orphanedFiles;
    } catch (error) {
      console.error('Error getting orphaned author images:', error);
      throw error;
    }
  },

  getOrphanedRecordings: async (): Promise<StorageFile[]> => {
    try {
      const orphanedFiles: StorageFile[] = [];

      // 1. Get all collections we need to check against
      const [
        recordingsSnapshot, 
        albumsSnapshot, 
        pinsSnapshot,
        wandersSnapshot,
        userResponsesSnapshot
      ] = await Promise.all([
        getDocs(collection(db, 'Recordings')),
        getDocs(collection(db, 'Albums')),
        getDocs(collection(db, 'Pins')),
        getDocs(collection(db, 'Wanders')),
        getDocs(collection(db, 'UserResponses'))
      ]);

      // Create sets of valid keys
      const validAlbumKeys = new Set(albumsSnapshot.docs.map(doc => doc.data().albumKey));
      const validPinKeys = new Set(pinsSnapshot.docs.map(doc => doc.data().pinKey));
      const validWanderAlbumKeys = new Set(wandersSnapshot.docs.map(doc => doc.data().albumKey));
      const validUserResponseRecordingKeys = new Set(userResponsesSnapshot.docs.map(doc => doc.data().recordingKey));

      // Get all recordings
      const recordings = recordingsSnapshot.docs.map(doc => ({
        ...doc.data(),
        key: doc.id
      } as IRecording));

      // 2. Get all existing files in storage first
      const existingFiles = new Set<string>();
      const recordingsRef = ref(storage, 'recordings');
      const userRecordingsRef = ref(storage, 'user_recordings');

      try {
        // Get files from recordings directory
        const recordingsResult = await listAll(recordingsRef);
        await Promise.all(recordingsResult.items.map(async item => {
          existingFiles.add(item.fullPath);
        }));

        // Get files from recordings subdirectories
        await Promise.all(recordingsResult.prefixes.map(async prefix => {
          const subResult = await listAll(prefix);
          subResult.items.forEach(item => existingFiles.add(item.fullPath));
        }));

        // Get files from user_recordings directory
        const userRecordingsResult = await listAll(userRecordingsRef);
        await Promise.all(userRecordingsResult.items.map(async item => {
          existingFiles.add(item.fullPath);
        }));

        // Get files from user_recordings subdirectories
        await Promise.all(userRecordingsResult.prefixes.map(async prefix => {
          const subResult = await listAll(prefix);
          subResult.items.forEach(item => existingFiles.add(item.fullPath));
        }));
      } catch (error) {
        console.error('Error listing storage directories:', error);
      }

      // 3. Process each recording document for issues
      for (const recording of recordings) {
        const errors: string[] = [];
        let shouldInclude = false;
        let isMissingFile = false;
        let isOrphanedDocument = false;

        // Check for broken albumKey
        if (recording.albumKey) {
          const albumExists = validAlbumKeys.has(recording.albumKey) || validWanderAlbumKeys.has(recording.albumKey);
          if (!albumExists) {
            errors.push(`Has albumKey "${recording.albumKey}" but no matching Album or Wander document exists`);
            isOrphanedDocument = true;
            shouldInclude = true;
          }
        }

        // Check for broken pinKey
        if (recording.pinKey && !validPinKeys.has(recording.pinKey)) {
          errors.push(`Has pinKey "${recording.pinKey}" but no matching Pin document exists`);
          isOrphanedDocument = true;
          shouldInclude = true;
        }

        // Check if recording is referenced in UserResponses
        if (recording.key && validUserResponseRecordingKeys.has(recording.key)) {
          // If it's referenced in UserResponses, it's not orphaned regardless of other conditions
          continue;
        }

        // Check for broken file paths
        if (recording.file?.recordingPath && !existingFiles.has(recording.file.recordingPath)) {
          // If the document has valid references but missing file, mark it as a missing file issue
          if (!isOrphanedDocument && recording.albumKey && recording.pinKey) {
            isMissingFile = true;
            errors.push(`⚠️ MISSING FILE: Recording has valid references but the file is missing at path: ${recording.file.recordingPath}`);
          } else {
            errors.push(`Recording file not found at path: ${recording.file.recordingPath}`);
          }
          shouldInclude = true;
        }

        // If any issues were found, add to orphaned files
        if (shouldInclude) {
          orphanedFiles.push({
            path: recording.file?.recordingPath || 'No path specified',
            timeCreated: new Date().toISOString(),
            type: 'recording',
            isOrphanedDocument,
            isMissingFile,
            recordingKey: recording.key,
            albumKey: recording.albumKey || undefined,
            pinKey: recording.pinKey || undefined,
            errors
          });
        }
      }

      // 4. Find storage files not referenced by any recording
      const validRecordingPaths = new Set(
        recordings
          .map(recording => recording.file?.recordingPath)
          .filter(Boolean)
      );

      const orphanedStorageFiles: StorageFile[] = [];
      for (const filePath of existingFiles) {
        if (!validRecordingPaths.has(filePath)) {
          orphanedStorageFiles.push({
            path: filePath,
            timeCreated: new Date().toISOString(),
            type: 'recording',
            errors: ['File exists in storage but no Recording document references it']
          });
        }
      }

      // Sort orphaned files to show missing files first
      const allOrphanedFiles = [...orphanedFiles, ...orphanedStorageFiles]
        .sort((a, b) => {
          if (a.isMissingFile && !b.isMissingFile) return -1;
          if (!a.isMissingFile && b.isMissingFile) return 1;
          return 0;
        });

      return allOrphanedFiles;
    } catch (error) {
      console.error('Error getting orphaned recordings:', error);
      throw error;
    }
  },

  deleteFiles: async (orphanedFiles: StorageFile[]): Promise<void> => {
    try {
      // Extract metadata if it exists
      const metadataFile = orphanedFiles.find(f => f.path === '_metadata');
      const projectIconReplacements = new Map(Object.entries(metadataFile?.projectIconReplacements || {}));
      const pinIconReplacements = new Map(Object.entries(metadataFile?.pinIconReplacements || {}));

      await Promise.all(
        orphanedFiles.map(async (file) => {
          if (file.path === '_metadata') return; // Skip metadata file

          try {
            // If it's a missing project icon with a replacement
            if (file.type === 'project' && file.isMissingFile && file.documentId) {
              const replacement = projectIconReplacements.get(file.path);
              if (replacement) {
                const projectRef = doc(db, 'Projects', file.documentId);
                await updateDoc(projectRef, { icon: replacement });
              }
            }
            // If it's a missing pin icon with a replacement
            else if (file.type === 'pin' && file.isMissingFile && file.documentId) {
              const replacement = pinIconReplacements.get(file.path);
              if (replacement) {
                const pinRef = doc(db, 'Pins', file.documentId);
                await updateDoc(pinRef, { pinIcon: replacement });
              }
            }
            // If it's a QR code file
            else if (file.type === 'qr') {
              if (file.isMissingFile && file.recordingKey) {
                // If it's a missing QR file, update the recording document to remove the reference
                const recordingRef = doc(db, 'Recordings', file.recordingKey);
                await updateDoc(recordingRef, { qrPath: null });
              } else if (!file.isMissingFile) {
                // If it's an orphaned QR file in storage, delete it
                const fileRef = ref(storage, file.path);
                await deleteObject(fileRef);
              }
            }
            // If it's just an orphaned storage file, delete it
            else if (!file.isMissingFile) {
              const fileRef = ref(storage, file.path);
              await deleteObject(fileRef);
            }
          } catch (error) {
            console.error(`Error processing deletion/update for ${file.path}:`, error);
            throw error;
          }
        })
      );
    } catch (error) {
      console.error('Error deleting/updating files:', error);
      throw error;
    }
  },

  getOrphanedIcons: async (): Promise<StorageFile[]> => {
    try {
      const orphanedFiles: StorageFile[] = [];

      // 1. Get all projects and pins
      const [projectsSnapshot, pinsSnapshot] = await Promise.all([
        getDocs(collection(db, 'Projects')),
        getDocs(collection(db, 'Pins'))
      ]);

      // Create sets of valid icon paths and maps for replacements
      const validProjectIcons = new Set<string>();
      const validPinIcons = new Set<string>();
      const projectIconReplacements = new Map<string, string>(); // missing path -> replacement path
      const pinIconReplacements = new Map<string, string>();     // missing path -> replacement path

      // Helper to get base name (everything after the timestamp)
      const getBaseName = (path: string) => {
        const match = path.match(/\d{13}(.+)$/);
        return match ? match[1] : null;
      };

      // Build maps of base names to valid paths for potential replacements
      const projectBaseNames = new Map<string, string[]>();  // base name -> array of valid paths
      const pinBaseNames = new Map<string, string[]>();      // base name -> array of valid paths

      // Process project icons
      projectsSnapshot.docs.forEach(doc => {
        const project = doc.data() as IProject;
        if (project.icon) {
          validProjectIcons.add(project.icon);
          const baseName = getBaseName(project.icon);
          if (baseName) {
            const paths = projectBaseNames.get(baseName) || [];
            paths.push(project.icon);
            projectBaseNames.set(baseName, paths);
          }
        }
      });

      // Process pin icons
      pinsSnapshot.docs.forEach(doc => {
        const pin = doc.data() as IPin;
        if (pin.pinIcon) {
          validPinIcons.add(pin.pinIcon);
          const baseName = getBaseName(pin.pinIcon);
          if (baseName) {
            const paths = pinBaseNames.get(baseName) || [];
            paths.push(pin.pinIcon);
            pinBaseNames.set(baseName, paths);
          }
        }
      });


      // Helper to normalize paths for comparison
      const normalizePath = (path: string) => {
        if (!path) return path;
        // Remove leading slash if present
        path = path.startsWith('/') ? path.substring(1) : path;
        // Handle 'pin-images' vs 'pin-image' directory names
        path = path.replace('pin-image/', 'pin-images/');
        path = path.replace('pin-images/pin-images/', 'pin-images/'); // Handle double prefix
        return path;
      };

      // Update the existingFiles Set to use normalized paths
      const existingFiles = new Set<string>();
      const projectIconsRef = ref(storage, 'project-icons');
      const pinImageRef = ref(storage, 'pin-image');
      const pinImagesRef = ref(storage, 'pin-images');

      try {
        // Get files from project-icons directory
        const projectIconsResult = await listAll(projectIconsRef);
        await Promise.all(projectIconsResult.items.map(async item => {
          const normalizedPath = normalizePath(item.fullPath);
          existingFiles.add(normalizedPath);
        }));

        // Get files from project-icons subdirectories
        await Promise.all(projectIconsResult.prefixes.map(async prefix => {
          const subResult = await listAll(prefix);
          subResult.items.forEach(item => {
            const normalizedPath = normalizePath(item.fullPath);
            existingFiles.add(normalizedPath);
          });
        }));

        // Try both pin-image and pin-images directories
        const [pinImageResult, pinImagesResult] = await Promise.all([
          listAll(pinImageRef).catch(() => ({ items: [], prefixes: [] })),
          listAll(pinImagesRef).catch(() => ({ items: [], prefixes: [] }))
        ]);


        // Process pin-image files
        await Promise.all([
          ...pinImageResult.items.map(async item => {
            const normalizedPath = normalizePath(item.fullPath);
            existingFiles.add(normalizedPath);
          }),
          ...pinImagesResult.items.map(async item => {
            const normalizedPath = normalizePath(item.fullPath);
            existingFiles.add(normalizedPath);
    
          })
        ]);

        // Process subdirectories from both
        await Promise.all([
          ...pinImageResult.prefixes.map(async prefix => {
            const subResult = await listAll(prefix);
    
            subResult.items.forEach(item => {
              const normalizedPath = normalizePath(item.fullPath);
              existingFiles.add(normalizedPath);
      
            });
          }),
          ...pinImagesResult.prefixes.map(async prefix => {
            const subResult = await listAll(prefix);
    
            subResult.items.forEach(item => {
              const normalizedPath = normalizePath(item.fullPath);
              existingFiles.add(normalizedPath);
      
            });
          })
        ]);


      } catch (error) {
        console.error('Error listing storage directories:', error);
      }


      // 3. Find orphaned files
      for (const filePath of existingFiles) {
        const isProjectIcon = filePath.startsWith('project-icons/');
        const isPinIcon = filePath.startsWith('pin-image/');
        let isOrphaned = false;
        const errors: string[] = [];

        if (isProjectIcon) {
          if (!validProjectIcons.has(filePath)) {
            isOrphaned = true;
            errors.push('Project icon exists in storage but no Project document references it');
          }
        } else if (isPinIcon) {
          if (!validPinIcons.has(filePath)) {
            isOrphaned = true;
            errors.push('Pin icon exists in storage but no Pin document references it');
          }
        }

        if (isOrphaned) {
          try {
            const fileRef = ref(storage, filePath);
            const metadata = await getMetadata(fileRef);
            const url = await getDownloadURL(fileRef);

            orphanedFiles.push({
              path: filePath,
              url,
              size: metadata.size || 0,
              timeCreated: metadata.timeCreated || new Date().toISOString(),
              type: isProjectIcon ? 'project' : 'pin',
              errors
            });
          } catch (error) {
            console.error(`Error getting metadata for ${filePath}:`, error);
            orphanedFiles.push({
              path: filePath,
              timeCreated: new Date().toISOString(),
              type: isProjectIcon ? 'project' : 'pin',
              errors: [...errors, 'Failed to get file metadata']
            });
          }
        }
      }

      // 4. Check for missing icons in documents and find replacements
      // Create a map to group documents by missing icon path
      const missingIconsMap = new Map<string, {
        path: string;
        type: 'project' | 'pin';
        references: Array<{ id: string; name?: string; type: 'project' | 'pin' }>;
        replacement?: string;
      }>();

      // Check projects with missing icons
      projectsSnapshot.docs.forEach(doc => {
        const project = doc.data() as IProject;
        if (project.icon) {
          const normalizedPath = normalizePath(project.icon);
  
  
  
          if (!existingFiles.has(normalizedPath)) {
            const existing = missingIconsMap.get(project.icon) || {
              path: project.icon,
              type: 'project',
              references: [],
              replacement: undefined
            };

            existing.references.push({
              id: doc.id,
              name: project.projectName || undefined,
              type: 'project'
            });

            if (!existing.replacement) {
              const baseName = getBaseName(project.icon);
              if (baseName) {
        
                const possibleReplacements = projectBaseNames.get(baseName) || [];
        
                existing.replacement = possibleReplacements.find(path => {
                  const normalizedReplacementPath = normalizePath(path);
                  const exists = path !== project.icon && existingFiles.has(normalizedReplacementPath);
          
                  return exists;
                });
              }
            }

            missingIconsMap.set(project.icon, existing);
          }
        }
      });

      // Check pins with missing icons
      pinsSnapshot.docs.forEach(doc => {
        const pin = doc.data() as IPin;
        if (pin.pinIcon) {
          const normalizedPath = normalizePath(pin.pinIcon);
  
  
  
          if (!existingFiles.has(normalizedPath)) {
            const existing = missingIconsMap.get(pin.pinIcon) || {
              path: pin.pinIcon,
              type: 'pin',
              references: [],
              replacement: undefined
            };

            existing.references.push({
              id: doc.id,
              name: pin.name || undefined,
              type: 'pin'
            });

            if (!existing.replacement) {
              const baseName = getBaseName(pin.pinIcon);
              if (baseName) {
        
                const possibleReplacements = pinBaseNames.get(baseName) || [];
        
                existing.replacement = possibleReplacements.find(path => {
                  const normalizedReplacementPath = normalizePath(path);
                  const exists = path !== pin.pinIcon && existingFiles.has(normalizedReplacementPath);
          
                  return exists;
                });
              }
            }

            missingIconsMap.set(pin.pinIcon, existing);
          }
        }
      });

      // Convert missing icons map to orphaned files
      for (const [path, info] of missingIconsMap) {
        const errors: string[] = [];
        
        // Add reference errors
        info.references.forEach((ref: { type: 'project' | 'pin'; name?: string; id: string }) => {
          errors.push(`⚠️ MISSING FILE: ${ref.type === 'project' ? 'Project' : 'Pin'} "${ref.name || ref.id}" references this icon`);
        });

        // Add replacement info if available
        if (info.replacement) {
          errors.push(`✅ Found replacement icon: ${info.replacement}`);
          if (info.type === 'project') {
            projectIconReplacements.set(path, info.replacement);
          } else {
            pinIconReplacements.set(path, info.replacement);
          }
        }

        orphanedFiles.push({
          path,
          timeCreated: new Date().toISOString(),
          type: info.type,
          isOrphanedDocument: true,
          isMissingFile: true,
          documentId: info.references[0].id, // Use first reference for primary document
          replacementPath: info.replacement,
          errors,
          // Add array of all document IDs that reference this file
          referencingDocuments: info.references.map((ref: { id: string; name?: string; type: 'project' | 'pin' }) => ({
            id: ref.id,
            name: ref.name,
            type: ref.type
          }))
        });
      }

      // Store the replacement maps in the orphaned files array metadata
      const metadataFile: StorageFile = {
        path: '_metadata',
        timeCreated: new Date().toISOString(),
        type: 'pin',
        projectIconReplacements: Object.fromEntries(projectIconReplacements),
        pinIconReplacements: Object.fromEntries(pinIconReplacements)
      };

      // Sort orphaned files to show missing files first
      const sortedOrphanedFiles = [...orphanedFiles, metadataFile].sort((a, b) => {
        if (a.path === '_metadata') return 1;  // Always put metadata last
        if (b.path === '_metadata') return -1;
        if (a.isMissingFile && !b.isMissingFile) return -1;
        if (!a.isMissingFile && b.isMissingFile) return 1;
        return 0;
      });


      return sortedOrphanedFiles;
    } catch (error) {
      console.error('Error getting orphaned icons:', error);
      throw error;
    }
  },

  getOrphanedQRCodes: async (): Promise<StorageFile[]> => {
    try {
      const orphanedFiles: StorageFile[] = [];

      // Helper to normalize paths for comparison
      const normalizePath = (path: string) => {
        if (!path) return path;
        // Remove leading slash if present
        path = path.startsWith('/') ? path.substring(1) : path;
        // Handle different QR path formats
        if (!path.startsWith('QR/') && !path.startsWith('qr-codes/')) {
          path = `QR/${path}`;
        }

        return path;
      };

      // 1. Get all recordings to check against
      const recordingsSnapshot = await getDocs(collection(db, 'Recordings'));
      
      // Get all unique QR paths from recordings that are actually used
      const qrPaths = new Set(
        recordingsSnapshot.docs
          .map(doc => {
            const qrPath = (doc.data() as IRecording).qrPath;
            return qrPath ? normalizePath(qrPath) : null;
          })
          .filter(Boolean)
      );

      // 2. Get all files in the QR storage
      const qrRef = ref(storage, 'QR');
      const existingFiles = new Set<string>();

      try {
        // Get files from QR directory
        const qrResult = await listAll(qrRef);

        await Promise.all(qrResult.items.map(async item => {
          const normalizedPath = normalizePath(item.fullPath);
          existingFiles.add(normalizedPath);
  
        }));

        // Get files from subdirectories
        await Promise.all(qrResult.prefixes.map(async prefix => {
          const subResult = await listAll(prefix);
  
          subResult.items.forEach(item => {
            const normalizedPath = normalizePath(item.fullPath);
            existingFiles.add(normalizedPath);
    
          });
        }));
      } catch (error) {
        console.error('Error listing QR storage directory:', error);
      }


      // 3. Find orphaned files (files in storage that are not referenced by any recording)
      for (const filePath of existingFiles) {
        const normalizedPath = normalizePath(filePath);
        if (!qrPaths.has(normalizedPath)) {
          try {
            const fileRef = ref(storage, filePath);
            const metadata = await getMetadata(fileRef);
            const url = await getDownloadURL(fileRef);

            orphanedFiles.push({
              path: filePath,
              url,
              size: metadata.size || 0,
              timeCreated: metadata.timeCreated || new Date().toISOString(),
              type: 'qr',
              errors: ['QR code exists in storage but no Recording document references it']
            });
          } catch (error) {
            console.error(`Error getting metadata for ${filePath}:`, error);
            orphanedFiles.push({
              path: filePath,
              timeCreated: new Date().toISOString(),
              type: 'qr',
              errors: ['QR code exists in storage but no Recording document references it', 'Failed to get file metadata']
            });
          }
        }
      }

      // 4. Find missing QR codes (referenced in recordings but not in storage)
      recordingsSnapshot.docs.forEach(doc => {
        const recording = doc.data() as IRecording;
        if (recording.qrPath) {
          const normalizedPath = normalizePath(recording.qrPath);
          if (!existingFiles.has(normalizedPath)) {
            orphanedFiles.push({
              path: recording.qrPath,
              timeCreated: new Date().toISOString(),
              type: 'qr',
              isOrphanedDocument: false,
              isMissingFile: true,
              recordingKey: doc.id,
              errors: [`⚠️ MISSING FILE: Recording "${doc.id}" references this QR code but the file doesn't exist`]
            });
          }
        }
      });

      // Sort orphaned files to show missing files first
      const sortedOrphanedFiles = orphanedFiles.sort((a, b) => {
        if (a.isMissingFile && !b.isMissingFile) return -1;
        if (!a.isMissingFile && b.isMissingFile) return 1;
        return 0;
      });


      return sortedOrphanedFiles;
    } catch (error) {
      console.error('Error getting orphaned QR codes:', error);
      throw error;
    }
  },
}; 