import { db } from '../firebase/firebase';
import { collection, getDocs, getDoc, doc, updateDoc, addDoc, setDoc, deleteDoc, Timestamp, where, query, onSnapshot } from 'firebase/firestore';
import { PinManager } from '../models';
import { IProject, IPin } from '../types';
import { getFirestore } from 'firebase/firestore';
import { OrganisationManager } from './OrganisationManager';
import { IMember } from '../types/organisation';

export const ProjectManager = {
  observeProjects: async (): Promise<IProject[]> => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Projects'));
      const projects: IProject[] = [];
      querySnapshot.forEach((docSnapshot) => {
        const project = { project_id: docSnapshot.id, ...docSnapshot.data() } as IProject;
        // Removed the isAvailable check to include all projects
        projects.push(project);
      });
      return projects;
    } catch (error) {
      console.error("Error getting projects:", error);
      return [];
    }
  },

  getProject: async (project_id: string): Promise<IProject | null> => {
    try {
      if (!project_id) {
        throw new Error('Invalid project_id');
      }
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
      if (!projectDoc.exists()) {
        return null;
      }
      return { project_id: projectDoc.id, ...projectDoc.data() } as IProject;
    } catch (error) {
      console.error("Error fetching project:", error);
      return null;
    }
  },

  getProjectIcon: async (project_id: string): Promise<string | null> => {
    try {
      const project = await ProjectManager.getProject(project_id);
      if (project && project.icon) {
        return project.icon;
      }
      return null;
    } catch (error) {
      console.error("Error fetching project icon:", error);
      return null;
    }
  },

  checkPinEnableForQRCodeOrNot: async (pin: any): Promise<boolean> => {
    try {
      const project = await ProjectManager.getProject(pin.project);
      if (project && project.isQREnabled && pin.pinType === "qrCode") {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error checking QR code enablement:", error);
      return false;
    }
  },

  getProjectPins: async (project_id: string): Promise<IPin[]> => {
    try {
      // Fetch all available pins
      const allPins = await PinManager.observePins();

      // Filter pins that belong to the given project
      const projectPins = allPins.filter(pin => pin.project === project_id);
      return projectPins;
    } catch (error) {
      console.error(`Error fetching pins for project ${project_id}:`, error);
      return [];
    }
  },

  updateProjectAudioSize: async (project_id: string, additionalSize: number): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
  
      if (!projectDoc.exists()) {
        
        return;
      }
  
      const projectData = projectDoc.data() as IProject;
      const currentAudioSize = projectData.audioSize || 0;
      const newAudioSize = currentAudioSize + additionalSize;
  
      // Update the project's audioSize with the new total
      await updateDoc(projectDocRef, {
        audioSize: newAudioSize
      });
  
    } catch (error) {
      console.error("Error updating project audio size:", error);
    }
  },

  getSubjectTagsForProject: async (project_id: string): Promise<string[]> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
      if (projectDoc.exists()) {
        const projectData = projectDoc.data() as IProject;
        return projectData.tags?.subjectTag || []; 
      }
      return [];
    } catch (error) {
      console.error("Error fetching subject tags:", error);
      return [];
    }
  },

  addSubjectTagToProject: async (project_id: string, tagName: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
      if (!projectDoc.exists()) {
        
        return;
      }
      const projectData = projectDoc.data() as IProject;
      const subjectTags = new Set(projectData.tags?.subjectTag || []);
      subjectTags.add(tagName);
  
      await updateDoc(projectDocRef, {
        'tags.subjectTag': Array.from(subjectTags)
      });
    } catch (error) {
      console.error("Error adding subject tag to project:", error);
    }
  },
  
  // Remove a tag from the project's subjectTags array
  removeSubjectTagFromProject: async (project_id: string, tagName: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
      if (!projectDoc.exists()) {
        
        return;
      }
      const projectData = projectDoc.data() as IProject;
      const subjectTags = new Set(projectData.tags?.subjectTag || []);
      if (subjectTags.has(tagName)) {
        subjectTags.delete(tagName);
        await updateDoc(projectDocRef, {
          'tags.subjectTag': Array.from(subjectTags)
        });
        
      }
    } catch (error) {
      console.error("Error removing subject tag from project:", error);
    }
  },

  updateContentTagForProject: async (project_id: string, contentTag: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
  
      if (!projectDoc.exists()) {
        
        return;
      }
  
      // Update the project's contentTag
      await updateDoc(projectDocRef, {
        'tags.contentTag': contentTag
      });
  
    } catch (error) {
      console.error("Error updating content tag for project:", error);
    }
  },

  createProject: async (project_id: string, projectData: IProject): Promise<void> => {
    try {
      // Create a reference to the new project document with the specified project_id
      const projectDocRef = doc(collection(db, 'Projects'), project_id);

      // Set the new project document data with the project_id as its document ID
      await setDoc(projectDocRef, {
        ...projectData,
        audioSize: 0, // Initialize audioSize to 0
        isResponseEnabled: projectData.isResponseEnabled || false, 
      });

    } catch (error) {
      console.error("Error creating new project with key " + project_id + ":", error);
    }
  },
  updateProject: async (project_id: string, updatedProjectData: Partial<IProject>): Promise<IProject | null> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
  
      await updateDoc(projectDocRef, {
        ...updatedProjectData,
        // Only update fields that are present in updatedProjectData
      });
  
      // Fetch and return the updated project data
      return await ProjectManager.getProject(project_id);
    } catch (error) {
      console.error(`Error updating project with key ${project_id}:`, error);
      return null;
    }
  },
  deleteProject: async (project_id: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      await deleteDoc(projectDocRef);
    } catch (error) {
      console.error(`Error deleting project with key ${project_id}:`, error);
    }
  },


  updateProjectAvailability: async (project_id: string, isAvailable: boolean): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      // Update the isAvailable property in Firestore
      await updateDoc(projectDocRef, { isAvailable });
    } catch (error) {
      console.error(`Error updating project availability for project ${project_id}:`, error);
    }
  },
  getProjectConfig: async (project_id: string): Promise<any> => {
    const projectDocRef = doc(db, 'Projects', project_id);
    const projectDoc = await getDoc(projectDocRef);
    if (projectDoc.exists()) {
      const projectData = projectDoc.data();
      return projectData.config || { locationInfo: true, authorInfo: true, recordingInfo: true, uploadFields: true, password: '' }; // Include default password
    }
    return null;
  },
  
  updateProjectConfig: async (project_id: string, config: any): Promise<void> => {
    const projectDocRef = doc(db, 'Projects', project_id);
    await updateDoc(projectDocRef, { config });
  },

  setTransitAlbumKey: async (project_id: string, albumKey: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      await updateDoc(projectDocRef, { transitAlbumKey: albumKey });
    } catch (error) {
      console.error("Error setting transit album key:", error);
    }
  },

  getTransitAlbumKey: async (project_id: string): Promise<string | null> => {
    try {
      const project = await ProjectManager.getProject(project_id);
      return project?.transitAlbumKey || null;
    } catch (error) {
      console.error("Error getting transit album key:", error);
      return null;
    }
  },

  setProjectPrivacy: async (project_id: string, isPrivate: boolean): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      await updateDoc(projectDocRef, { isPrivate });
    } catch (error) {
      console.error(`Error setting privacy for project ${project_id}:`, error);
    }
  },

  getProjectPrivacy: async (project_id: string): Promise<boolean | null> => {
    try {
      const project = await ProjectManager.getProject(project_id);
      return project?.isPrivate ?? null;
    } catch (error) {
      console.error(`Error getting privacy for project ${project_id}:`, error);
      return null;
    }
  },

  createInviteLink: async (project_id: string, accessLevel: 'read' | 'write' | 'admin' = 'read'): Promise<string | null> => {
    try {
      
      const inviteRef = await addDoc(collection(db, 'invites'), {
        project_id,
        accessLevel,
        createdAt: Timestamp.now(),
        expirationDate: Timestamp.fromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000))
      });
      
      // Use HTTPS URL
      const inviteLink = `https://invite-service-dot-overhear-2.uc.r.appspot.com/invite/${inviteRef.id}`;
      return inviteLink;
    } catch (error) {
      console.error('Error creating invite link:', error);
      return null;
    }
  },

  validateInviteLink: async (inviteId: string): Promise<{ project_id: string, accessLevel: string } | null> => {
    try {
      
      const inviteDoc = await getDoc(doc(db, 'invites', inviteId));
    
      if (inviteDoc.exists()) {
        const inviteData = inviteDoc.data();
        const now = new Date();
        const expirationDate = inviteData.expirationDate.toDate();
        

        if (now < expirationDate) {
          
          return { project_id: inviteData.project_id, accessLevel: inviteData.accessLevel };
        } else {
          
        }
      } else {
        
      }

      return null;
    } catch (error) {
      console.error('Error validating invite link:', error);
      return null;
    }
  },

  addUserToProject: async (userId: string, project_id: string, accessLevel: 'read' | 'write' | 'admin'): Promise<void> => {
    try {
      console.log(`[ProjectManager] Attempting to add user ${userId} to project ${project_id} with ${accessLevel} access`);
      
      // Check if user already has access
      const projectAccessRef = collection(db, 'ProjectAccess');
      const q = query(
        projectAccessRef,
        where('userId', '==', userId),
        where('project_id', '==', project_id)
      );
      const existingAccess = await getDocs(q);
      
      if (!existingAccess.empty) {
        console.log(`[ProjectManager] User ${userId} already has access to project ${project_id}`);
        return;
      }

      // Verify project exists
      const projectRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectRef);
      if (!projectDoc.exists()) {
        console.error(`[ProjectManager] Project ${project_id} does not exist`);
        return;
      }

      // Add new access
      const newAccess = await addDoc(projectAccessRef, {
        project_id,
        userId,
        accessLevel,
        createdAt: Timestamp.now()
      });
      console.log(`[ProjectManager] Successfully added access for user ${userId} to project ${project_id}`);
    } catch (error) {
      console.error(`[ProjectManager] Error adding user ${userId} to project ${project_id}:`, error);
      throw error;
    }
  },


  listenForProjectAccessChanges: (userId: string, callback: () => void): (() => void) => {
    
    const projectAccessRef = collection(db, 'ProjectAccess');
    const q = query(projectAccessRef, where('userId', '==', userId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      
      callback();
    }, (error) => {
      console.error("ProjectManager.listenForProjectAccessChanges: Error in listener:", error);
    });

    return unsubscribe;
  },

  addPinToProject: async (project_id: string, pinKey: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
      if (!projectDoc.exists()) {
        throw new Error(`Project with id ${project_id} does not exist`);
      }

      const projectData = projectDoc.data() as IProject;
      const pins = projectData.pins || [];
      if (!pins.includes(pinKey)) {
        pins.push(pinKey);
        await updateDoc(projectDocRef, { pins });
      }
    } catch (error) {
      console.error(`Error adding pin ${pinKey} to project ${project_id}:`, error);
    }
  },

  addGroupToProject: async (project_id: string, groupId: string, accessLevel: 'read' | 'record'): Promise<void> => {
    try {
      const projectAccessRef = collection(db, 'ProjectAccess');
      await addDoc(projectAccessRef, {
        project_id,
        groupId,
        accessLevel
      });
    } catch (error) {
      console.error(`Error adding group ${groupId} to project ${project_id}:`, error);
      throw error;
    }
  },

  observeModeratorProjects: async (moderatorId: string): Promise<IProject[]> => {
    try {
      const projectsRef = collection(db, 'Projects');
      const q = query(projectsRef, where('projectOwnerUserKey', '==', moderatorId));
      const querySnapshot = await getDocs(q);
      const projects: IProject[] = [];
      querySnapshot.forEach((docSnapshot) => {
        const project = { project_id: docSnapshot.id, ...docSnapshot.data() } as IProject;
        projects.push(project);
      });
      return projects;
    } catch (error) {
      console.error("Error getting moderator projects:", error);
      return [];
    }
  },

  observeOrganisationProjects: async (organisationId: string): Promise<IProject[]> => {
    try {
      // First get all moderators in the organization
      const members = await OrganisationManager.getOrganisationMembers(organisationId);
      const moderatorIds = members
        .filter((member: IMember) => member.role === 'moderator')
        .map((member: IMember) => member.userKey);

      if (moderatorIds.length === 0) {
        return [];
      }

      // Then get all projects where projectOwnerUserKey is in the list of moderator IDs
      const projectsRef = collection(db, 'Projects');
      const q = query(projectsRef, where('projectOwnerUserKey', 'in', moderatorIds));
      const querySnapshot = await getDocs(q);
      const projects: IProject[] = [];
      querySnapshot.forEach((docSnapshot) => {
        const project = { project_id: docSnapshot.id, ...docSnapshot.data() } as IProject;
        projects.push(project);
      });
      return projects;
    } catch (error) {
      console.error("Error getting organisation projects:", error);
      return [];
    }
  },

  /**
   * Updates project access for users when group membership changes
   * @param {string} groupId - The ID of the group that changed
   * @param {string[]} addedUserIds - Array of user IDs that were added to the group
   * @param {string[]} removedUserIds - Array of user IDs that were removed from the group
   * @returns {Promise<void>}
   */
  syncGroupMembershipChanges: async (
    groupId: string,
    addedUserIds: string[],
    removedUserIds: string[]
  ): Promise<void> => {
    try {
      console.log(`[ProjectManager] Syncing group membership changes for group: ${groupId}`);
      console.log(`[ProjectManager] Added users: ${addedUserIds.join(', ')}`);
      console.log(`[ProjectManager] Removed users: ${removedUserIds.join(', ')}`);

      // Find all private projects that have this group
      const projectsRef = collection(db, 'Projects');
      
      // Query for all projects that have this group in their groupIds array
      const projectsQuery = query(
        projectsRef,
        where('groupIds', 'array-contains', groupId)
      );
      const projectsSnapshot = await getDocs(projectsQuery);
      console.log(`[ProjectManager] Found ${projectsSnapshot.size} projects with this group`);

      // For each project that has this group
      const updatePromises = projectsSnapshot.docs.flatMap(async (projectDoc) => {
        const project_id = projectDoc.id;
        const projectData = projectDoc.data();
        
        // Skip if project is not private
        if (!projectData.isPrivate) {
          console.log(`[ProjectManager] Skipping non-private project: ${project_id}`);
          return [];
        }

        console.log(`[ProjectManager] Processing project: ${project_id}`, {
          projectName: projectData.projectName,
          isPrivate: projectData.isPrivate,
          groupIds: projectData.groupIds
        });

        // For added users, add project access
        const addPromises = addedUserIds.map(async userId => {
          console.log(`[ProjectManager] Adding access for user ${userId} to project ${project_id}`);
          try {
            await ProjectManager.addUserToProject(userId, project_id, 'read');
            console.log(`[ProjectManager] Successfully added access for user ${userId}`);
            return { userId, success: true };
          } catch (error) {
            console.error(`[ProjectManager] Failed to add access for user ${userId}:`, error);
            return { userId, success: false, error };
          }
        });

        // For removed users, we need to check if they should still have access through other groups
        const removePromises = removedUserIds.map(async userId => {
          console.log(`[ProjectManager] Checking access removal for user ${userId}`);
          // Get all groups this user is still part of
          const userGroups = await ProjectManager.getUserGroups(userId);
          console.log(`[ProjectManager] User ${userId} is still part of groups:`, userGroups);
          
          // Get project's groups
          const projectGroups = projectData.groupIds || [];
          console.log(`[ProjectManager] Project ${project_id} has groups:`, projectGroups);
          
          // If user is not in any other groups that have access to this project
          const hasOtherAccess = userGroups.some(g => projectGroups.includes(g));
          
          if (!hasOtherAccess) {
            console.log(`[ProjectManager] Removing access for user ${userId} as they have no other group access`);
            // Remove their access
            await ProjectManager.removeUserFromProject(userId, project_id);
          } else {
            console.log(`[ProjectManager] Keeping access for user ${userId} as they have access through other groups`);
          }
        });

        return [...addPromises, ...removePromises];
      });

      await Promise.all(updatePromises.flat());
      console.log(`[ProjectManager] Completed syncing group membership changes`);
    } catch (error) {
      console.error(`[ProjectManager] Error syncing group membership changes:`, error);
      throw error;
    }
  },

  /**
   * Gets all groups a user is a member of
   * @param {string} userId - The ID of the user
   * @returns {Promise<string[]>} Array of group IDs
   */
  getUserGroups: async (userId: string): Promise<string[]> => {
    try {
      const groupsRef = collection(db, 'groups');
      const groupsQuery = query(groupsRef, where('memberIds', 'array-contains', userId));
      const groupsSnapshot = await getDocs(groupsQuery);
      return groupsSnapshot.docs.map(doc => doc.id);
    } catch (error) {
      console.error('Error getting user groups:', error);
      return [];
    }
  },

  /**
   * Removes a user's access to a project
   * @param {string} userId - The ID of the user
   * @param {string} project_id - The ID of the project
   * @returns {Promise<void>}
   */
  removeUserFromProject: async (userId: string, project_id: string): Promise<void> => {
    try {
      console.log(`[ProjectManager] Attempting to remove user ${userId} from project ${project_id}`);
      
      const projectAccessRef = collection(db, 'ProjectAccess');
      const q = query(
        projectAccessRef,
        where('userId', '==', userId),
        where('project_id', '==', project_id)
      );
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        console.log(`[ProjectManager] No access records found for user ${userId} in project ${project_id}`);
        return;
      }
      
      // Delete all matching access records
      const deletePromises = snapshot.docs.map(async doc => {
        await deleteDoc(doc.ref);
        console.log(`[ProjectManager] Deleted access record ${doc.id} for user ${userId}`);
        return doc.id;
      });
      const deletedIds = await Promise.all(deletePromises);
      console.log(`[ProjectManager] Successfully removed all access records for user ${userId} from project ${project_id}`);
    } catch (error) {
      console.error(`[ProjectManager] Error removing user ${userId} from project ${project_id}:`, error);
      throw error;
    }
  },

  syncProjectGroupChanges: async (
    project_id: string,
    oldGroupIds: string[],
    newGroupIds: string[]
  ): Promise<void> => {
    try {

      // Verify project exists and is private
      const projectRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectRef);
      if (!projectDoc.exists()) {
        console.error(`Project ${project_id} does not exist`);
        return;
      }
      const projectData = projectDoc.data();
      if (!projectData.isPrivate) {
        return;
      }

      const addedGroupIds = newGroupIds.filter(id => !oldGroupIds.includes(id));
      const removedGroupIds = oldGroupIds.filter(id => !newGroupIds.includes(id));

      // For each added group, add access for all its members
      for (const groupId of addedGroupIds) {
        const groupRef = doc(db, 'groups', groupId);
        const groupDoc = await getDoc(groupRef);
        if (groupDoc.exists()) {
          const memberIds = groupDoc.data().memberIds || [];
          
          // First verify current access state
          const projectAccessRef = collection(db, 'ProjectAccess');
          const accessQuery = query(
            projectAccessRef,
            where('project_id', '==', project_id),
            where('userId', 'in', memberIds)
          );
          const existingAccess = await getDocs(accessQuery);
          const existingAccessMap = new Map(
            existingAccess.docs.map(doc => [doc.data().userId, doc.data()])
          );

          const addResults = await Promise.all(
            memberIds.map(async (userId: string) => {
              try {
                if (!existingAccessMap.has(userId)) {
                  await ProjectManager.addUserToProject(userId, project_id, 'read');
                  return { userId, success: true, action: 'added' };
                }
                return { userId, success: true, action: 'already_exists' };
              } catch (error) {
                console.error(`Failed to add access for user ${userId}:`, error);
                return { userId, success: false, error };
              }
            })
          );
        } 
      }

      // For each removed group, remove access for its members (unless they have access through another group)
      for (const groupId of removedGroupIds) {
        const groupRef = doc(db, 'groups', groupId);
        const groupDoc = await getDoc(groupRef);
        if (groupDoc.exists()) {
          const memberIds = groupDoc.data().memberIds || [];          
          // First verify current access state
          const projectAccessRef = collection(db, 'ProjectAccess');
          const accessQuery = query(
            projectAccessRef,
            where('project_id', '==', project_id),
            where('userId', 'in', memberIds)
          );
          const existingAccess = await getDocs(accessQuery);
          const existingAccessMap = new Map(
            existingAccess.docs.map(doc => [doc.data().userId, { ...doc.data(), docId: doc.id }])
          );
          
          const removeResults = await Promise.all(
            memberIds.map(async (userId: string) => {
              try {
                // Check if user has access through other groups
                const userGroups = await ProjectManager.getUserGroups(userId);
                
                const hasOtherAccess = userGroups.some(g => newGroupIds.includes(g));
                
                if (!hasOtherAccess) {
                  if (existingAccessMap.has(userId)) {
                    await ProjectManager.removeUserFromProject(userId, project_id);
                    return { userId, removed: true, hadAccess: true };
                  }
                  return { userId, removed: false, hadAccess: false, reason: 'no_access_record' };
                }
                return { userId, removed: false, hadAccess: existingAccessMap.has(userId), reason: 'has_other_access' };
              } catch (error) {
                console.error(`Failed to process removal for user ${userId}:`, error);
                return { userId, removed: false, error };
              }
            })
          );
        } 
      }

      // Update project's groupIds
      await updateDoc(projectRef, {
        groupIds: newGroupIds
      });
    } catch (error) {
      console.error('Error in syncProjectGroupChanges:', error);
      throw error;
    }
  }
}