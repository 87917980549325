import { DropResult } from 'react-beautiful-dnd';
import { IPin } from '../types';
import pinStore from '../stores/PinStore';
import { PinManager } from '../models/PinManager';
import { CollectionLogic } from '../types/Project';

export const handleDragEnd = async (
  result: DropResult,
  sortedPins: IPin[],
  setDragOverPinKey: (key: string | null) => void,
  setIsOrphanDragging: (isDragging: boolean) => void,
  closeSidebar: () => void,
  collectionLogic?: CollectionLogic
) => {
  const { source, destination } = result;
  if (!destination) {
    console.log('🚫 No destination - drag cancelled');
    return;
  }

  const sourceIndex = source.index;
  const destinationIndex = destination.index;
  const draggedPin = sortedPins[sourceIndex];

  console.log('🔄 Drag Operation:', {
    collectionLogic,
    sourceIndex,
    destinationIndex,
    draggedPin: {
      name: draggedPin.name,
      pinOrder: draggedPin.pinOrder
    }
  });

  if (collectionLogic === CollectionLogic.Sequential) {
    // Handle sequential logic
    handleSequentialDrag(sourceIndex, destinationIndex, sortedPins, pinStore, PinManager);
  } else if (collectionLogic === CollectionLogic.Branching) {
    // Handle branching logic
    await handleBranchingDrag(sourceIndex, destinationIndex, sortedPins, pinStore, PinManager);
  }

  console.log('✨ Drag operation complete');
  setDragOverPinKey(null);
  setIsOrphanDragging(false);
  closeSidebar();
};

const handleSequentialDrag = async (
  sourceIndex: number,
  destinationIndex: number,
  sortedPins: IPin[],
  pinStore: any,
  PinManager: any
) => {
  const pinsToUpdate: IPin[] = [];
  
  // Moving down
  if (sourceIndex < destinationIndex) {
    for (let i = 0; i < sortedPins.length; i++) {
      const pin = sortedPins[i];
      if (!pin.pinOrder) continue;

      if (i === sourceIndex) {
        pinsToUpdate.push({
          ...pin,
          pinOrder: { 
            ...pin.pinOrder,
            id: destinationIndex + 1,
            mainBranch: 0,
            subBranch: 0,
            branchLevel: 0
          }
        });
      } else if (i > sourceIndex && i <= destinationIndex) {
        pinsToUpdate.push({
          ...pin,
          pinOrder: {
            ...pin.pinOrder,
            id: i,
            mainBranch: 0,
            subBranch: 0,
            branchLevel: 0
          }
        });
      }
    }
  }
  // Moving up
  else if (sourceIndex > destinationIndex) {
    for (let i = 0; i < sortedPins.length; i++) {
      const pin = sortedPins[i];
      if (!pin.pinOrder) continue;

      if (i === sourceIndex) {
        pinsToUpdate.push({
          ...pin,
          pinOrder: {
            ...pin.pinOrder,
            id: destinationIndex + 1,
            mainBranch: 0,
            subBranch: 0,
            branchLevel: 0
          }
        });
      } else if (i >= destinationIndex && i < sourceIndex) {
        pinsToUpdate.push({
          ...pin,
          pinOrder: {
            ...pin.pinOrder,
            id: i + 2,
            mainBranch: 0,
            subBranch: 0,
            branchLevel: 0
          }
        });
      }
    }
  }

  // Update local store and Firestore
  pinsToUpdate.forEach(pin => pinStore.setPin(pin.pinKey, pin));
  await PinManager.updateSequentialPinOrder(pinsToUpdate);
};

const handleBranchingDrag = async (
  sourceIndex: number,
  destinationIndex: number,
  sortedPins: IPin[],
  pinStore: any,
  PinManager: any
) => {
  const sourcePin = sortedPins[sourceIndex];
  const destinationPin = sortedPins[destinationIndex];

  if (!sourcePin?.pinOrder || !destinationPin?.pinOrder) return;

  // Only allow reordering within the same level and parent
  if (sourcePin.pinOrder.mainBranch !== destinationPin.pinOrder.mainBranch ||
      sourcePin.branchParentPinKey !== destinationPin.branchParentPinKey) {
    console.log('🚫 Cannot move between different branch levels');
    return;
  }

  // Update the subBranch numbers for the affected pins
  const pinsInSameBranch = sortedPins.filter(pin => 
    pin.pinOrder?.mainBranch === sourcePin.pinOrder?.mainBranch &&
    pin.branchParentPinKey === sourcePin.branchParentPinKey
  );

  const updatedPins = pinsInSameBranch.map((pin, index) => ({
    ...pin,
    pinOrder: {
      ...pin.pinOrder!,
      subBranch: index
    }
  }));

  // Update local store and Firestore
  updatedPins.forEach(pin => pinStore.setPin(pin.pinKey, pin));
  await PinManager.updateSequentialPinOrder(updatedPins);
};