// src/hooks/useAdminNav.ts
import { useState, useEffect, useCallback } from 'react';
import { useProjectContext } from '../contexts/ProjectContext';
import { PinManager, ProjectManager } from '../models';
import { IProject } from '../types';
import pinStore from '../stores/PinStore';
import userStore from '../stores/UserStore';

// Add return type interface
interface UseAdminNavReturn {
  projectName: string;
  projects: IProject[];
  onProjectChange: (projectKey: string) => void;
  selectedProject: string;
  userRole: string;
  selectedProjectDetails: IProject | undefined;
}

export const useAdminNav = (): UseAdminNavReturn => {
  const { selectedProject, setSelectedProject } = useProjectContext();
  const [projectName, setProjectName] = useState<string>('');
  const [projects, setProjects] = useState<IProject[]>([]);
  const userRole = userStore.user?.userRole || '';
  const [selectedProjectDetails, setSelectedProjectDetails] = useState<IProject>();

  // Fetch project name based on selectedProject
  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (selectedProject) {
        const project = await ProjectManager.getProject(selectedProject);
        if (project) {
          setProjectName(project.projectName || '');
          setSelectedProjectDetails(project);
        }
      }
    };

    fetchProjectDetails();
  }, [selectedProject]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        let projectsList;
        if (userRole === 'moderator') {
          projectsList = await ProjectManager.observeModeratorProjects(userStore.currentUser?.id || '');
        } else if (userRole === 'organisationAdmin' && userStore.currentOrganisation) {
          projectsList = await ProjectManager.observeOrganisationProjects(userStore.currentOrganisation.id);
        } else {
          projectsList = await ProjectManager.observeProjects();
        }
        setProjects(projectsList);

        // If no project is selected or the selected project isn't in the list, select the first available project
        if (projectsList.length > 0 && (!selectedProject || !projectsList.find(p => p.project_id === selectedProject))) {
          const firstProject = projectsList[0];
          setSelectedProject(firstProject.project_id);
          setProjectName(firstProject.projectName || '');
          setSelectedProjectDetails(firstProject);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    // For superAdmin, fetch projects without requiring organization
    if (userRole === 'superAdmin') {
      fetchProjects();
    } else if (userStore.currentOrganisation) {
      fetchProjects();
    } 
  }, [userRole, userStore.currentUser?.id, userStore.currentOrganisation, selectedProject, setSelectedProject]);

  // Handle project change
  const onProjectChange = useCallback((projectKey: string) => {
    // Verify the project exists in our list before changing
    const projectExists = projects.find(p => p.project_id === projectKey);
    if (!projectExists) {
      console.error('Attempted to select a project that does not exist in the available projects list');
      return;
    }

    pinStore.clearPins();
  
    const fetchPinsForProject = async () => {
      try {
        const pins = await PinManager.getPinsByProjectKey(projectKey);
        pinStore.setPins(pins);
      } catch (error) {
        console.error('Error fetching pins for project:', error);
      }
    };
  
    if (projectKey) {
      fetchPinsForProject();
      setSelectedProject(projectKey);
      localStorage.setItem('selectedProject', projectKey);
    }
  }, [setSelectedProject, projects]);

  // Load saved project on mount
  useEffect(() => {
    const savedProject = localStorage.getItem('selectedProject');
    if (savedProject && projects.length > 0) {
      const projectExists = projects.find(p => p.project_id === savedProject);
      if (projectExists) {
        onProjectChange(savedProject);
      }
    }
  }, [projects, onProjectChange]);

  return {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole,
    selectedProjectDetails,
  };
};
