import { FunctionComponent, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Typography,
  Box,
  Paper,
  InputAdornment,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from "react-router-dom";

import { OrganisationManager } from "../models/OrganisationManager";
import userStore from "../stores/UserStore";
import "./OrgRegistrationScreen.css";
import { OrganisationType } from "../types/organisation";

const OrgRegistrationScreen: FunctionComponent = observer(() => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    orgName: "",
    orgType: "" as OrganisationType,
    description: "",
    adminEmail: "",
    adminPassword: "",
    adminConfirmPassword: "",
  });
  const [error, setError] = useState<string | null>(null);
  const { loading } = userStore;

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<OrganisationType>) => {
    setFormData(prev => ({
      ...prev,
      orgType: e.target.value as OrganisationType
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    // Basic validation
    if (formData.adminPassword !== formData.adminConfirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (formData.adminPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    try {
      const result = await OrganisationManager.registerOrganisation({
        name: formData.orgName,
        type: formData.orgType,
        description: formData.description,
        adminEmail: formData.adminEmail,
        adminPassword: formData.adminPassword,
      });

      // Update the user store with the new user data
      userStore.setUser(result.user);
      userStore.setCurrentOrganisation(result.organisation);

      // Redirect to the Organisation dashboard
      navigate('/org-dashboard');
    } catch (err) {
      setError((err as Error).message);
    }
  };

  return (
    <Box className="registration-screen" sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 600, mx: "auto" }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Register Your Organisation
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Organisation Name"
            name="orgName"
            value={formData.orgName}
            onChange={handleTextChange}
            required
            disabled={loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormControl fullWidth margin="normal" required disabled={loading}>
            <InputLabel>Organisation Type</InputLabel>
            <Select
              name="orgType"
              value={formData.orgType}
              onChange={handleSelectChange}
              label="Organisation Type"
            >
              <MenuItem value="school">School</MenuItem>
              <MenuItem value="community">Community</MenuItem>
              <MenuItem value="organisation">Organisation</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            margin="normal"
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleTextChange}
            multiline
            rows={3}
            disabled={loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DescriptionIcon />
                </InputAdornment>
              ),
            }}
          />

          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
            Admin Account Details
          </Typography>

          <TextField
            fullWidth
            margin="normal"
            label="Admin Email"
            name="adminEmail"
            type="email"
            value={formData.adminEmail}
            onChange={handleTextChange}
            required
            disabled={loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            margin="normal"
            label="Admin Password"
            name="adminPassword"
            type="password"
            value={formData.adminPassword}
            onChange={handleTextChange}
            required
            disabled={loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            margin="normal"
            label="Confirm Password"
            name="adminConfirmPassword"
            type="password"
            value={formData.adminConfirmPassword}
            onChange={handleTextChange}
            required
            disabled={loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            disabled={loading}
            sx={{ mt: 3, height: 48 }}
          >
            {loading ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <CircularProgress size={24} color="inherit" />
                <span>Registering...</span>
              </Box>
            ) : (
              "Register Organisation"
            )}
          </Button>
        </form>
      </Paper>
    </Box>
  );
});

export default OrgRegistrationScreen; 