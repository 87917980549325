import React, { FunctionComponent, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import "./InputForms.css";
import { IWanderPin, Album } from "../types";
import WanderStore from "../stores/WanderStore";
import { AlbumManager } from "../models";
import AllDataManager from "../models/AllDataManager";
import WanderForm from "./WanderForm";

type WanderEditorProps = {
  onClose: () => void;
  wanderKey?: string;
  initialWanderData?: IWanderPin;
  onSave?: (wanderData: IWanderPin) => Promise<void>;
};

const WanderEditor: FunctionComponent<WanderEditorProps> = ({
  onClose,
  wanderKey,
  initialWanderData,
  onSave,
}) => {
  const isEditMode = !!wanderKey;
  const [name, setName] = useState(initialWanderData?.name || '');
  const [description, setDescription] = useState(initialWanderData?.pinDescription || '');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (isEditMode && wanderKey) {
      const fetchLatestWanderData = async () => {
        const latestData = await WanderStore.getWander(wanderKey);
        if (latestData) {
          setName(latestData.name || '');
          setDescription(latestData.pinDescription || '');
        }
      };

      fetchLatestWanderData();
    }
  }, [isEditMode, wanderKey]);

  const handleDelete = async () => {
    if (!isEditMode || !wanderKey || !initialWanderData) return;

    if (window.confirm('Are you sure you want to delete this wander?')) {
      try {
        await AllDataManager.removePinAndAssociatedData(initialWanderData.pinKey);
      } catch (error) {
        if ((error as Error).message !== 'Pin not found') {
          console.error('Error removing pin and associated data:', error);
          return;
        }
      }

      try {
        await WanderStore.deleteWander(wanderKey);
        onClose();
      } catch (error) {
        console.error('Error deleting wander:', error);
      }
    }
  };

  const handleSave = async () => {
    setIsSaving(true);

    try {
      if (isEditMode && wanderKey && initialWanderData) {
        // Update existing wander
        const updatedWander: IWanderPin = {
          ...initialWanderData,
          name: name,
          pinDescription: description,
        };

        await WanderStore.updateWander(updatedWander );
        if (onSave) {
          await onSave(updatedWander);
        }
      } else {
        // Create new wander
        const generatedWanderId = uuidv4();
        const generatedAlbumId = uuidv4();

        const newAlbum: Album = {
          albumKey: generatedAlbumId,
          name: 'Wander Album',
          projectKey: null,
          userKey: null,
        };

        await AlbumManager.createAlbum(newAlbum);

        let nextWanderOrderNo = 1;
        WanderStore.wanders.forEach((wander) => {
          if (wander.wanderOrderNo && wander.wanderOrderNo >= nextWanderOrderNo) {
            nextWanderOrderNo = wander.wanderOrderNo + 1;
          }
        });

        const newWander: IWanderPin = {
          wanderKey: generatedWanderId,
          pinKey: generatedWanderId,
          name: name,
          pinDescription: description,
          albumKey: generatedAlbumId,
          pinType: 'geoLocation',
          wanderOrderNo: nextWanderOrderNo
        };

        await WanderStore.createWander(newWander);
        if (onSave) {
          await onSave(newWander);
        }
      }

      onClose();
    } catch (error) {
      console.error('Error saving wander:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="popup-portal">
      <h2 className="heading1">{isEditMode ? 'Edit Wander' : 'Create Wander'}</h2>
      <WanderForm
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        isUploading={isSaving}
        showDelete={isEditMode}
        onCancel={onClose}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default WanderEditor;
