import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AdminNav, Icon, AdminButtonRow, WanderAccordion } from "../components";
import "./ListPages.css";
import wanderStore from "../stores/WanderStore";
import { useAdminNav } from "../hooks/useAdminNav";
import ListHeader from '../components/ListHeader'; // Import ListHeader component

const WanderView: FunctionComponent = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        wanderStore.fetchAllWanders(); 
    }, []); // Add any dependencies if necessary

    // Directly derive sortedWanders from wanderStore's observable
    const sortedWanders = Array.from(wanderStore.wanders.values()).sort((a, b) => (a.wanderOrderNo ?? 0) - (b.wanderOrderNo ?? 0));

    const {
        projectName,
        projects,
        onProjectChange,
        selectedProject,
        userRole
    } = useAdminNav();


    return (
        <div className="list-page">
            <AdminNav
                showProjectDropdown={false}
                imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
                projectName={projectName}
                userRole={userRole} // Pass the userRole state here
                projects={projects} // Pass the projects state if needed
                selectedProject={selectedProject} // Pass the selectedProject state if needed
                onProjectChange={onProjectChange} // Pass the onProjectChange function if needed
            />
            <AdminButtonRow
                settingsIcon={<Icon icon="settings" size={24} />}
                handleAdminMessageViewClick={() => navigate("/message-view")}
                handleProjectViewClick={() => navigate("/project-view")}
                handleWandersViewClick={() => navigate("/wanders-view")}
                handleUserAccessViewClick={() => navigate("/users-access-view")}
                defaultView="project-view"
                userRole={userRole}
            />
            <ListHeader headers={['', 'Name', '',]} columnCount={3} /> {/* Pass columnCount */}
            {sortedWanders.map((wander) => (
                <WanderAccordion
                    key={wander.wanderKey}
                    wanderKey={wander.wanderKey}
                />
            ))}
        </div>
    );
});

export default WanderView;
