import { useState, FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminNav } from '../components/AdminNav';
import { useAdminNav } from '../hooks/useAdminNav';
import './RecordingFormConfigurator.css'
import { ProjectManager } from '../models';

type RecordingFormConfiguratorProps = {
  onChange: (config: Record<string, boolean | string>) => void;  // Ensure this prop is passed from the parent component
};

const RecordingFormConfigurator: FunctionComponent<RecordingFormConfiguratorProps> = ({ onChange }) => {
  const [config, setConfig] = useState({
    locationInfo: true,
    authorInfo: true,
    recordingInfo: true,
    uploadFields: true,
    password: ''
  });

  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole
  } = useAdminNav();

  useEffect(() => {
    const loadConfig = async () => {
      if (selectedProject) {
        const loadedConfig = await ProjectManager.getProjectConfig(selectedProject);
        if (loadedConfig) {
          setConfig(loadedConfig);
        } 
      }
    };
    loadConfig();
  }, [selectedProject]);

  const handleCheckboxChange = (field: keyof typeof config) => {
    const newConfig = { ...config, [field]: !config[field] };
    setConfig(newConfig);
    onChange(newConfig);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newConfig = { ...config, password: event.target.value };
    setConfig(newConfig);
    onChange(newConfig);
  };

  const handleSaveConfig = async () => {
    if (selectedProject) {
      await ProjectManager.updateProjectConfig(selectedProject, config);
      alert('Configuration saved successfully!');
    } else {
      alert('No project selected!');
    }
  };

  const navigate = useNavigate();

  const handleViewForm = () => {
    if (selectedProject) {
      navigate(`/forms-view/${selectedProject}`);
    } else {
      alert('No project selected!');
    }
  };

  return (
    <div>
      <div className="list-page">
        <AdminNav
          imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
          projectName={projectName}
          userRole={userRole}
          projects={projects}
          selectedProject={selectedProject}
          onProjectChange={onProjectChange}
        />
        <div className='form-builder'>
          <h1 className="title">Form Builder</h1>

          <p className="elements">Choose which elements you require the recipient to complete.</p>
          <div className="labels">
            <label>
              <input
                type="checkbox"
                checked={config.locationInfo}
                onChange={() => handleCheckboxChange('locationInfo')}
              />
              Location Information
            </label>
            <label>
              <input
                type="checkbox"
                checked={config.authorInfo}
                onChange={() => handleCheckboxChange('authorInfo')}
              />
              Author Information
            </label>
            <label>
              <input
                type="checkbox"
                checked={config.recordingInfo}
                onChange={() => handleCheckboxChange('recordingInfo')}
              />
              Recording Information
            </label>
            <label>
              <input
                type="checkbox"
                checked={config.uploadFields}
                onChange={() => handleCheckboxChange('uploadFields')}
              />
              Upload Fields
            </label>
          </div>
          <div className="password-input">
            <label>Add a password for Form Access:</label>
            <input type='text' value={config.password} onChange={handleInputChange} />
          </div>

          <div className="button-container">
            <button className="button-action" onClick={handleSaveConfig}>Save Configuration</button>
            <button className="button-action" onClick={handleViewForm}>View Form</button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default RecordingFormConfigurator
