import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { ProjectManager, UserManager } from '../models';
import { OrganisationManager } from '../models/OrganisationManager';
import { projectStore } from '../stores/ProjectStore';
import { handleFileUpload, constructUrl } from "../utilities";
import { auth } from "../firebase";
import ProjectForm from './ProjectForm';
import { useProjectData } from "../hooks/useProjectData";
import { IProject, CollectionLogic } from "../types"; 
import allDataManager from "../models/AllDataManager";
import { TagManager } from '../models/TagManager';
import pinStore from '../stores/PinStore';
import userStore from '../stores/UserStore';
import { IGroup } from "../types/organisation";

type ProjectEditorProps = {
  onClose: () => void;
  project_id?: string;
  initialProjectData?: IProject;
  onSave?: (projectData: IProject) => Promise<void>;
};

const ProjectEditor: FunctionComponent<ProjectEditorProps> = ({
  onClose,
  project_id,
  initialProjectData,
  onSave,
}) => {
  const isEditMode = !!project_id;
  const [projectName, setProjectName] = useState(initialProjectData?.projectName || '');
  const [projectOwnerUserKey, setProjectOwnerUserKey] = useState(initialProjectData?.projectOwnerUserKey || '');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [tempIconUrl, setTempIconUrl] = useState<string>('');
  const [projectIconUrl, setProjectIconUrl] = useState<string>(
    initialProjectData?.icon || '/assets/overhear-assets/images/ovh-logoartboard-12x-1.png'
  );
  const [isQREnabled, setIsQREnabled] = useState(initialProjectData?.isQREnabled || false);
  const [isResponseEnabled, setIsResponseEnabled] = useState(initialProjectData?.isResponseEnabled || false);
  const [isTransitProjectEnabled, setIsTransitProjectEnabled] = useState(initialProjectData?.isTransitProjectEnabled || false);
  const [isUploading, setIsUploading] = useState(false);
  const currentUserId = auth.currentUser?.uid;
  const [collectionLogic, setCollectionLogic] = useState<CollectionLogic>(
    initialProjectData?.collectionLogic || CollectionLogic.Regular
  );
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>(initialProjectData?.groupIds || []);
  const [isPrivate, setIsPrivate] = useState<boolean>(initialProjectData?.isPrivate || userStore.currentRole === 'moderator');
  const hasOrganisation = !!userStore.currentOrganisation;

  useEffect(() => {
    const loadGroups = async () => {
      if (userStore.currentOrganisation) {
        try {
          const orgGroups = await OrganisationManager.getOrganisationGroups(userStore.currentOrganisation.id);
          setGroups(orgGroups);
        } catch (error) {
          console.error('Error loading groups:', error);
        }
      }
    };
    loadGroups();
  }, []);

  useEffect(() => {
    if (isEditMode && initialProjectData?.icon) {
      setProjectIconUrl(constructUrl(initialProjectData.icon));
    } else {
      setProjectIconUrl(constructUrl('pin-images/1688231813492WebOverhear_Icon.png'));
    }

    if (isEditMode && initialProjectData?.projectOwnerUserKey) {
      setProjectOwnerUserKey(initialProjectData.projectOwnerUserKey);
    } else if (currentUserId) {
      UserManager.getCurrentUser(currentUserId).then(user => {
        if (user && user.userKey) {
          setProjectOwnerUserKey(user.userKey);
        }
      });
    }
  }, [isEditMode, initialProjectData, currentUserId]);

  useEffect(() => {
    if (currentUserId) {
      const fetchUserKey = async () => {
        try {
          const currentUser = await UserManager.getCurrentUser(currentUserId);
          if (currentUser && currentUser.userKey) {
            setProjectOwnerUserKey(currentUser.userKey);
          }
        } catch (error) {
          console.error('Error fetching user key:', error);
        }
      };

      fetchUserKey();
    }
  }, [currentUserId]);

  useEffect(() => {
    if (isEditMode && project_id) {
      const fetchLatestProjectData = async () => {
        const latestData = await ProjectManager.getProject(project_id);
        if (latestData) {
          setProjectName(latestData.projectName || '');
          setProjectIconUrl(latestData.icon ? constructUrl(latestData.icon) : '/default-project-icon.png');
          setIsQREnabled(latestData.isQREnabled || false);
          setIsResponseEnabled(latestData.isResponseEnabled || false);
          setIsTransitProjectEnabled(latestData.isTransitProjectEnabled || false);
          setCollectionLogic(latestData.collectionLogic || CollectionLogic.Regular);
          setSelectedGroupIds(latestData.groupIds || []);
          setIsPrivate(latestData.isPrivate || false);
        }
      };

      fetchLatestProjectData();
    }
  }, [isEditMode, project_id]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setSelectedFile(file);
      // Revoke previous temp URL if it exists
      if (tempIconUrl) {
        URL.revokeObjectURL(tempIconUrl);
      }
      const url = URL.createObjectURL(file);
      setTempIconUrl(url);
    } else {
      setSelectedFile(null);
      if (tempIconUrl) {
        URL.revokeObjectURL(tempIconUrl);
      }
      setTempIconUrl('');
    }
  };

  const handleSave = async () => {
    if (!projectName || !projectOwnerUserKey) {
      console.error('Project name and User Key are required');
      return;
    }

    setIsUploading(true);

    let projectIconPath = isEditMode ? initialProjectData?.icon || '1688231813492WebOverhear_Icon.png' : '1688231813492WebOverhear_Icon.png';
    if (selectedFile) {
      try {
        const filePath = 'project-icons/';
        const fileName = `${Date.now()}${selectedFile.name}`;
        projectIconPath = await handleFileUpload(selectedFile, setProjectIconUrl, filePath, fileName);
      } catch (error) {
        console.error('Error uploading project icon:', error);
        setIsUploading(false);
        return;
      }
    }

    const projectData: IProject = {
      ...initialProjectData,
      projectName,
      icon: projectIconPath,
      isQREnabled,
      isResponseEnabled,
      isTransitProjectEnabled,
      collectionLogic,
      project_id: initialProjectData?.project_id || uuidv4(),
      pins: initialProjectData?.pins || [],
      tags: {
        subjectTag: [projectName],
        contentTag: initialProjectData?.tags?.contentTag || '',
      },
      isAvailable: initialProjectData?.isAvailable || true,
      audioSize: initialProjectData?.audioSize || 0,
      projectOwnerUserKey,
      isPrivate,
      groupIds: isPrivate && hasOrganisation ? selectedGroupIds : [],
    };

    try {
      if (isEditMode && project_id) {
        // Convert MobX arrays to regular arrays for comparison
        const oldGroupIds = Array.from(initialProjectData?.groupIds || []);
        const newGroupIds = isPrivate && hasOrganisation ? Array.from(selectedGroupIds) : [];


        const updatedProject = await ProjectManager.updateProject(project_id, projectData);
                // Sync project access for changed groups
        await ProjectManager.syncProjectGroupChanges(project_id, oldGroupIds, newGroupIds);

        const pinKeys = pinStore.getPinKeysForProject(project_id);
        await TagManager.updateProjectTags(project_id, projectName, pinKeys);
        await ProjectManager.addSubjectTagToProject(project_id, projectName);

        if (updatedProject && onSave) {
          onSave(updatedProject);
        }
      } else {
        const newProjectId = uuidv4();
        const newProjectData = {
          ...projectData,
          key: newProjectId,
          project_id: newProjectId,
          pins: [],
          tags: {
            subjectTag: [projectName],
            contentTag: '', 
          },
          isAvailable: true,
          audioSize: 0,
          projectOwnerUserKey,
          isPrivate,
          groupIds: isPrivate && hasOrganisation ? Array.from(selectedGroupIds) : [],
        };

        // Create the project first
        await ProjectManager.createProject(newProjectId, newProjectData);
        
        // If the project is private and has selected groups, create access records for all users in those groups
        if (isPrivate && hasOrganisation && selectedGroupIds.length > 0) {
          // Use syncProjectGroupChanges with empty oldGroupIds since it's a new project
          await ProjectManager.syncProjectGroupChanges(newProjectId, [], Array.from(selectedGroupIds));
        }

        const pinKeys: string[] = [];
        await TagManager.updateProjectTags(newProjectId, projectName, pinKeys);
        await ProjectManager.addSubjectTagToProject(newProjectId, projectName);

        projectStore.setSelectedProject(newProjectId);
        projectStore.addProject(newProjectData);
        if (onSave) {
          onSave(newProjectData);
        }
      }
      onClose();
    } catch (error) {
      console.error('Error saving project:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = async () => {
    if (!project_id) return;
    try {
      await ProjectManager.deleteProject(project_id);
      projectStore.deleteProject(project_id);
      onClose();
      
      // Refresh the project list based on user role
      const currentUser = userStore.currentUser;
      const currentRole = userStore.currentRole;
      
      if (currentUser && currentRole) {
        if (currentRole === 'superAdmin' || currentRole === 'projectAdmin') {
          // Admin sees all projects
          await projectStore.fetchProjects();
        } else {
          // Moderators and other roles only see their own projects
          const projectData = await ProjectManager.observeModeratorProjects(currentUser.id);
          projectStore.projects = projectData;
        }
      }
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  useEffect(() => {
    return () => {
      if (tempIconUrl) {
        URL.revokeObjectURL(tempIconUrl);
      }
    };
  }, [tempIconUrl]);

  return (
    <div className="popup-portal">
      <h2 className="heading1">{isEditMode ? 'Edit Project' : 'Create Project'}</h2>
      <ProjectForm
        projectName={projectName}
        setProjectName={setProjectName}
        projectOwnerUserKey={projectOwnerUserKey}
        setProjectOwnerUserKey={setProjectOwnerUserKey}
        projectIconUrl={tempIconUrl || projectIconUrl}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        onFileChange={handleFileChange}
        isQREnabled={isQREnabled}
        setIsQREnabled={setIsQREnabled}
        isResponseEnabled={isResponseEnabled}
        setIsResponseEnabled={setIsResponseEnabled}
        isTransitProjectEnabled={isTransitProjectEnabled}
        setIsTransitProjectEnabled={setIsTransitProjectEnabled}
        collectionLogic={collectionLogic}
        setCollectionLogic={setCollectionLogic}
        isUploading={isUploading}
        onCancel={onClose}
        onSave={handleSave}
        onDelete={handleDelete}
        showDelete={isEditMode}
        groups={groups}
        selectedGroupIds={selectedGroupIds}
        setSelectedGroupIds={setSelectedGroupIds}
        isPrivate={isPrivate}
        hasOrganisation={hasOrganisation}
      />
    </div>
  );
};

export default ProjectEditor;
