import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem, Button } from '@mui/material';
import { IAuthor } from '../types/Author';
import { constructUrl } from '../utilities/constructUrl';

interface AuthorMergePreferencesProps {
  authors: IAuthor[];
  onMerge: (preferences: {
    bio: string;
    website: string;
    image: string;
  }) => void;
  onCancel: () => void;
}

const AuthorMergePreferences: React.FC<AuthorMergePreferencesProps> = ({
  authors,
  onMerge,
  onCancel,
}) => {
  const [selectedBio, setSelectedBio] = useState<string>(authors[0]?.bio || '');
  const [selectedWebsite, setSelectedWebsite] = useState<string>(authors[0]?.website || '');
  const [selectedImage, setSelectedImage] = useState<string>(authors[0]?.image || '');

  const handleMerge = () => {
    onMerge({
      bio: selectedBio,
      website: selectedWebsite,
      image: selectedImage,
    });
  };

  return (
    <Box className="merge-preferences" sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Select Preferences for Merged Profile
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>Bio</Typography>
        <Select
          fullWidth
          value={selectedBio}
          onChange={(e) => setSelectedBio(e.target.value)}
          sx={{ mb: 2 }}
        >
          {authors.map((author, index) => (
            <MenuItem key={index} value={author.bio || ''}>
              <Box sx={{ maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {author.bio || 'No bio available'}
              </Box>
            </MenuItem>
          ))}
        </Select>

        <Typography variant="subtitle1" gutterBottom>Website</Typography>
        <Select
          fullWidth
          value={selectedWebsite}
          onChange={(e) => setSelectedWebsite(e.target.value)}
          sx={{ mb: 2 }}
        >
          {authors.map((author, index) => (
            <MenuItem key={index} value={author.website || ''}>
              {author.website || 'No website available'}
            </MenuItem>
          ))}
        </Select>

        <Typography variant="subtitle1" gutterBottom>Profile Image</Typography>
        <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
          {authors.map((author, index) => (
            <Box
              key={index}
              onClick={() => setSelectedImage(author.image || '')}
              sx={{
                cursor: 'pointer',
                border: selectedImage === author.image ? '2px solid #1976d2' : '2px solid transparent',
                borderRadius: '4px',
                padding: '4px',
              }}
            >
              <img
                src={author.image ? constructUrl(author.image) : '/assets/icons/user.svg'}
                alt={`${author.name}'s profile`}
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                onError={(e) => {
                  (e.target as HTMLImageElement).src = '/assets/icons/user.svg';
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleMerge} color="primary">
          Merge with Selected Preferences
        </Button>
      </Box>
    </Box>
  );
};

export default AuthorMergePreferences; 