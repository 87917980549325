import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, Button, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AuthorInformationFields from '../components/AuthorInformationFields';
import { AuthorManager, PinManager } from '../models';
import RecordingStore from '../stores/RecordingStore';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { IPin } from '../types';
import { IRecordingWithAuthor } from '../types/Tags';
import { constructUrl } from '../utilities/constructUrl';
import './AuthorDashboard.css';
import { IProject } from '../types/Project';
import { ProjectManager } from '../models';
import AudioPlayer from '../components/AudioPlayer';
import { handleFileUpload } from '../utilities/handleFileUpload';
import { Icon } from '../components/Icon';
import { updateTags } from '../utilities/tagUpdate'; // Import the updateTags function
import { useNavigate } from 'react-router-dom';
import authenticationStore from '../stores/AuthenticationStore';

// Define a new interface for author details that includes the imageFile property
interface AuthorDetails {
  authorKey?: string;  // Make authorKey optional
  authorName: string;
  authorBio: string;
  authorWebsite: string;
  authorTags: string[];
  authorImageUrl: string;
  imageFile?: File | null;
}

const AuthorDashboard: React.FC = observer(() => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authorDetails, setAuthorDetails] = useState<AuthorDetails>({
    authorKey: '',
    authorName: '',
    authorBio: '',
    authorWebsite: '',
    authorTags: [],
    authorImageUrl: '/assets/icons/user.svg',
  });
  const [authorPins, setAuthorPins] = useState<IPin[]>([]);
  const [projectsMap, setProjectsMap] = useState<Map<string, IProject>>(new Map());
  const mapRef = useRef<L.Map | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);
  const [audioErrors, setAudioErrors] = useState<Record<string, boolean>>({});

  // Add error handler for unhandled rejections
  useEffect(() => {
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      event.preventDefault();
      console.error('Unhandled promise rejection:', event.reason);
      setError(event.reason instanceof Error ? event.reason : new Error(String(event.reason)));
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);
    return () => window.removeEventListener('unhandledrejection', handleUnhandledRejection);
  }, []);

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        if (!authenticationStore.isAuthenticated) {
          navigate('/author-login');
          return;
        }

        const user = authenticationStore.user;
        
        if (!user) {
          throw new Error('No authenticated user found');
        }

        try {
          const authorData = await AuthorManager.getAuthorByUserKey(user.uid);
          
          if (authorData) {
            setAuthorDetails({
              authorKey: authorData.authorKey || '',
              authorName: authorData.name || '',
              authorBio: authorData.bio || '',
              authorWebsite: authorData.website || '',
              authorTags: authorData.tags?.authorTags || [],
              authorImageUrl: authorData.image || '/assets/icons/user.svg',
            });

            if (authorData.authorKey) {
              try {
                await RecordingStore.fetchRecordingsForAuthor(authorData.authorKey);
                
                // Safely get pins if there are any recordings
                if (RecordingStore.recordings.size > 0) {
                  const pins = await fetchPinsForAuthor(authorData.authorKey);
                  setAuthorPins(pins.filter(pin => pin !== null));

                  // Fetch projects for all recordings
                  const projectKeys = Array.from(
                    new Set(
                      Array.from(RecordingStore.recordings.values())
                        .filter(r => r && r.project) // Add null check
                        .map(r => r.project)
                        .filter((key): key is string => typeof key === 'string')
                    )
                  );

                  if (projectKeys.length > 0) {
                    const projects = await Promise.all(
                      projectKeys.map(async key => {
                        try {
                          return await ProjectManager.getProject(key);
                        } catch (error) {
                          console.error(`Error fetching project ${key}:`, error);
                          return null;
                        }
                      })
                    );

                    const projectMap = new Map(
                      projects
                        .filter((p): p is IProject => p !== null)
                        .map(p => [p.project_id, p])
                    );
                    setProjectsMap(projectMap);
                  }
                }
              } catch (error) {
                console.error('Error fetching recordings and related data:', error);
                throw error; // Re-throw to be caught by outer try-catch
              }
            }
          } else {
            // New user without author profile - set default state
            setAuthorDetails({
              authorKey: '',
              authorName: '',
              authorBio: '',
              authorWebsite: '',
              authorTags: [],
              authorImageUrl: '/assets/icons/user.svg',
            });
            setIsEditing(true);
          }
        } catch (error) {
          console.error('Error fetching author data:', error);
          throw error; // Re-throw to be caught by outer try-catch
        }
      } catch (error) {
        console.error('Error in checkAuthAndFetchData:', error);
        setError(error instanceof Error ? error : new Error(String(error)));
        // Don't rethrow - this is our top-level error handler
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthAndFetchData().catch(error => {
      console.error('Unhandled error in checkAuthAndFetchData:', error);
      setError(error instanceof Error ? error : new Error(String(error)));
    });
  }, [navigate]);

  useEffect(() => {
    if (isLoading) return; // Don't initialize map while loading

    if (!mapRef.current && document.getElementById('authorMap')) {
      initializeMap();
    } else if (mapRef.current) {
      updateMapPins();
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [authorPins, isLoading]);

  const fetchPinsForAuthor = async (authorKey: string): Promise<IPin[]> => {
    try {
      if (!RecordingStore.recordings || RecordingStore.recordings.size === 0) {
        return [];
      }

      const recordings = Array.from(RecordingStore.recordings.values()).filter(recording => recording !== null);
      const pinKeys = recordings
        .filter(recording => recording && recording.pinKey) // Add null check
        .map(recording => recording.pinKey)
        .filter((key): key is string => typeof key === 'string');

      if (pinKeys.length === 0) {
        return [];
      }

      const pins = await Promise.all(
        pinKeys.map(async pinKey => {
          try {
            return await PinManager.getPin(pinKey);
          } catch (error) {
            console.error(`Error fetching pin ${pinKey}:`, error);
            return null;
          }
        })
      );

      return pins.filter((pin): pin is IPin => pin !== null);
    } catch (error) {
      console.error('Error in fetchPinsForAuthor:', error);
      return [];
    }
  };

  const initializeMap = () => {
    if (!mapRef.current) {
      mapRef.current = L.map('authorMap').setView([0, 0], 2);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(mapRef.current);

      authorPins.forEach(pin => {
        if (pin.location?.geoPoint) {
          const { lat, long } = pin.location.geoPoint;
          const iconUrl = constructUrl(pin.pinIcon);
          const icon = L.icon({
            iconUrl: iconUrl,
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          });
          L.marker([Number(lat), Number(long)], { icon }).addTo(mapRef.current!);
        }
      });

      if (authorPins.length > 0) {
        const validPins = authorPins.filter(pin => pin.location?.geoPoint);
        if (validPins.length > 0) {
          const bounds = L.latLngBounds(validPins.map(pin => [Number(pin.location.geoPoint!.lat), Number(pin.location.geoPoint!.long)]));
          mapRef.current.fitBounds(bounds);
        }
      }
    }
  };

  const updateMapPins = () => {
    if (!mapRef.current) return;

    // Clear existing markers
    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        mapRef.current!.removeLayer(layer);
      }
    });

    // Add new markers
    const validPins = authorPins.filter(pin => pin.location?.geoPoint);
    validPins.forEach(pin => {
      const { lat, long } = pin.location.geoPoint!;
      const iconUrl = constructUrl(pin.pinIcon);
      const icon = L.icon({
        iconUrl: iconUrl,
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      });
      L.marker([Number(lat), Number(long)], { icon }).addTo(mapRef.current!);
    });

    // Fit bounds if there are valid pins
    if (validPins.length > 0) {
      const bounds = L.latLngBounds(validPins.map(pin => [Number(pin.location.geoPoint!.lat), Number(pin.location.geoPoint!.long)]));
      mapRef.current.fitBounds(bounds);
    } else {
      // If no pins, set a default view
      mapRef.current.setView([0, 0], 2);
    }
  };

  const toggleEdit = () => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      // Ensure all required fields are included when setting editingDetails
      setIsEditing(true);
    }
  };

  const handleSave = async () => {
    if (isEditing && authorDetails.authorKey) {
      try {
        setIsUploading(true);
        let imageUrl = authorDetails.authorImageUrl;

        // If there's a new image file, upload it
        if (authorDetails.imageFile) {
          const filePath = `images/author/${authorDetails.authorKey}/`;
          const fileName = `profile_${Date.now()}.jpg`;
          imageUrl = await handleFileUpload(
            authorDetails.imageFile,
            (url) => {
              setAuthorDetails((prev) => ({
                ...prev,
                authorImageUrl: typeof url === 'function' ? url(prev.authorImageUrl) : url
              }));
            },
            filePath,
            fileName
          );
        }

        // Update author details including the new image URL
        await AuthorManager.updateAuthor(authorDetails.authorKey, {
          name: authorDetails.authorName,
          bio: authorDetails.authorBio,
          website: authorDetails.authorWebsite,
          tags: { authorTags: authorDetails.authorTags || [] },
          image: imageUrl,
        });

        setAuthorDetails({
          ...authorDetails,
          authorImageUrl: imageUrl,
        });
        setIsEditing(false);
      } catch (error) {
        console.error('Error saving author details:', error);
        // Optionally, you can add error handling UI here
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const getAuthorImageUrl = (imageUrl: string) => {
    if (!imageUrl || imageUrl === '/assets/icons/user.svg') {
      return '/assets/icons/user.svg';
    }
    // Check if the URL is already a full Firebase Storage URL
    if (imageUrl.startsWith('https://firebasestorage.googleapis.com')) {
      return imageUrl; // Return as is if it's already a full URL
    }
    return constructUrl(imageUrl);
  };

  const handleUpdateTags = useCallback(async (authorKey: string, newTags: string[]) => {
    try {
      // First, update the author's tags in the database
      await AuthorManager.updateAuthor(authorKey, {
        tags: { authorTags: newTags }
      });

      // Then, use the updateTags utility to update tags in other collections
      await updateTags({
        authorKey,
        pinKeys: authorPins.map(pin => pin.pinKey).filter((key): key is string => key !== undefined),
        authorTags: newTags,
        initialAuthorTags: authorDetails.authorTags
      });

    } catch (error) {
      console.error('Error updating author tags:', error);
      // Optionally, you can add error handling UI here
    }
  }, []);

  const handleAudioError = (recordingKey: string) => {
    setAudioErrors(prev => ({
      ...prev,
      [recordingKey]: true
    }));
    console.warn(`Audio failed to load for recording: ${recordingKey}`);
  };

  const getAudioUrl = useCallback((recording: IRecordingWithAuthor) => {
    try {
      if (!recording.key) return '';
      const url = RecordingStore.getAudioUrl(recording.key);
      return url || '';
    } catch (error) {
      console.error('Error getting audio URL:', error);
      return '';
    }
  }, []);

  if (error) {
    return (
      <Box className="error-container" p={3}>
        <Typography variant="h6" color="error">
          Error Loading Dashboard
        </Typography>
        <Typography color="error">
          {error.message}
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => {
            setError(null);
            window.location.reload();
          }}
          sx={{ mt: 2 }}
        >
          Retry
        </Button>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="author-dashboard">
      <Box display="flex" justifyContent="center" mb={2}>
        <Icon icon="overhearlight" width={120} height={140} /> {/* Adjust these dimensions as needed */}
      </Box>
      <Typography variant="h4" gutterBottom>
        Author Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} className={`dashboard-section author-profile ${isEditing ? 'editing-mode' : ''}`}>
            <Typography variant="h6" className="section-title">Author Profile</Typography>
            {isEditing ? (
              <Box className="edit-mode-container">
                <AuthorInformationFields
                  initialAuthorName={authorDetails.authorName}
                  initialAuthorBio={authorDetails.authorBio}
                  initialAuthorWebsite={authorDetails.authorWebsite}
                  initialAuthorTags={authorDetails.authorTags}
                  initialAuthorImageUrl={authorDetails.authorImageUrl}
                  authorKey={authorDetails.authorKey || ''}
                  pinKeys={authorPins.map(pin => pin.pinKey).filter((key): key is string => key !== undefined)} // Pass all pinKeys
                  onAuthorDetailsChange={(details) => setAuthorDetails(prev => ({ ...prev!, ...details }))}
                  setAuthorTags={(newTags) => setAuthorDetails(prev => ({
                    ...prev!,
                    authorTags: typeof newTags === 'function' ? newTags(prev!.authorTags) : newTags
                  }))}
                />
                <Box className="edit-buttons-container">
                  <Button onClick={handleCancel} variant="outlined" color="secondary" disabled={isUploading}>
                    Cancel
                  </Button>
                  <Button onClick={handleSave} variant="contained" color="primary" disabled={isUploading}>
                    {isUploading ? 'Saving...' : 'Save'}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box className="author-profile-content">
                <img 
                  src={getAuthorImageUrl(authorDetails.authorImageUrl)}
                  alt={authorDetails.authorName} 
                  className="author-image" 
                  onError={(e) => {
                    console.error("Error loading image:", e);
                    
                    (e.target as HTMLImageElement).src = '/assets/icons/user.svg';
                  }}
                />
                <Typography variant="h6" className="author-name">{authorDetails.authorName}</Typography>
                <Typography variant="body1" className="author-bio">{authorDetails.authorBio}</Typography>
                <Typography variant="body2" className="author-website">
                  Website: <a href={authorDetails.authorWebsite} target="_blank" rel="noopener noreferrer">{authorDetails.authorWebsite}</a>
                </Typography>
                <Box className="author-tags">
                  {authorDetails.authorTags.map((tag, index) => (
                    <span key={index} className="author-tag">{tag}</span>
                  ))}
                </Box>
                <Button onClick={toggleEdit} variant="contained" color="primary" className="edit-button">
                  Edit Profile
                </Button>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} className="dashboard-section">
            <Typography variant="h6">My Recordings</Typography>
            {RecordingStore.recordings.size > 0 ? (
              <List className="recordings-list">
                {Array.from(RecordingStore.recordings.values()).map((recording) => (
                  <ListItem key={recording.key} className="recording-item" disableGutters>
                    <Box className="recording-item-inner">
                      <Box className="recording-item-content">
                        <Typography variant="subtitle1" className="recording-title">
                          {recording.file?.title || 'Untitled Recording'}
                        </Typography>
                        <Box className="audio-player-container">
                          {!audioErrors[recording.key] ? (
                            <AudioPlayer 
                              audioUrl={getAudioUrl(recording)} 
                              onError={() => handleAudioError(recording.key)}
                            />
                          ) : (
                            <Typography color="error" variant="body2">
                              Audio unavailable
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box className="recording-details">
                        <div className="recording-detail-item">
                          <span className="recording-detail-label">Project:</span>
                          {recording.project && projectsMap.has(recording.project) 
                            ? projectsMap.get(recording.project)?.projectName 
                            : 'Unknown'}
                        </div>
                        <div className="recording-detail-item">
                          <span className="recording-detail-label">Description:</span>
                          {recording.file?.description || 'No description available'}
                        </div>
                        <div className="recording-detail-item">
                          <span className="recording-detail-label">Collection Count:</span>
                          {recording.collectionLog?.timesCollected || 0}
                        </div>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2">No recordings found.</Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className="dashboard-section map-container">
            <Typography variant="h6">My Pins</Typography>
            {!isLoading && <Box id="authorMap" style={{ height: '400px', width: '100%' }}></Box>}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});

export default AuthorDashboard;
