import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Paper, List, ListItem, Button, CircularProgress } from '@mui/material';
import { IAuthor } from '../types/Author';
import { AuthorManager } from '../models';
import { constructUrl } from '../utilities/constructUrl';
import './ArtistsView.css';
import AdminNav from '../components/AdminNav';
import { AdminButtonRow } from '../components/AdminButtonRow';
import userStore from '../stores/UserStore';
import { useNavigate } from 'react-router-dom';
import { RecordingManager } from '../models';
import { storage } from '../firebase/firebase';
import { ref, deleteObject } from 'firebase/storage';
import AuthorMergePreferences from '../components/AuthorMergePreferences';

interface AuthorGroup {
  name: string;
  authors: IAuthor[];
  hasDuplicates?: boolean;
}

const ArtistsView: React.FC = () => {
  const [authorGroups, setAuthorGroups] = useState<AuthorGroup[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [selectedAuthors, setSelectedAuthors] = useState<string[]>([]);
  const [showMergePreferences, setShowMergePreferences] = useState(false);
  const [authorsToMerge, setAuthorsToMerge] = useState<IAuthor[]>([]);
  const userRole = userStore.currentUser?.role || 'user';
  const navigate = useNavigate();

  const handleAdminMessageViewClick = useCallback(() => {
    navigate("/message-view", { state: { userRole } });
  }, [navigate, userRole]);

  const handleProjectViewClick = useCallback(() => {
    navigate("/project-view", { state: { userRole } });
  }, [navigate, userRole]);

  const handleWandersViewClick = useCallback(() => {
    navigate("/wanders-view", { state: { userRole } });
  }, [navigate, userRole]);

  const handleUserAccessViewClick = useCallback(() => {
    navigate("/user-access-view", { state: { userRole } });
  }, [navigate, userRole]);

  useEffect(() => {
    fetchAuthors();
  }, []);

  const fetchAuthors = async () => {
    try {
      const authors = await AuthorManager.getAllAuthors();
      
      // Group authors by name
      const groupedAuthors = authors.reduce((groups: { [key: string]: IAuthor[] }, author) => {
        if (!author.name) return groups;
        if (!groups[author.name]) {
          groups[author.name] = [];
        }
        groups[author.name].push(author);
        return groups;
      }, {});

      // Convert to array, add hasDuplicates flag, and sort
      const groupsArray = Object.entries(groupedAuthors)
        .map(([name, authors]) => ({
          name,
          authors,
          hasDuplicates: authors.length > 1
        }))
        .sort((a, b) => {
          // First sort by duplicate status (duplicates first)
          if (a.hasDuplicates && !b.hasDuplicates) return -1;
          if (!a.hasDuplicates && b.hasDuplicates) return 1;
          
          // Then sort by number of authors (more authors first)
          if (a.authors.length !== b.authors.length) {
            return b.authors.length - a.authors.length;
          }
          
          // Finally sort alphabetically
          return a.name.localeCompare(b.name);
        });

      setAuthorGroups(groupsArray);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching authors:', error);
      setIsLoading(false);
    }
  };

  const handleGroupClick = (name: string) => {
    setSelectedGroup(selectedGroup === name ? null : name);
    setSelectedAuthors([]);
  };

  const handleAuthorSelection = (authorKey: string) => {
    setSelectedAuthors(prev => 
      prev.includes(authorKey)
        ? prev.filter(key => key !== authorKey)
        : [...prev, authorKey]
    );
  };

  const handleMergeClick = () => {
    const selectedAuthorsList = authorGroups
      .flatMap(group => group.authors)
      .filter(author => author.authorKey && selectedAuthors.includes(author.authorKey));

    setAuthorsToMerge(selectedAuthorsList);
    setShowMergePreferences(true);
  };

  const handleMergeAuthors = async (preferences: { bio: string; website: string; image: string }) => {
    if (selectedAuthors.length < 2) return;

    try {
      // Get the first author as the base
      const baseAuthor = authorGroups
        .find(group => group.authors.some(author => author.authorKey === selectedAuthors[0]))
        ?.authors.find(author => author.authorKey === selectedAuthors[0]);

      if (!baseAuthor || !baseAuthor.authorKey) return;

      // Get all authors that will be merged
      const authorsToMerge = authorGroups
        .flatMap(group => group.authors)
        .filter(author => author.authorKey && selectedAuthors.includes(author.authorKey));

      // Delete images from storage for all authors except the selected image
      const deleteImagePromises = authorsToMerge
        .filter(author => 
          author.image && 
          author.image !== preferences.image &&
          !author.image.startsWith('/assets/') &&
          author.image.startsWith('images/author/')
        )
        .map(async (author) => {
          try {
            if (author.image) {
              const imageRef = ref(storage, author.image);
              await deleteObject(imageRef);
            }
          } catch (error) {
            console.error(`Error deleting image for author ${author.name} at path: ${author.image}:`, error);
          }
        });

      // Wait for all image deletions to complete
      await Promise.all(deleteImagePromises);

      // Merge the authors with selected preferences
      const mergedAuthor = await AuthorManager.mergeAuthors(
        selectedAuthors,
        baseAuthor.userKey || '',
        baseAuthor.authorKey,
        {
          bio: preferences.bio,
          website: preferences.website,
          image: preferences.image,
        }
      );

      // Update all recordings that reference any of the old author keys
      await RecordingManager.updateRecordingsAuthor(
        selectedAuthors.filter(key => key !== baseAuthor.authorKey),
        baseAuthor.authorKey
      );

      // Refresh the authors list
      await fetchAuthors();
      setSelectedAuthors([]);
      setShowMergePreferences(false);

    } catch (error) {
      console.error('Error merging authors:', error);
    }
  };

  const handleSelectAllInGroup = (authors: IAuthor[]) => {
    const authorKeys = authors
      .filter(author => author.authorKey)
      .map(author => author.authorKey as string);

    if (authorKeys.every(key => selectedAuthors.includes(key))) {
      // If all are selected, unselect all
      setSelectedAuthors(prev => prev.filter(key => !authorKeys.includes(key)));
    } else {
      // If not all are selected, select all
      setSelectedAuthors(prev => [...new Set([...prev, ...authorKeys])]);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <AdminNav />
      <Box sx={{ marginTop: '64px' }}>
        <AdminButtonRow 
          defaultView="artists-view" 
          userRole={userRole}
          handleAdminMessageViewClick={handleAdminMessageViewClick}
          handleProjectViewClick={handleProjectViewClick}
          handleWandersViewClick={handleWandersViewClick}
          handleUserAccessViewClick={handleUserAccessViewClick}
        />
        <Box className="artists-view">
          <Typography variant="h4" gutterBottom>
            Artist Profiles Management
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              {authorGroups.filter(g => g.hasDuplicates).length} artists with duplicate profiles
            </Typography>
          </Box>
          <List>
            {authorGroups.map(group => (
              <Paper 
                key={group.name} 
                elevation={3} 
                className="author-group"
                sx={{
                  borderLeft: group.hasDuplicates ? '4px solid #f44336' : 'none',
                  bgcolor: group.hasDuplicates ? 'rgba(244, 67, 54, 0.08)' : 'inherit'
                }}
              >
                <ListItem>
                  <Box className="group-header">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="h6" onClick={() => handleGroupClick(group.name)} style={{ cursor: 'pointer' }}>
                        {group.name} ({group.authors.length} profiles)
                      </Typography>
                      {group.hasDuplicates && (
                        <Typography variant="body2" color="error">
                          Duplicate profiles detected
                        </Typography>
                      )}
                    </Box>
                    {group.hasDuplicates && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <input
                          type="checkbox"
                          checked={group.authors.every(author => 
                            author.authorKey && selectedAuthors.includes(author.authorKey)
                          )}
                          onChange={() => handleSelectAllInGroup(group.authors)}
                        />
                        <Typography variant="body2">Select All</Typography>
                      </Box>
                    )}
                  </Box>
                </ListItem>
                {selectedGroup === group.name && (
                  <Box className="author-list">
                    {group.authors.map(author => (
                      <ListItem key={author.authorKey} className="author-item">
                        <Box className="author-content">
                          <input
                            type="checkbox"
                            checked={selectedAuthors.includes(author.authorKey || '')}
                            onChange={() => author.authorKey && handleAuthorSelection(author.authorKey)}
                            disabled={!author.authorKey}
                          />
                          <img
                            src={author.image ? constructUrl(author.image) : '/assets/icons/user.svg'}
                            alt={author.name}
                            className="author-image"
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = '/assets/icons/user.svg';
                            }}
                          />
                          <Box className="author-details">
                            <Typography variant="body1">{author.bio || 'No bio available'}</Typography>
                            <Typography variant="body2">
                              Website: {author.website || 'No website available'}
                            </Typography>
                            <Box className="author-tags">
                              {author.tags?.authorTags?.map((tag, index) => (
                                <span key={index} className="tag">{tag}</span>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      </ListItem>
                    ))}
                    {selectedAuthors.length >= 2 && (
                      <Box className="merge-actions">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleMergeClick}
                        >
                          Merge Selected Profiles
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
              </Paper>
            ))}
          </List>
        </Box>
      </Box>
      {showMergePreferences && (
        <Paper
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '800px',
            maxHeight: '90vh',
            overflow: 'auto',
            zIndex: 1000,
          }}
        >
          <AuthorMergePreferences
            authors={authorsToMerge}
            onMerge={handleMergeAuthors}
            onCancel={() => setShowMergePreferences(false)}
          />
        </Paper>
      )}
    </Box>
  );
};

export default ArtistsView; 