import React, { FunctionComponent, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Link as LinkIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import { OrganisationManager } from "../models/OrganisationManager";
import userStore from "../stores/UserStore";
import { IMember, MemberRole, IGroup } from "../types/organisation";
import "./OrgDashboardScreen.css";
import QRCode from 'qrcode';
import MemberManagement from "../components/members/MemberManagement";
import { Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { AdminNav } from '../components/AdminNav';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const OrgDashboardScreen: FunctionComponent = observer(() => {
  const [tabValue, setTabValue] = useState(0);
  const [members, setMembers] = useState<IMember[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [newMemberRole, setNewMemberRole] = useState<MemberRole>("member");
  const [accessLinks, setAccessLinks] = useState<{ [key: string]: string }>({});
  const [qrCodes, setQrCodes] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState<string | null>(null);
  const { currentOrganisation } = userStore;
  const [loading, setLoading] = useState(true);
  const [addingMember, setAddingMember] = useState(false);
  const [generatingLink, setGeneratingLink] = useState<MemberRole | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    if (!currentOrganisation) return;
    setLoading(true);
    try {
      // Load groups and members in parallel
      const [loadedGroups, loadedMembers] = await Promise.all([
        OrganisationManager.getOrganisationGroups(currentOrganisation.id),
        OrganisationManager.getOrganisationMembers(currentOrganisation.id)
      ]);

      setGroups(loadedGroups);
      setMembers(loadedMembers);
    } catch (err) {
      console.error('Error loading data:', err);
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = async () => {
    if (!currentOrganisation) return;
    setAddingMember(true);
    try {
      await OrganisationManager.inviteMemberByEmail(
        currentOrganisation.id,
        newMemberEmail,
        "moderator"
      );
      setIsAddMemberOpen(false);
      setNewMemberEmail("");
      await loadData();
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setAddingMember(false);
    }
  };

  const generateQRCode = async (data: string): Promise<string> => {
    try {
      return await QRCode.toDataURL(data, {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 0.92
      });
    } catch (error) {
      console.error('Error generating QR code:', error);
      return '';
    }
  };

  const handleGenerateAccessLink = async (role: MemberRole) => {
    if (!currentOrganisation) return;
    setGeneratingLink(role);
    try {
      const link = await OrganisationManager.generateAccessCode(
        currentOrganisation.id,
        role
      );
      setAccessLinks(prev => ({ ...prev, [role]: link }));
      const qrCode = await generateQRCode(link);
      setQrCodes(prev => ({ ...prev, [role]: qrCode }));
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setGeneratingLink(null);
    }
  };

  const handleCopyLink = (role: MemberRole) => {
    if (accessLinks[role]) {
      navigator.clipboard.writeText(accessLinks[role]);
    }
  };


  const getRoleOptions = () => {
    if (!currentOrganisation) return [
      { value: "member", label: "Member" },
      { value: "moderator", label: "Moderator" }
    ];

    if (currentOrganisation.type === 'school') {
      return [
        { value: "member", label: "Student" },
        { value: "moderator", label: "Teacher" }
      ];
    }

    return [
      { value: "member", label: "Member" },
      { value: "moderator", label: "Moderator" }
    ];
  };

  const getRoleLabels = () => {
    if (!currentOrganisation) return [
      { value: "member", label: "Member" },
      { value: "moderator", label: "Moderator" }
    ];

    if (currentOrganisation.type === 'school') {
      return [
        { value: "member", label: "Student" },
        { value: "moderator", label: "Teacher" }
      ];
    }

    return [
      { value: "member", label: "Member" },
      { value: "moderator", label: "Moderator" }
    ];
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100vh',
      marginTop: '80px'
    }}>
      <AdminNav 
        showProjectDropdown={false}
        imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
      />
      <Box sx={{ p: 3, flexGrow: 1, overflow: 'auto' }}>
        <Paper elevation={3} sx={{ p: 4, maxWidth: 1200, mx: "auto" }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 3 
          }}>
            <Typography variant="h4">
              {currentOrganisation?.name} - Organisation Dashboard
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setIsAddMemberOpen(true)}
            >
              Add Member
            </Button>
          </Box>

          <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
            <Tab label="Members" />
            <Tab label="Access Links" />
            <Tab label="Settings" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6">Organisation Members</Typography>
            </Box>

            {currentOrganisation && (
              <MemberManagement
                organisationId={currentOrganisation.id}
                organisationType={currentOrganisation.type}
                adminId={currentOrganisation.adminId}
                members={members}
                groups={groups}
                onMembersChange={loadData}
              />
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Access Links
              </Typography>
              {getRoleLabels().map(({ value, label }) => (
                <Box key={value} sx={{ mb: 4 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {label} Access
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={generatingLink === value ? <CircularProgress size={20} color="inherit" /> : <LinkIcon />}
                    onClick={() => handleGenerateAccessLink(value as MemberRole)}
                    disabled={generatingLink === value}
                    sx={{ mr: 2, mb: 2, minWidth: 200 }}
                  >
                    {generatingLink === value ? `Generating ${label} Link...` : `Generate New ${label} Link`}
                  </Button>
                  {accessLinks[value] && (
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        value={accessLinks[value]}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                        <Button
                          variant="outlined"
                          onClick={() => handleCopyLink(value as MemberRole)}
                          startIcon={<LinkIcon />}
                        >
                          Copy Link
                        </Button>
                        {qrCodes[value] && (
                          <Box sx={{ 
                            mt: 2, 
                            p: 2, 
                            border: '1px solid #ccc', 
                            borderRadius: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                          }}>
                            <Typography variant="subtitle2" gutterBottom>
                              QR Code
                            </Typography>
                            <img 
                              src={qrCodes[value]} 
                              alt={`QR Code for ${label}`}
                              style={{ width: 200, height: 200 }}
                            />
                            <Button
                              variant="outlined"
                              onClick={() => {
                                const link = document.createElement('a');
                                link.download = `${label.toLowerCase()}-access-qr.png`;
                                link.href = qrCodes[value];
                                link.click();
                              }}
                              sx={{ mt: 1 }}
                            >
                              Download QR Code
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Typography variant="h6" gutterBottom>
              Organisation Settings
            </Typography>
            {/* Add Organisation settings here */}
          </TabPanel>

          <Dialog 
            open={isAddMemberOpen} 
            onClose={() => !addingMember && setIsAddMemberOpen(false)}
          >
            <DialogTitle>Add Moderator</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Email Address"
                type="email"
                fullWidth
                value={newMemberEmail}
                onChange={(e) => setNewMemberEmail(e.target.value)}
                disabled={addingMember}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsAddMemberOpen(false)} disabled={addingMember}>
                Cancel
              </Button>
              <Button 
                onClick={handleAddMember} 
                variant="contained"
                disabled={addingMember || !newMemberEmail}
                sx={{ minWidth: 120 }}
              >
                {addingMember ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} color="inherit" />
                    <span>Adding...</span>
                  </Box>
                ) : (
                  'Add Moderator'
                )}
              </Button>
            </DialogActions>
          </Dialog>

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
});

export default OrgDashboardScreen; 