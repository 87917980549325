import React from "react";
import { TextField, Button, Checkbox, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import "./InputForms.css";
import FormButtons from './FormButtons';
import { CollectionLogic } from "../types";
import { IGroup } from "../types/organisation";

interface ProjectFormProps {
  projectName: string;
  setProjectName: React.Dispatch<React.SetStateAction<string>>;
  projectOwnerUserKey: string;
  setProjectOwnerUserKey: React.Dispatch<React.SetStateAction<string>>;
  projectIconUrl: string;
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isQREnabled: boolean;
  setIsQREnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isResponseEnabled: boolean;
  setIsResponseEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isTransitProjectEnabled: boolean;
  setIsTransitProjectEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isUploading: boolean;
  showDelete: boolean;
  onCancel: () => void;
  onSave: () => Promise<void>;
  onDelete?: () => Promise<void>;
  collectionLogic: CollectionLogic;
  setCollectionLogic: React.Dispatch<React.SetStateAction<CollectionLogic>>;
  groups: IGroup[];
  selectedGroupIds: string[];
  setSelectedGroupIds: React.Dispatch<React.SetStateAction<string[]>>;
  isPrivate: boolean;
  hasOrganisation: boolean;
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  projectName,
  setProjectName,
  projectOwnerUserKey,
  setProjectOwnerUserKey,
  projectIconUrl,
  selectedFile,
  setSelectedFile,
  onFileChange,
  isQREnabled,
  setIsQREnabled,
  isResponseEnabled,
  setIsResponseEnabled,
  isTransitProjectEnabled,
  setIsTransitProjectEnabled,
  isUploading,
  showDelete,
  onCancel,
  onSave,
  onDelete,
  collectionLogic,
  setCollectionLogic,
  groups,
  selectedGroupIds,
  setSelectedGroupIds,
  isPrivate,
  hasOrganisation,
}) => {
  return (
    <>
      <TextField
        className="input-container"
        color="primary"
        label="User Key"
        size="medium"
        placeholder="User Key"
        variant="filled"
        type="text"
        value={projectOwnerUserKey}
        onChange={(event) => setProjectOwnerUserKey(event.target.value)}
      />
      <TextField
        className="input-container"
        color="primary"
        label="Project Name"
        size="medium"
        placeholder="Project Name"
        variant="filled"
        type="text"
        value={projectName}
        onChange={(event) => setProjectName(event.target.value)}
      />
      <div className="image-uploader">
        <img 
          className="icon" 
          alt="Project Icon" 
          src={projectIconUrl || '/assets/overhear-assets/images/ovh-logoartboard-12x-1.png'} 
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null; // Prevent infinite loop
            target.src = '/assets/overhear-assets/images/ovh-logoartboard-12x-1.png';
          }}
        />
        <Button
          className="button-action-submit"
          color="primary"
          size="medium"
          variant="contained"
          component="label"
        >
          Change Icon
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={onFileChange}
          />
        </Button>
      </div>
      <div className="check-toggle">
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon style={{ color: 'black', fontSize: 24 }} />}
          checkedIcon={<CheckBoxIcon style={{ color: 'black', fontSize: 24 }} />}
          checked={isQREnabled}
          onChange={(event) => setIsQREnabled(event.target.checked)}
        />
        <a className="qr-codes">QR Codes</a>
      </div>
      <div className="check-toggle">
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon style={{ color: 'black', fontSize: 24 }} />}
          checkedIcon={<CheckBoxIcon style={{ color: 'black', fontSize: 24 }} />}
          checked={isResponseEnabled}
          onChange={(event) => setIsResponseEnabled(event.target.checked)}
        />
        <a className="response-enabled">Enable Responses</a>
      </div>
      <div className="check-toggle">
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon style={{ color: 'black', fontSize: 24 }} />}
          checkedIcon={<CheckBoxIcon style={{ color: 'black', fontSize: 24 }} />}
          checked={isTransitProjectEnabled}
          onChange={(event) => setIsTransitProjectEnabled(event.target.checked)}
        />
        <a className="transit-project">Enable Transit Project</a>
      </div>

      <FormControl fullWidth variant="filled" className="dropdown-container">
        <InputLabel>Project Logic</InputLabel>
        <Select
          value={collectionLogic}
          onChange={(event) => setCollectionLogic(event.target.value as CollectionLogic)}
          variant="filled"
          className="collection-logic-dropdown"
          sx={{
            '& .MuiSelect-select': {
              padding: '12px',
            },
            backgroundColor: '#f5f5f5',
          }}
        >
          <MenuItem value={CollectionLogic.Regular}>Regular</MenuItem>
          <MenuItem value={CollectionLogic.Sequential}>Sequential</MenuItem>
          <MenuItem value={CollectionLogic.Branching}>Branching</MenuItem>
        </Select>
      </FormControl>

      {isPrivate && hasOrganisation && (
        <FormControl fullWidth variant="filled" className="dropdown-container">
          <InputLabel>Group Access</InputLabel>
          <Select
            multiple
            value={selectedGroupIds}
            onChange={(event) => setSelectedGroupIds(event.target.value as string[])}
            variant="filled"
            displayEmpty
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <em>Select groups to grant access</em>;
              }
              return groups
                .filter(group => (selected as string[]).includes(group.id))
                .map(group => group.name)
                .join(', ');
            }}
            sx={{
              '& .MuiSelect-select': {
                padding: '12px',
              },
              backgroundColor: '#f5f5f5',
              '& .MuiSelect-select.MuiSelect-multiple.Mui-focused': {
                backgroundColor: 'transparent',
              },
              '& em': { // Style for placeholder
                fontStyle: 'normal',
                color: 'rgba(0, 0, 0, 0.6)',
              },
            }}
          >
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormButtons
        onCancel={onCancel}
        onSave={onSave}
        onDelete={onDelete}
        isUploading={isUploading}
        showDelete={showDelete}
      />
    </>
  );
};

export default ProjectForm;