// src/pages/ProjectView.tsx
import React, { useCallback, useEffect, useState } from 'react';
import AdminNav from '../components/AdminNav';
import ProjectItem from '../components/ProjectItem';
import { IProject } from '../types/Project';
import { useAdminNav } from '../hooks/useAdminNav';
import { ProjectManager } from '../models/ProjectManager';
import "./ListPages.css"
import { observer } from 'mobx-react-lite'; // Import observer from mobx-react-lite
import { projectStore } from '../stores/ProjectStore'; // Import projectStore
import { useNavigate } from 'react-router-dom';
import { Icon, AdminButtonRow } from '../components';
import ListHeader from '../components/ListHeader'; // Import ListHeader component
import userStore from '../stores/UserStore';

const ProjectView: React.FC = observer(() => { 
  const navigate = useNavigate();
  const { userRole } = useAdminNav();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [isCreateProjectOpen, setCreateProjectOpen] = useState(false);
  const openCreateProject = useCallback(() => {
    setCreateProjectOpen(true);
  }, []);

  // Use projects directly from projectStore
  const projects = projectStore.projects;

  useEffect(() => {
    const loadProjects = async () => {
      try {
        setLoading(true);
        const currentUser = userStore.currentUser;
        if (!currentUser) throw new Error('No user logged in');

        if (userRole === 'superAdmin') {
          // Super admin sees all projects in the database
          await projectStore.fetchProjects();
        } else if (userRole === 'admin') {
          // Admin sees all projects
          await projectStore.fetchProjects();
        } else if (userRole === 'organisationAdmin' && userStore.currentOrganisation) {
          // Organization admin sees all projects from their organization's moderators
          const projectData = await ProjectManager.observeOrganisationProjects(userStore.currentOrganisation.id);
          projectStore.setProjects(projectData);
        } else {
          // Moderators only see their own projects
          const projectData = await ProjectManager.observeModeratorProjects(currentUser.id);
          projectStore.setProjects(projectData);
        }
      } catch (err) {
        console.error('Error loading projects:', err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    loadProjects();
  }, [userRole, userStore.currentOrganisation]); // Add currentOrganisation as dependency

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!projects || projects.length === 0) {
    return (
      <div className='list-page'>
        <AdminNav
          imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
          showProjectDropdown={false} 
          userRole={userRole}
        />
        <AdminButtonRow
          settingsIcon={<Icon icon="settings" size={24} />}
          handleAdminMessageViewClick={() => navigate("/message-view")}
          handleProjectViewClick={() => navigate("/project-view")}
          handleWandersViewClick={() => navigate("/wanders-view")}
          handleUserAccessViewClick={() => navigate("/users-access-view")}
          defaultView="project-view"
          userRole={userRole}
        />
        <div>No projects available. Click 'Create New Project' to get started.</div>
      </div>
    );
  }

  return (
    <div className='list-page' style={{ marginTop: '80px' }}>
      <AdminNav
        imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
        showProjectDropdown={false} 
        userRole={userRole}
      />
      <AdminButtonRow
        settingsIcon={<Icon icon="settings" size={24} />}
        handleAdminMessageViewClick={() => navigate("/message-view")}
        handleProjectViewClick={() => navigate("/project-view")}
        handleWandersViewClick={() => navigate("/wanders-view")}
        handleUserAccessViewClick={() => navigate("/users-access-view")}
        defaultView="project-view"
        userRole={userRole}
      />
      <ListHeader headers={['Live', 'Icon', 'Name', 'Pins', 'Tags']} columnCount={6} />
      <div className='body'>
        {projects.map((project) => (
          <ProjectItem key={project.project_id} project={project} />
        ))}
      </div>
    </div>
  );
});

export default ProjectView;
