import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RecordingAccordion } from '../components/Accordions/RecordingAccordion';
import { AdminNav, Icon, AdminButtonRow } from '../components';
import { useNavigate } from 'react-router-dom';
import { useAdminNav } from '../hooks/useAdminNav';
import { handleDelete } from '../utilities/handleDelete';
import { Select, MenuItem, Checkbox, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { RecordingManager, IRecordingWithModerationInfo } from '../models/RecordingManager';
import { ContentRating } from '../types';
import './ListPages.css';
import './ModerationView.css';

export const ModerationView: React.FC = observer(() => {
  const [moderationRecordings, setModerationRecordings] = useState<IRecordingWithModerationInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRecordings, setSelectedRecordings] = useState<string[]>([]);
  const [contentRatings, setContentRatings] = useState<Record<string, ContentRating>>({});
  const navigate = useNavigate();

  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole
  } = useAdminNav();

  useEffect(() => {
    const fetchModerationRecordings = async () => {
      try {
        const recordings = await RecordingManager.fetchModerationRecordings();
        setModerationRecordings(recordings);
        
        // Initialize content ratings from fetched data
        const initialRatings: Record<string, ContentRating> = {};
        recordings.forEach(recording => {
          if (recording.contentRating) {
            initialRatings[recording.key] = recording.contentRating;
          }
        });
        setContentRatings(initialRatings);
      } catch (error) {
        console.error('Error fetching moderation recordings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchModerationRecordings();
  }, []);

  const handleRecordingSelect = (recordingKey: string) => {
    setSelectedRecordings(prev => {
      if (prev.includes(recordingKey)) {
        return prev.filter(key => key !== recordingKey);
      } else {
        return [...prev, recordingKey];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedRecordings.length === moderationRecordings.length) {
      setSelectedRecordings([]);
    } else {
      setSelectedRecordings(moderationRecordings.map(r => r.key));
    }
  };

  const handleContentRatingChange = async (recordingKey: string, rating: ContentRating) => {
    try {
      const recording = moderationRecordings.find(r => r.key === recordingKey);
      await RecordingManager.updateContentRating(recordingKey, rating, recording?.moderationResult);

      // Update local state
      setContentRatings(prev => ({
        ...prev,
        [recordingKey]: rating
      }));

      // Remove from moderation list if successful
      setModerationRecordings(prev => 
        prev.filter(recording => recording.key !== recordingKey)
      );
    } catch (error) {
      console.error('Error updating content rating:', error);
    }
  };

  const handleRecordingDelete = async (recordingKey: string) => {
    try {
      await handleDelete(recordingKey, '', () => {
        setModerationRecordings(prev => prev.filter(r => r.key !== recordingKey));
      }, setLoading);
    } catch (error) {
      console.error('Error deleting recording:', error);
    }
  };

  return (
    <div className="list-page">
      <AdminNav
        showProjectDropdown={false}
        imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
        projectName={projectName}
        userRole={userRole}
        projects={projects}
        selectedProject={selectedProject}
        onProjectChange={onProjectChange}
      />
      <AdminButtonRow
        settingsIcon={<Icon icon="settings" size={24} />}
        handleAdminMessageViewClick={() => navigate("/message-view")}
        handleProjectViewClick={() => navigate("/project-view")}
        handleWandersViewClick={() => navigate("/wanders-view")}
        handleUserAccessViewClick={() => navigate("/user-access-view")}
        defaultView="moderation-view"
        userRole={userRole}
      />
      
      <div className="moderation-view-container">
        <h1 className="moderation-view-title">Content Moderation</h1>
        {loading ? (
          <div className="moderation-view-loading">Loading...</div>
        ) : moderationRecordings.length === 0 ? (
          <div className="moderation-view-empty">
            No recordings currently require moderation.
          </div>
        ) : (
          <div className="moderation-recordings-list">
            <div className="moderation-header">
              <Checkbox
                checked={selectedRecordings.length === moderationRecordings.length}
                indeterminate={selectedRecordings.length > 0 && selectedRecordings.length < moderationRecordings.length}
                onChange={handleSelectAll}
              />
              <span>Select All</span>
            </div>
            {moderationRecordings.map((recording) => (
              <div key={recording.key} className="moderation-item">
                <div className="moderation-controls-left">
                  <Checkbox
                    checked={selectedRecordings.includes(recording.key)}
                    onChange={() => handleRecordingSelect(recording.key)}
                  />
                </div>
                <RecordingAccordion
                  recordingKey={recording.key}
                  pinKey=""
                  sourceView="PinList"
                />
                <div className="moderation-controls-right">
                  <Select
                    value={contentRatings[recording.key] || ''}
                    onChange={(e) => handleContentRatingChange(recording.key, e.target.value as ContentRating)}
                    displayEmpty
                    className="content-rating-select"
                  >
                    <MenuItem value="" disabled>Select Rating</MenuItem>
                    <MenuItem value="Adult">Adult</MenuItem>
                    <MenuItem value="Young Adult">Young Adult</MenuItem>
                    <MenuItem value="Family">Family</MenuItem>
                  </Select>
                  <IconButton 
                    onClick={() => handleRecordingDelete(recording.key)}
                    className="delete-button"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default ModerationView;