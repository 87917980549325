// src/stores/PinStore.ts
import { makeAutoObservable, runInAction } from "mobx";
import { IPin } from "../types/Pin";
import { PinManager } from "../models/PinManager";

export class PinStore {
  pins = new Map<string, IPin>();
  loading = false;
  error: Error | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setPins(pins: IPin[]) {
    runInAction(() => {
      this.pins.clear();
      pins.sort((a, b) => {
        if (a.pinOrder && b.pinOrder) {
          if (a.pinOrder.id !== b.pinOrder.id) {
            return a.pinOrder.id - b.pinOrder.id;
          }
          if (a.pinOrder.mainBranch !== b.pinOrder.mainBranch) {
            return a.pinOrder.mainBranch - b.pinOrder.mainBranch;
          }
          return a.pinOrder.subBranch - b.pinOrder.subBranch;
        }
        return 0;
      }).forEach(pin => {
        this.pins.set(pin.pinKey, pin);
      });
    });
  }

  addPin(newPin: IPin) {
    this.pins.set(newPin.pinKey, newPin);
  }

  removePin(pinKey: string) {
    this.pins.delete(pinKey);
  }

  clearPins() {
    this.pins.clear();
  }

  async createPin(newPin: IPin) {
    this.loading = true;
    this.error = null;
    try {
      await PinManager.createPin(newPin);
      runInAction(() => {
        this.pins.set(newPin.pinKey, newPin);
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('An unknown error occurred');
        this.loading = false;
      });
      console.error('Error creating new pin:', error);
      throw error;
    }
  }

  async fetchPin(pinKey: string) {
    this.loading = true;
    this.error = null;
    try {
      const pin = await PinManager.getPin(pinKey);
      runInAction(() => {
        if (pin) {
          this.pins.set(pinKey, pin);
        }
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        console.error(`Failed to fetch pin with key ${pinKey}:`, error);
        this.error = error instanceof Error ? error : new Error('An unknown error occurred');
        this.loading = false;
      });
    }
  }

  async updatePin(updatedPin: IPin) {
    this.loading = true;
    this.error = null;
    try {
      await PinManager.updatePin(updatedPin);
      runInAction(() => {
        this.pins.set(updatedPin.pinKey, updatedPin);
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('An unknown error occurred');
        this.loading = false;
      });
      console.error('Error updating pin:', error);
      throw error;
    }
  }

  getPinKeysForProject(project_id: string): string[] {
    return Array.from(this.pins.values())
      .filter(pin => pin.project === project_id)
      .map(pin => pin.pinKey);
  }

  getPin(pinKey: string): IPin | undefined {
    return this.pins.get(pinKey);
  }

  setPin(pinKey: string, pin: IPin) {
    this.pins.set(pinKey, pin);
  }

  async togglePinAvailability(pinKey: string) {
    this.loading = true;
    try {
      const pin = this.pins.get(pinKey);
      if (!pin) {
        throw new Error('Pin not found');
      }
      const updatedPin = { ...pin, isAvailable: !pin.isAvailable };
      
      // Optimistically update the UI
      runInAction(() => {
        this.pins.set(pinKey, updatedPin);
        this.loading = false;
      });
  
      // Update in Firestore
      await PinManager.updatePin(updatedPin);
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error : new Error('An unknown error occurred');
        this.loading = false;
      });
      console.error('Error toggling pin availability:', error);
    }
  }
}

export default new PinStore();