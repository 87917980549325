import { makeAutoObservable } from 'mobx';
import { auth } from '../firebase/firebase';
import { User, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { MemberRole } from '../types/organisation';
import { OrganisationManager } from '../models/OrganisationManager';
import userStore from './UserStore';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';

class AuthenticationStore {
  user: User | null = null;
  loading = false;
  error: Error | null = null;

  constructor() {
    makeAutoObservable(this);
    auth.onAuthStateChanged(this.setUser);
  }

  setUser = (user: User | null) => {
    this.user = user;
  }

  signInWithRole = async (email: string, password: string, role: MemberRole) => {
    try {
      this.loading = true;
      this.error = null;

      // Sign in with Firebase
      let userCredential;
      try {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      } catch (firebaseError) {
        console.error('Firebase authentication error:', firebaseError);
        // Log specific firebase error code
        if (firebaseError instanceof Error) {
          console.error('Firebase error code:', (firebaseError as any).code);
        }
        throw firebaseError;
      }

      const user = userCredential.user;
      
      // For superadmin role, check SuperAdmins collection
      if (role === 'superAdmin') {
        // Check if user is in SuperAdmins collection
        const superAdminRef = doc(db, 'SuperAdmins', 'ids');
        const superAdminSnap = await getDoc(superAdminRef);

        if (superAdminSnap.exists() && superAdminSnap.data().userKeys) {
          const userKeys = superAdminSnap.data().userKeys as string[];
          if (!userKeys.includes(user.uid)) {
            throw new Error('You do not have superadmin access');
          }
        } else {
          throw new Error('SuperAdmin data not found');
        }

        // Set user in UserStore without organization data
        userStore.setUser({
          userId: user.uid,
          email: user.email || '',
          displayName: user.displayName || '',
          userRole: 'superAdmin'
        });

        return userCredential;
      }
      
      // For organization roles (organisationAdmin, moderator, member)
      const organisations = await OrganisationManager.getUserOrganisations(user.uid);
      
      // Verify role matches the requested role or has higher privileges
      const orgValues = Object.values(organisations) as { role: MemberRole }[];
      
      const hasRole = orgValues.some(org => {
        // Allow organisationAdmin to access moderator features
        if (role === 'moderator') {
          const hasAccess = org.role === 'moderator' || org.role === 'organisationAdmin';
          return hasAccess;
        }
        return org.role === role;
      });
      
      if (!hasRole) {
        throw new Error(`You do not have ${role} access to any organization`);
      }
      
      // Set user in UserStore with role
      userStore.setUser({
        userId: user.uid,
        email: user.email || '',
        displayName: user.displayName || '',
        organisations,
        userRole: role
      });
      
      // If there's only one Organisation, set it as current
      if (Object.keys(organisations).length === 1) {
        const orgId = Object.keys(organisations)[0];
        await userStore.switchOrganisation(orgId);
      }

      return userCredential;
    } catch (error) {
      if (error instanceof Error) {
        // Handle Firebase Auth errors
        if (error.message.includes('auth/user-not-found')) {
          this.error = new Error('No account found with this email');
        } else if (error.message.includes('auth/wrong-password')) {
          this.error = new Error('Incorrect password');
        } else if (error.message.includes('auth/invalid-email')) {
          this.error = new Error('Invalid email format');
        } else {
          this.error = error;
        }
      } else {
        this.error = new Error('An unexpected error occurred');
      }
      throw this.error;
    } finally {
      this.loading = false;
    }
  }

  signOut = async () => {
    try {
      await signOut(auth);
      userStore.clearUser();
    } catch (error) {
      throw error;
    }
  }

  get isAuthenticated() {
    return !!this.user;
  }

  // Add a flag to distinguish between superadmin and org admin logins
  private isSuperAdminLogin = false;

  setSuperAdminLogin(value: boolean) {
    this.isSuperAdminLogin = value;
  }
}

const authenticationStore = new AuthenticationStore();
export default authenticationStore;