import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Theme, ThemeContexts, lightTheme, darkTheme } from '../theme';

type ThemeContextType = {
  themeScheme: ThemeContexts;
  setThemeContextOverride: (newTheme: ThemeContexts) => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  themeScheme: undefined,
  setThemeContextOverride: (_newTheme: ThemeContexts) => {
    console.error("Tried to call setThemeContextOverride before the ThemeProvider was initialized");
  },
});

const themeContextToTheme = (themeContext: ThemeContexts, mainColor: string, iconColor: string): Theme => {
  const baseTheme = themeContext === "dark" ? darkTheme : lightTheme;
  
  return {
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      palette: {
        ...baseTheme.colors.palette,
        main: mainColor,
        icon: iconColor,
      },
    },
  };
};

export const useThemeProvider = (initialTheme: ThemeContexts = undefined) => {
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [overrideTheme, setTheme] = useState<ThemeContexts>(initialTheme);
  const [mainColor, setMainColor] = useState('#1976d2');
  const [iconColor, setIconColor] = useState('#000000');

  const setThemeContextOverride = useCallback((newTheme: ThemeContexts) => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme || '');
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as ThemeContexts;
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const themeScheme = overrideTheme || (prefersDarkMode ? 'dark' : 'light');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', themeScheme);
  }, [themeScheme]);

  return {
    themeScheme,
    setThemeContextOverride,
    ThemeProvider: ThemeContext.Provider,
    setMainColor,
    setIconColor,
  };
};

type ThemedStyle<T> = (theme: Theme) => T;
type ThemedStyleArray<T> = Array<ThemedStyle<T> | T>;

interface UseAppThemeValue {
  setThemeContextOverride: (newTheme: ThemeContexts) => void;
  theme: Theme;
  themeContext: ThemeContexts;
  themed: <T>(styleOrStyleFn: ThemedStyle<T> | T | ThemedStyleArray<T>) => T;
}

export const useAppTheme = (): UseAppThemeValue => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useAppTheme must be used within a ThemeProvider");
  }

  const { themeScheme, setThemeContextOverride } = context;
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const themeContext = useMemo(
    () => themeScheme || (prefersDarkMode ? 'dark' : 'light'),
    [themeScheme, prefersDarkMode],
  );

  const theme = useMemo(
    () => themeContextToTheme(themeContext, '#1976d2', '#000000'),
    [themeContext],
  );

  const themed = useCallback(
    <T>(styleOrStyleFn: ThemedStyle<T> | T | ThemedStyleArray<T>) => {
      const flatStyles = [styleOrStyleFn].flat(3);
      const stylesArray = flatStyles.map((f) => {
        if (typeof f === 'function') {
          return (f as ThemedStyle<T>)(theme);
        } else {
          return f;
        }
      });

      return Object.assign({}, ...stylesArray) as T;
    },
    [theme],
  );

  return {
    setThemeContextOverride,
    theme,
    themeContext,
    themed,
  };
}; 