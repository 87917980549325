import React from 'react';
import { useThemeProvider } from './hooks/useAppTheme';
import AppRoutes from './routes/AppRoutes';

const App: React.FC = () => {
  const { ThemeProvider, themeScheme, setThemeContextOverride } = useThemeProvider();

  return (
    <ThemeProvider value={{ themeScheme, setThemeContextOverride }}>
      <AppRoutes />
    </ThemeProvider>
  );
};

export default App;
