// ProjectStore.ts
import { makeAutoObservable, runInAction } from 'mobx';
import { IProject } from "../types/Project";
import { ProjectManager } from '../models';

class ProjectStore {
  selectedProject: string = localStorage.getItem('selectedProject') || '';
  projects: IProject[] = [];
  loading: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
    this.fetchProjects(); // Fetch projects when store is initialized
  }

  setSelectedProject(project: string) {
    this.selectedProject = project;
    localStorage.setItem('selectedProject', project);
  }

  getSelectedProject() {
    return this.selectedProject;
  }

  addProject(newProject: IProject) {
    runInAction(() => {
      this.projects.push(newProject);
    });
  }

  setProjects(projects: IProject[]) {
    runInAction(() => {
      this.projects = projects;
    });
  }

  async fetchProjects() {
    this.loading = true;
    this.error = null;
    try {
      const projectData = await ProjectManager.observeProjects();
      runInAction(() => {
        this.projects = projectData;
        if (!this.selectedProject && this.projects.length > 0) {
          this.setSelectedProject(this.projects[0].project_id);
        }
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        console.error('Error fetching projects:', error);
        this.error = error instanceof Error ? error.message : 'Error fetching projects';
        this.loading = false;
      });
    }
  }

  deleteProject = (project_id: string) => {
    runInAction(() => {
      this.projects = this.projects.filter(project => project.project_id !== project_id);
    });
  }

  async updateProject(project_id: string, updatedProject: IProject) {
    try {
      const index = this.projects.findIndex(project => project.project_id === project_id);
      if (index !== -1) {
        runInAction(() => {
          this.projects[index] = { ...this.projects[index], ...updatedProject };
        });
      } else {
        throw new Error('Project not found');
      }
    } catch (error) {
      console.error('Error updating project:', error);
      throw error;
    }
  }

  async toggleProjectAvailability(project_id: string) {
    try {
      // Find the project in the local state
      let project = this.projects.find(p => p.project_id === project_id);
      
      if (!project) {
        // If project is not in local state, try to fetch it
        const fetchedProject = await ProjectManager.getProject(project_id);
        if (!fetchedProject) {
          throw new Error('Project not found');
        }
        runInAction(() => {
          this.projects.push(fetchedProject);
        });
        project = fetchedProject;
      }

      if (!project) {
        throw new Error('Project not found after fetch attempt');
      }

      // Toggle the isAvailable property
      const updatedIsAvailable = !project.isAvailable;

      // Optimistically update the UI
      runInAction(() => {
        const index = this.projects.findIndex(p => p.project_id === project_id);
        if (index !== -1) {
          this.projects[index] = {
            ...this.projects[index],
            isAvailable: updatedIsAvailable
          };
        }
      });

      // Update in Firestore
      await ProjectManager.updateProjectAvailability(project_id, updatedIsAvailable);
    } catch (error) {
      // Revert the optimistic update if the server update fails
      runInAction(() => {
        const index = this.projects.findIndex(p => p.project_id === project_id);
        if (index !== -1) {
          this.projects[index] = {
            ...this.projects[index],
            isAvailable: !this.projects[index].isAvailable
          };
        }
      });
      console.error('Error toggling project availability:', error);
      throw error;
    }
  }
}

export const projectStore = new ProjectStore();
