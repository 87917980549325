import React, { useState } from 'react';
import { Checkbox, Button, Box } from '@mui/material';
import './TermsAndConditions.css';
import signatureImage from '../images/Signature.png';  
import overhearLightImage from '../images/overhearlight.png';

const termsAndConditionsText = `
Terms and Conditions for Recording Submission

By submitting a recording through this form, you agree to grant Overhear Ltd a single-use, non-exclusive, not-for-profit licence to use the submitted recording. This agreement is subject to the following terms:

1. Licence Grant: You grant Overhear Ltd a single-use, non-exclusive, worldwide, royalty-free licence to use, reproduce, distribute, and publicly perform the submitted recording for not-for-profit purposes.

2. Ownership: You retain all ownership rights to your submitted recording.

3. Non-Commercial Use: Overhear Ltd may only use the recording for non-commercial, not-for-profit purposes.

4. No Transfer: Overhear Ltd may not transfer or sublicense the rights granted herein to any third party.

5. Attribution: Overhear Ltd will provide appropriate attribution to you as the creator of the recording when used.

6. Term: This licence is granted for a single use of the recording. Any subsequent use will require a new agreement.

7. Warranties: You warrant that you have the right to grant this licence and that the recording does not infringe on any third-party rights.

8. Limitation of Liability: Overhear Ltd shall not be liable for any damages arising from the use of the recording.

By checking the box below, you acknowledge that you have read, understood, and agree to these terms and conditions.
`;

const easyReadTermsText = `
Easy-Read Terms and Conditions

1. You're giving Overhear Ltd permission to use your recording once.
2. You still own your recording.
3. Overhear Ltd can't make money from your recording.
4. Overhear Ltd can't give this permission to anyone else.
5. Overhear Ltd will say it's your recording when they use it.
6. This permission is for one-time use only.
7. You promise you have the right to give this permission.
8. Overhear Ltd isn't responsible if something goes wrong with the recording.

By checking the box, you're saying you understand and agree to these terms.
`;

interface TermsAndConditionsProps {
  agreed: boolean;
  onAgree: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onContinue: () => void;
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ agreed, onAgree, onContinue }) => {
  const [isEasyRead, setIsEasyRead] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const toggleEasyRead = () => {
    setIsEasyRead(!isEasyRead);
  };

  const handlePrint = () => {
    const formattedText = (isEasyRead ? easyReadTermsText : termsAndConditionsText)
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0)
      .join('</p><p>');

    const printContent = `
      <html>
        <head>
          <title>Terms and Conditions</title>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; }
            .header { text-align: center; margin-bottom: 20px; }
            .header img { width: 100px; }
            .signature { margin-top: 20px; }
            .signature img { width: 150px; }
          </style>
        </head>
        <body>
          <div class="header">
            <img src="${overhearLightImage}" alt="Overhear Light Logo" />
          </div>
          <h2>Terms and Conditions</h2>
          <div><p>${formattedText}</p></div>
          <div class="signature">
            <p>Signed by:</p>
            <img src="${signatureImage}" alt="Signature" onload="window.print();" />
            <p>Tom Peel<br>Overhear Ltd Director</p>
          </div>
        </body>
      </html>
    `;

    const printWindow = window.open('', '_blank');
    printWindow?.document.write(printContent);
    printWindow?.document.close();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleContinue = () => {
    if (isChecked) {
      onContinue(); // Call the onContinue callback passed from the parent component
    }
  };

  return (
    <div className='terms-container'>
      <Box display="flex" justifyContent="center" mb={2}>
        <img 
          src={overhearLightImage}
          alt="Overhear Light" 
          style={{ 
            width: 120, 
            height: 140, 
            marginBottom: '1rem' 
          }} 
        />
      </Box>
      <h2>Terms and Conditions</h2>
      <Button onClick={toggleEasyRead} className='toggle-button'>
        {isEasyRead ? 'Show Full Version' : 'Show Easy-Read Version'}
      </Button>
      <Button onClick={handlePrint} className='print-button'>
        Print Terms & Conditions
      </Button>
      <div className='terms-text'>
        {isEasyRead ? easyReadTermsText : termsAndConditionsText}
      </div>
      <div className="checkbox-container">
        <div className="checkbox-wrapper">
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            id="terms-checkbox"
            className="terms-checkbox"
          />
          <label htmlFor="terms-checkbox">
            I acknowledge that I have read, understood, and agree to these terms and conditions
          </label>
        </div>
      </div>
      <button 
        className='continue-button'
        onClick={handleContinue} 
        disabled={!isChecked}
      >
        Continue
      </button>
    </div>
  );
};