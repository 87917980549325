//ADMIN PANNEL

import { doc, setDoc, getDoc, serverTimestamp, collection, query, where, getDocs, deleteDoc, updateDoc, arrayUnion, arrayRemove, writeBatch } from "firebase/firestore";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, deleteUser } from "firebase/auth";
import { db } from "../firebase/firebase";
import { IOrganisation, IOrganisationRegistration, IMember, MemberRole, IGroup, MemberCode } from "../types/organisation";
import { Timestamp } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { sendOrganizationInvite } from "../services/emailService";
import { ProjectManager } from "./ProjectManager";
import userStore from '../stores/UserStore';

export class OrganisationManager {
  // Animal emojis for student identification
  static readonly ANIMAL_EMOJIS = [
    '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐨', '🐯',
    '🦁', '🐮', '🐷', '🐸', '🐵', '🐔', '🦆', '🦅', '🦉', '🦋',
    '🐢', '🐙', '🦈', '🦒', '🦡', '🦃', '🐝', '🐞', '🦕',
    '🐳', '🐬', '🦭', '🐘', '🦬', '🦩', '🦚', '🦜', '🐧', '🐦',
    '🦢', '🦫', '🦦', '🦥', '🦨', '🦘', '🦙', '🦛', '🐪', '🐫'
  ];

  static async registerOrganisation(data: IOrganisationRegistration & { existingUser?: boolean }) {
    try {
      const auth = getAuth();
      let user;

      if (data.existingUser) {
        // Sign in existing user
        const userCredential = await signInWithEmailAndPassword(
          auth,
          data.adminEmail,
          data.adminPassword
        );
        user = userCredential.user;
      } else {
        // Create new user
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          data.adminEmail,
          data.adminPassword
        );
        user = userCredential.user;
      }

      // Create the Organisation document
      const orgRef = doc(db, 'Organisations', uuidv4());
      const orgData: IOrganisation = {
        id: orgRef.id,
        name: data.name,
        type: data.type,
        description: data.description || '',
        adminId: user.uid,
        createdAt: serverTimestamp() as Timestamp,
        updatedAt: serverTimestamp() as Timestamp,
        isActive: true,
        settings: {
          allowAnonymousMembers: false,
          requireApproval: true,
        }
      };

      // Create the member document for the admin
      const memberData: IMember = {
        userKey: user.uid,
        organisationId: orgRef.id,
        role: 'organisationAdmin',
        email: data.adminEmail,
        name: user.displayName || data.adminEmail.split('@')[0],
        joinedAt: serverTimestamp() as Timestamp,
        groupIds: []
      };

      // Get existing user data if it exists
      const userRef = doc(db, 'Users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        // Update existing user document
        const existingData = userDoc.data();
        await updateDoc(userRef, {
          Organisations: {
            ...existingData.Organisations,
            [orgRef.id]: memberData
          }
        });
      } else {
        // Create new user document
        await setDoc(userRef, {
          userKey: user.uid,
          email: data.adminEmail,
          displayName: user.displayName || data.adminEmail.split('@')[0],
          Organisations: {
            [orgRef.id]: memberData
          }
        });
      }

      // Create organization document
      await setDoc(orgRef, orgData);

      return { user: { userId: user.uid, email: data.adminEmail }, organisation: orgData };
    } catch (error) {
      console.error('Error registering Organisation:', error);
      throw error;
    }
  }

  static async getOrganisation(orgId: string) {
    try {
      const orgDoc = await getDoc(doc(db, 'Organisations', orgId));
      if (!orgDoc.exists()) {
        throw new Error('Organisation not found');
      }
      return orgDoc.data() as IOrganisation;
    } catch (error) {
      console.error('Error getting Organisation:', error);
      throw error;
    }
  }

  static async getUserOrganisations(userId: string) {
    try {
      const userDoc = await getDoc(doc(db, 'Users', userId));
      if (!userDoc.exists()) {
        return {};
      }
      return userDoc.data().Organisations || {};
    } catch (error) {
      console.error('Error getting user Organisations:', error);
      throw error;
    }
  }

  static async verifyAccessCode(accessCode: string) {
    try {
      const accessCodesRef = collection(db, 'accessCodes');
      const q = query(
        accessCodesRef, 
        where('code', '==', accessCode),
        where('isActive', '==', true)
      );
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        return null;
      }

      const codeData = querySnapshot.docs[0].data();
      const now = new Date();
      const expirationDate = codeData.expiresAt.toDate();

      if (now > expirationDate) {
        return null;
      }

      return {
        organisationId: codeData.organisationId,
        role: codeData.role,
      };
    } catch (error) {
      console.error('Error verifying access code:', error);
      throw error;
    }
  }

  static async getOrganisationMembers(orgId: string): Promise<IMember[]> {
    try {
      // Get regular members
      const usersRef = collection(db, 'Users');
      const q = query(usersRef, where(`Organisations.${orgId}`, '!=', null));
      const querySnapshot = await getDocs(q);
      
      const members = querySnapshot.docs.map(doc => {
        const userData = doc.data();
        const orgData = userData.Organisations[orgId];
        return {
          ...orgData,
          userKey: doc.id,
          name: orgData.name || userData.userProfile?.userName || userData.email || 'Unnamed Member',
          isTemporary: false // Explicitly set to false for regular members
        };
      });

      // Get temporary users
      const tempQuery = query(
        collection(db, 'Users'),
        where('isTemporary', '==', true),
        where('organisationId', '==', orgId)
      );
      const tempSnapshot = await getDocs(tempQuery);
      
      const tempMembers = tempSnapshot.docs.map(doc => {
        const userData = doc.data();
        return {
          userKey: doc.id,
          organisationId: orgId,
          role: 'member',
          name: userData.userProfile?.userName || 'Unnamed Student',
          joinedAt: userData.createdAt,
          groupIds: userData.groupIds || [],
          animalEmoji: userData.userProfile?.userName?.split(' ')[0] || '🐼',
          isTemporary: true,
          tempUserId: doc.id,
          registered: false
        } as IMember;
      });

      // Create a Map to deduplicate members
      const memberMap = new Map();
      [...members, ...tempMembers].forEach(member => {
        const id = member.userKey || member.tempUserId;
        if (id) {
          memberMap.set(id, member);
        }
      });

      return Array.from(memberMap.values());
    } catch (error) {
      console.error('Error getting Organisation members:', error);
      throw error;
    }
  }

  static async inviteMemberByEmail(orgId: string, email: string, role: MemberRole) {
    try {
      // Get organization type first
      const orgDoc = await getDoc(doc(db, 'Organisations', orgId));
      if (!orgDoc.exists()) {
        throw new Error('Organization not found');
      }
      const orgData = orgDoc.data() as IOrganisation;

      // Generate access code
      const accessCode = uuidv4();
      
      // Create access code document
      const accessCodeRef = doc(db, 'accessCodes', accessCode);
      await setDoc(accessCodeRef, {
        code: accessCode,
        organisationId: orgId,
        role: role,
        email,
        isActive: true,
        createdAt: serverTimestamp(),
        expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7 days
      });

      // Generate the invite link
      const inviteLink = `${window.location.origin}/join-org/${accessCode}`;

      // Send invitation email
      await sendOrganizationInvite(
        email,
        orgData.name,
        role,
        inviteLink
      );

      return accessCode;
    } catch (error) {
      console.error('Error inviting member:', error);
      throw error;
    }
  }

  static async generateAccessCode(orgId: string, role: MemberRole) {
    try {
      // Get organization type first
      const orgDoc = await getDoc(doc(db, 'Organisations', orgId));
      if (!orgDoc.exists()) {
        throw new Error('Organization not found');
      }
      const orgData = orgDoc.data() as IOrganisation;

      // Map role if organization is a school
      let mappedRole = role;
      if (orgData.type === 'school') {
        mappedRole = role;
      }

      const accessCode = uuidv4().slice(0, 8);
      
      // Create access code document
      const accessCodeRef = doc(db, 'accessCodes', accessCode);
      await setDoc(accessCodeRef, {
        code: accessCode,
        organisationId: orgId,
        role: mappedRole,
        isActive: true,
        createdAt: serverTimestamp(),
        expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7 days
      });

      // Return the full invite link
      return `${window.location.origin}/join-org/${accessCode}`;
    } catch (error) {
      console.error('Error generating access code:', error);
      throw error;
    }
  }

  static async removeMember(orgId: string, memberId: string) {
    try {
      const userRef = doc(db, 'Users', memberId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        throw new Error('User not found');
      }

      const userData = userDoc.data();
      const batch = writeBatch(db);

      // Handle temporary user deletion
      if (userData.isTemporary) {
        // Get the user's groups
        const groupIds = userData.groupIds || [];
        
        // Remove user from all groups they belong to
        await Promise.all(groupIds.map(async (groupId: string) => {
          const groupRef = doc(db, 'groups', groupId);
          const groupDoc = await getDoc(groupRef);
          if (groupDoc.exists()) {
            const groupData = groupDoc.data();
            await updateDoc(groupRef, {
              memberIds: groupData.memberIds.filter((id: string) => id !== memberId),
              updatedAt: serverTimestamp()
            });
          }
        }));

        // Delete the generated code from the organization's generatedCodes collection
        const generatedCodesRef = collection(db, 'Organisations', orgId, 'generatedCodes');
        const generatedCodesQuery = query(generatedCodesRef, where('tempUserId', '==', memberId));
        const generatedCodesSnapshot = await getDocs(generatedCodesQuery);

        // Delete the generated code document if it exists
        generatedCodesSnapshot.docs.forEach(doc => {
          batch.delete(doc.ref);
        });

        // Delete any access codes associated with this temporary user
        const accessCodesRef = collection(db, 'accessCodes');
        const accessCodesQuery = query(accessCodesRef, where('tempUserId', '==', memberId));
        const accessCodesSnapshot = await getDocs(accessCodesQuery);
        
        accessCodesSnapshot.docs.forEach(doc => {
          batch.delete(doc.ref);
        });

        // Delete the temporary user
        batch.delete(userRef);
        
        // Commit all deletions in one batch
        await batch.commit();
        return;
      }

      // Handle regular user (admin, moderator, etc.)
      const updatedOrganisations = { ...userData.Organisations };
      const memberData = updatedOrganisations[orgId];
      
      if (memberData) {
        // Get the access code from the member data
        const accessCode = memberData.accessCode;

        // If there's an access code, delete it from the accessCodes collection
        if (accessCode) {
          const accessCodeRef = doc(db, 'accessCodes', accessCode);
          batch.delete(accessCodeRef);

          // Also delete from the organization's generatedCodes subcollection
          const generatedCodeRef = doc(db, 'Organisations', orgId, 'generatedCodes', accessCode);
          batch.delete(generatedCodeRef);
        }

        // Remove user from all groups they belong to
        const groupIds = memberData.groupIds || [];
        await Promise.all(groupIds.map(async (groupId: string) => {
          const groupRef = doc(db, 'groups', groupId);
          const groupDoc = await getDoc(groupRef);
          if (groupDoc.exists()) {
            const groupData = groupDoc.data();
            await updateDoc(groupRef, {
              memberIds: groupData.memberIds.filter((id: string) => id !== memberId),
              updatedAt: serverTimestamp()
            });
          }
        }));
      }

      // Delete any generated codes associated with this user
      const generatedCodesRef = collection(db, 'Organisations', orgId, 'generatedCodes');
      const generatedCodesQuery = query(generatedCodesRef, where('userKey', '==', memberId));
      const generatedCodesSnapshot = await getDocs(generatedCodesQuery);
      generatedCodesSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Only remove the organization from the user's Organisations map
      delete updatedOrganisations[orgId];
      
      // Delete the entire user document if:
      // 1. This was their last organization, OR
      // 2. They were created by a moderator/admin in this org, OR
      // 3. They are a regular member (not an admin or moderator in other orgs)
      const isAdmin = Object.values(updatedOrganisations).some((org: any) => org.role === 'organisationAdmin');
      const isModerator = Object.values(updatedOrganisations).some((org: any) => org.role === 'moderator');
      const shouldDeleteUser = 
        Object.keys(updatedOrganisations).length === 0 || 
        (userData.moderatorEmail && userData.email?.startsWith(userData.moderatorEmail.split('@')[0])) ||
        (!isAdmin && !isModerator); // Only delete if they're not an admin/moderator in other orgs

      if (shouldDeleteUser) {
        batch.delete(userRef);
        // Delete the user from Firebase Auth if they were created by a moderator
        if (userData.moderatorEmail && userData.email) {
          try {
            const auth = getAuth();
            // First sign in as the user to get their auth record
            const userCredential = await signInWithEmailAndPassword(auth, userData.email, userData.accessCode || '');
            if (userCredential.user) {
              await deleteUser(userCredential.user);
            }
          } catch (error) {
            console.error('Error deleting user from auth:', error);
            // Continue with other deletions even if auth deletion fails
          }
        }
      } else {
        batch.update(userRef, {
          Organisations: updatedOrganisations
        });
      }

      // Handle access codes
      if (userData.email) {
        // Find and delete any access codes for this user in this Organisation
        const accessCodesRef = collection(db, 'accessCodes');
        const accessCodesQuery = query(
          accessCodesRef,
          where('organisationId', '==', orgId),
          where('email', '==', userData.email)
        );
        const accessCodesSnapshot = await getDocs(accessCodesQuery);
        
        accessCodesSnapshot.docs.forEach(doc => {
          batch.delete(doc.ref);
        });
      }

      // Commit all changes
      await batch.commit();

    } catch (error) {
      console.error('Error removing member:', error);
      throw error;
    }
  }

  static async addMemberWithAccessCode(accessCode: string, userId: string) {
    try {
      // Get access code data
      const accessCodesRef = collection(db, 'accessCodes');
      const q = query(
        accessCodesRef, 
        where('code', '==', accessCode),
        where('isActive', '==', true)
      );
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('Invalid or expired access code');
      }

      const codeData = querySnapshot.docs[0].data();
      const now = new Date();
      const expirationDate = codeData.expiresAt.toDate();

      if (now > expirationDate) {
        throw new Error('Access code has expired');
      }

      // Get organization data to check type
      const orgDoc = await getDoc(doc(db, 'Organisations', codeData.organisationId));
      if (!orgDoc.exists()) {
        throw new Error('Organization not found');
      }
      const orgData = orgDoc.data();

      // Map role based on organization type
      let mappedRole = codeData.role;
      if (orgData.type === 'school') {
        if (mappedRole === 'moderator') mappedRole = 'moderator';
        if (mappedRole === 'member') mappedRole = 'member';
      }

      let memberData: IMember;

      // Handle anonymous student access
      if (codeData.isAnonymous && codeData.tempUserId) {
        console.log(`[OrganisationManager] Converting temporary user ${codeData.tempUserId} to permanent user ${userId}`);
        
        // Get the temporary user data
        const tempUserRef = doc(db, 'Users', codeData.tempUserId);
        const tempUserDoc = await getDoc(tempUserRef);

        if (tempUserDoc.exists()) {
          const tempUserData = tempUserDoc.data();
          
          // Create permanent user document
          const userRef = doc(db, 'Users', userId);
          
          // Generate email using moderator's email
          const moderatorEmail = tempUserData.moderatorEmail;
          if (!moderatorEmail) {
            throw new Error('Moderator email not found for temporary user');
          }
          
          const generatedEmail = `${moderatorEmail.split('@')[0]}+member_${accessCode}@${moderatorEmail.split('@')[1]}`;
          
          // Create member data for anonymous student
          memberData = {
            userKey: userId,
            organisationId: codeData.organisationId,
            role: 'member',
            name: tempUserData.userProfile.userName,
            joinedAt: serverTimestamp() as Timestamp,
            groupIds: tempUserData.groupIds || [],
            moderatorEmail: tempUserData.moderatorEmail,
            animalEmoji: tempUserData.userProfile.userName.split(' ')[0],
            email: generatedEmail,
            accessCode,
            isTemporary: false,
            registered: true
          };

          await setDoc(userRef, {
            userKey: userId,
            email: generatedEmail,
            userProfile: {
              userName: tempUserData.userProfile.userName,
              bio: '',
              profileImage: '',
              restrictAutoAdd: true,
              authorKey: userId
            },
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            moderatorEmail: tempUserData.moderatorEmail,
            Organisations: {
              [codeData.organisationId]: memberData
            }
          });

          // Delete the temporary user
          await deleteDoc(tempUserRef);
          console.log(`[OrganisationManager] Deleted temporary user ${codeData.tempUserId}`);

        } else {
          throw new Error('Temporary user data not found');
        }
      } else {
        // Handle regular (non-anonymous) user joining
        // Get user data
        const userRef = doc(db, 'Users', userId);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.exists() ? userDoc.data() : null;

        // Create member data for regular user
        memberData = {
          userKey: userId,
          organisationId: codeData.organisationId,
          role: mappedRole,
          email: userData?.email || codeData.email,
          name: userData?.displayName || codeData.email.split('@')[0],
          joinedAt: serverTimestamp() as Timestamp,
          groupIds: []
        };

        // Update user document
        if (userDoc.exists()) {
          // Check if user is already a member of this organization
          const existingOrgs = userData?.Organisations || {};
          if (existingOrgs[codeData.organisationId]) {
            throw new Error('User is already a member of this organization');
          }

          // Update existing user with new organization
          await updateDoc(userRef, {
            Organisations: {
              ...existingOrgs,
              [codeData.organisationId]: memberData
            }
          });
        } else {
          // Create new user document
          await setDoc(userRef, {
            userKey: userId,
            email: codeData.email,
            displayName: codeData.email.split('@')[0],
            Organisations: {
              [codeData.organisationId]: memberData
            }
          });
        }
      }

      // Deactivate the access code
      await updateDoc(querySnapshot.docs[0].ref, {
        isActive: false,
        usedBy: userId,
        usedAt: serverTimestamp()
      });

      return memberData;
    } catch (error) {
      console.error('Error adding member with access code:', error);
      throw error;
    }
  }

  static async createGroup(orgId: string, data: { name: string; memberIds: string[] }) {
    try {
      const groupRef = doc(db, 'groups', uuidv4());
      const groupData: IGroup = {
        id: groupRef.id,
        name: data.name,
        organisationId: orgId,
        memberIds: data.memberIds,
        createdAt: serverTimestamp() as Timestamp,
        updatedAt: serverTimestamp() as Timestamp,
      };

      // Create the group document
      await setDoc(groupRef, groupData);

      // Update member documents with the new group
      const updatePromises = data.memberIds.map(memberId =>
        updateDoc(doc(db, 'Users', memberId), {
          [`Organisations.${orgId}.groupIds`]: arrayUnion(groupRef.id)
        })
      );

      await Promise.all(updatePromises);

      return groupData;
    } catch (error) {
      console.error('Error creating group:', error);
      throw error;
    }
  }

  static async getOrganisationGroups(orgId: string): Promise<IGroup[]> {
    try {
      const groupsRef = collection(db, 'groups');
      const q = query(groupsRef, where('organisationId', '==', orgId));
      const querySnapshot = await getDocs(q);
      
      return querySnapshot.docs.map(doc => doc.data() as IGroup);
    } catch (error) {
      console.error('Error getting organisation groups:', error);
      throw error;
    }
  }

  static async generateAnonymousMemberCode(
    orgId: string, 
    identifier: string, 
    animalEmoji: string,
    groupId: string,
    moderatorEmail: string
  ) {
    try {
      console.log(`[OrganisationManager] Starting anonymous member creation for org: ${orgId}, group: ${groupId}`);
      
      // Generate a unique access code
      const accessCode = uuidv4().slice(0, 8);
      
      // Create a temporary user in the Users collection
      const tempUserId = `temp_${accessCode}`;
      const userRef = doc(db, 'Users', tempUserId);
      
      const userName = `${animalEmoji} ${identifier}`;
      console.log(`[OrganisationManager] Creating temporary user: ${userName} (${tempUserId})`);
      
      await setDoc(userRef, {
        userKey: tempUserId,
        userProfile: {
          userName,
          bio: '',
          profileImage: '',
          restrictAutoAdd: true,
        },
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        isTemporary: true,
        accessCode,
        organisationId: orgId,
        groupIds: [groupId],
        moderatorEmail
      });
      console.log(`[OrganisationManager] Temporary user created successfully with groupIds: ${[groupId]}`);

      // Create access code document
      const accessCodeRef = doc(db, 'accessCodes', accessCode);
      await setDoc(accessCodeRef, {
        code: accessCode,
        organisationId: orgId,
        role: 'member',
        isAnonymous: true,
        identifier: userName,
        animalEmoji,
        tempUserId,
        groupId,
        isActive: true,
        createdAt: serverTimestamp(),
        expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 days
        moderatorEmail
      });
      console.log(`[OrganisationManager] Access code created: ${accessCode}`);

      // Save to generated codes subcollection
      const generatedCodeRef = doc(collection(db, 'Organisations', orgId, 'generatedCodes'), accessCode);
      await setDoc(generatedCodeRef, {
        code: accessCode,
        link: `${window.location.origin}/join-org/${accessCode}`,
        animalEmoji,
        identifier: userName,
        tempUserId,
        groupId,
        createdAt: serverTimestamp(),
        moderatorEmail
      });
      console.log(`[OrganisationManager] Generated code saved to organisation subcollection`);

      // Return the full invite link and data
      return {
        code: accessCode,
        link: `${window.location.origin}/join-org/${accessCode}`,
        animalEmoji,
        identifier: userName,
        tempUserId,
        groupId,
        moderatorEmail
      };
    } catch (error) {
      console.error('[OrganisationManager] Error generating anonymous student code:', error);
      throw error;
    }
  }


  static async saveGeneratedCodes(orgId: string, codes: MemberCode[]): Promise<void> {
    try {
      const codesCollection = collection(db, 'Organisations', orgId, 'generatedCodes');
      const batch = writeBatch(db);

      // Delete existing codes
      const existingCodes = await getDocs(codesCollection);
      existingCodes.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Add new codes
      codes.forEach(code => {
        const docRef = doc(codesCollection);
        const { qrCode, ...codeData } = code; // Remove qrCode as it's regenerated on load
        batch.set(docRef, codeData);
      });

      await batch.commit();
    } catch (error: any) {
      console.error('Error saving generated codes:', error);
      console.error('Error details:', {
        orgId,
        errorCode: error.code,
        errorMessage: error.message
      });
      throw error;
    }
  }

  static async getGeneratedCodes(orgId: string) {
    try {
      const codesCollection = collection(db, 'Organisations', orgId, 'generatedCodes');
      
      // Get the user's role in the organization
      const userDoc = await getDoc(doc(db, 'Users', userStore.currentUser?.id || ''));
      const userData = userDoc.data();
      
      const snapshot = await getDocs(codesCollection);
      
      return snapshot.docs.map(doc => doc.data());
    } catch (error: any) {
      console.error('Error getting generated codes:', error);
      console.error('Error details:', {
        orgId,
        errorCode: error.code,
        errorMessage: error.message,
        userId: userStore.currentUser?.id
      });
      throw error;
    }
  }

  static async updateGroupMembers(orgId: string, groupId: string, memberIds: string[]) {
    try {
      const groupRef = doc(db, 'groups', groupId);
      const groupDoc = await getDoc(groupRef);
      
      if (!groupDoc.exists()) {
        throw new Error('Group not found');
      }

      const oldMemberIds: string[] = groupDoc.data().memberIds || [];
      const removedMemberIds = oldMemberIds.filter((id: string) => !memberIds.includes(id));
      const addedMemberIds = memberIds.filter((id: string) => !oldMemberIds.includes(id));

      // Update the group document
      await updateDoc(groupRef, {
        memberIds,
        updatedAt: serverTimestamp()
      });

      // Update removed members
      const removePromises = removedMemberIds.map((memberId: string) =>
        updateDoc(doc(db, 'Users', memberId), {
          [`Organisations.${orgId}.groupIds`]: arrayRemove(groupId)
        })
      );

      // Update added members
      const addPromises = addedMemberIds.map((memberId: string) =>
        updateDoc(doc(db, 'Users', memberId), {
          [`Organisations.${orgId}.groupIds`]: arrayUnion(groupId)
        })
      );

      await Promise.all([...removePromises, ...addPromises]);

      // Sync project access for the group membership changes
      await ProjectManager.syncGroupMembershipChanges(groupId, addedMemberIds, removedMemberIds);

      return true;
    } catch (error) {
      console.error('Error updating group members:', error);
      throw error;
    }
  }

  static async cleanupTestData(orgId: string): Promise<void> {
    try {
      // 1. Get all members including temporary users
      const [regularUsersSnapshot, tempUsersSnapshot] = await Promise.all([
        // Regular users
        getDocs(query(
          collection(db, 'Users'),
          where(`Organisations.${orgId}`, '!=', null)
        )),
        // Temporary users
        getDocs(query(
          collection(db, 'Users'),
          where('isTemporary', '==', true),
          where('organisationId', '==', orgId)
        ))
      ]);

      const batch = writeBatch(db);
      let operationCount = 0;
      let currentBatch = batch;

      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) { // Leave some room for safety
          await currentBatch.commit();
          currentBatch = writeBatch(db);
          operationCount = 0;
        }
      };

      // Handle regular users - delete members/students, preserve admin/moderator/teacher roles
      for (const doc of regularUsersSnapshot.docs) {
        const userData = doc.data();
        const userRole = userData.Organisations[orgId]?.role;
        
        // Skip admins, superadmins, moderators, and teachers
        if (userRole === 'organisationAdmin' || 
            userRole === 'superAdmin' || 
            userRole === 'moderator' || 
            userRole === 'teacher') {
          continue;
        }

        // For regular members/students, delete their entire document
        currentBatch.delete(doc.ref);
        operationCount++;
        await commitBatchIfNeeded();
      }

      // Delete temporary users completely
      for (const doc of tempUsersSnapshot.docs) {
        currentBatch.delete(doc.ref);
        operationCount++;
        await commitBatchIfNeeded();
      }

      // Clear all groups' memberIds
      const groupsSnapshot = await getDocs(query(
        collection(db, 'groups'),
        where('organisationId', '==', orgId)
      ));
      
      for (const doc of groupsSnapshot.docs) {
        currentBatch.update(doc.ref, { 
          memberIds: [],
          updatedAt: serverTimestamp()
        });
        operationCount++;
        await commitBatchIfNeeded();
      }

      // Delete all generated codes
      const codesSnapshot = await getDocs(
        collection(db, 'Organisations', orgId, 'generatedCodes')
      );
      
      for (const doc of codesSnapshot.docs) {
        currentBatch.delete(doc.ref);
        operationCount++;
        await commitBatchIfNeeded();
      }

      // Delete all access codes for this organisation
      const accessCodesSnapshot = await getDocs(query(
        collection(db, 'accessCodes'),
        where('organisationId', '==', orgId)
      ));
      
      for (const doc of accessCodesSnapshot.docs) {
        currentBatch.delete(doc.ref);
        operationCount++;
        await commitBatchIfNeeded();
      }

      // Commit final batch if there are any pending operations
      if (operationCount > 0) {
        await currentBatch.commit();
      }

    } catch (error) {
      console.error('Error cleaning up test data:', error);
      throw error;
    }
  }

  static async deleteAllGeneratedCodes(organisationId: string): Promise<void> {
    const codesRef = collection(db, 'Organisations', organisationId, 'generatedCodes');
    const codesSnapshot = await getDocs(codesRef);
    
    // Delete all codes in batches of 500 (Firestore limit)
    const batch = writeBatch(db);
    let operationCount = 0;
    
    for (const doc of codesSnapshot.docs) {
      batch.delete(doc.ref);
      operationCount++;
      
      if (operationCount >= 500) {
        await batch.commit();
        operationCount = 0;
      }
    }
    
    if (operationCount > 0) {
      await batch.commit();
    }
  }

  static async deleteGroup(organisationId: string, groupId: string): Promise<void> {
    const groupRef = doc(db, 'groups', groupId);
    await deleteDoc(groupRef);
  }
} 