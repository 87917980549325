import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Chip,
  Checkbox,
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Delete as DeleteIcon,
  Group as GroupIcon,
} from '@mui/icons-material';
import { IMember, IGroup } from '../../types/organisation';
import { OrganisationManager } from '../../models/OrganisationManager';
import userStore from '../../stores/UserStore';

const ANIMAL_EMOJIS = [
  '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐨', '🐯',
  '🦁', '🐮', '🐷', '🐸', '🐵', '🐔', '🦆', '🦅', '🦉', '🦋',
  '🐢', '🐙', '🦈', '🦒', '🦘', '🦡', '🦃', '🐝', '🐞', '🦕',
  '🐬', '🦭', '🐘', '🦬', '🦩', '🦜', '🐧', '🐦',
  '🦢', '🦫', '🦦', '🦥', '🦨', '🦘', '🦙', '🦛', '🐪', '🐫'
];

interface MemberManagementProps {
  organisationId: string;
  organisationType?: 'school' | 'community' | 'organisation';
  adminId?: string;
  members: IMember[];
  groups: IGroup[];
  onMembersChange: () => void;
  showAdminGroups?: boolean;
}

export const MemberManagement: React.FC<MemberManagementProps> = ({
  organisationId,
  organisationType = 'organisation',
  adminId,
  members,
  groups,
  onMembersChange,
  showAdminGroups = true,
}) => {

  const [collapsedGroups, setCollapsedGroups] = useState<Set<string>>(new Set());
  const [error, setError] = useState<string | null>(null);
  const [removingMember, setRemovingMember] = useState<string | null>(null);
  const [isGenerateCodesOpen, setIsGenerateCodesOpen] = useState(false);
  const [isAddModeratorOpen, setIsAddModeratorOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [studentCount, setStudentCount] = useState<number>(1);
  const [generatingCodes, setGeneratingCodes] = useState(false);
  const [newModeratorEmail, setNewModeratorEmail] = useState('');
  const [addingModerator, setAddingModerator] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());
  const [isAddGroupOpen, setIsAddGroupOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [addingGroup, setAddingGroup] = useState(false);

  const getMemberTerm = (isPlural: boolean = false) => {
    return organisationType === 'school' 
      ? `Student${isPlural ? 's' : ''}`
      : `Member${isPlural ? 's' : ''}`;
  };

  const toggleGroupCollapse = (groupId: string) => {
    setCollapsedGroups(prev => {
      const newSet = new Set(prev);
      if (newSet.has(groupId)) {
        newSet.delete(groupId);
      } else {
        newSet.add(groupId);
      }
      return newSet;
    });
  };

  const handleRemoveMember = async (memberId: string) => {
    setRemovingMember(memberId);
    try {
      // Find all groups this member belongs to
      const memberGroups = groups.filter(group => group.memberIds.includes(memberId));
      
      // Remove member from each group they belong to
      await Promise.all(memberGroups.map(group => 
        OrganisationManager.updateGroupMembers(
          organisationId,
          group.id,
          group.memberIds.filter(id => id !== memberId)
        ).then(() => {})
      ));

      // Remove the member from the organization
      await OrganisationManager.removeMember(organisationId, memberId);
      onMembersChange();
    } catch (err) {
      console.error('Error removing member:', err);
      setError((err as Error).message);
    } finally {
      setRemovingMember(null);
    }
  };

  const handleAddModerator = async () => {
    if (!newModeratorEmail) return;
    setAddingModerator(true);
    try {
      await OrganisationManager.inviteMemberByEmail(
        organisationId,
        newModeratorEmail,
        'moderator'
      );
      setIsAddModeratorOpen(false);
      setNewModeratorEmail('');
      onMembersChange();
    } catch (err) {
      console.error('Error adding moderator:', err);
      setError((err as Error).message);
    } finally {
      setAddingModerator(false);
    }
  };

  const handleGenerateStudentCodes = async () => {
    if (!selectedGroup) return;
    setGeneratingCodes(true);
    try {
      const group = groups.find(g => g.id === selectedGroup);
      if (!group) throw new Error('Selected group not found');

      // Get the highest existing member number for this group
      const existingNumbers = members
        .filter(member => {
          // Check both groupIds and group memberIds
          const memberId = member.userKey || member.tempUserId;
          return member.groupIds?.includes(selectedGroup) || 
                 (memberId && group.memberIds.includes(memberId));
        })
        .map(member => {
          // Extract number from the name, considering group name prefix
          const match = member.name.match(new RegExp(`${group.name} (?:Student|Member) (\\d+)`));
          return match ? parseInt(match[1]) : 0;
        })
        .filter(num => !isNaN(num));
      
      const startingNumber = existingNumbers.length > 0 ? Math.max(...existingNumbers) + 1 : 1;

      // Create a Set of used emojis for this group
      const usedEmojis = new Set(
        members
          .filter(member => {
            const memberId = member.userKey || member.tempUserId;
            return member.groupIds?.includes(selectedGroup) || 
                   (memberId && group.memberIds.includes(memberId));
          })
          .map(member => member.animalEmoji)
      );

      // Filter out already used emojis and shuffle the remaining ones
      const availableEmojis = ANIMAL_EMOJIS.filter(emoji => !usedEmojis.has(emoji))
        .sort(() => Math.random() - 0.5)
        .slice(0, studentCount);

      const responses = await Promise.all(
        availableEmojis.map(async (emoji, index) => {
          const studentNumber = startingNumber + index;
          const identifier = `${group.name} ${getMemberTerm()} ${studentNumber}`;
          return await OrganisationManager.generateAnonymousMemberCode(
            organisationId,
            identifier,
            emoji,
            selectedGroup,
            userStore.currentUser?.email || ''
          );
        })
      );

      // Update the group with all new members at once
      const newMemberIds = responses.map(response => response.tempUserId);
      await OrganisationManager.updateGroupMembers(
        organisationId,
        selectedGroup,
        [...group.memberIds, ...newMemberIds]
      );

      onMembersChange();
      setIsGenerateCodesOpen(false);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setGeneratingCodes(false);
    }
  };

  const handleSelectMember = (memberId: string) => {
    setSelectedMembers(prev => {
      const newSet = new Set(prev);
      if (newSet.has(memberId)) {
        newSet.delete(memberId);
      } else {
        newSet.add(memberId);
      }
      return newSet;
    });
  };

  const handleSelectAllInGroup = (groupId: string) => {
    
    // Get all members of the group
    const groupMembers = members.filter(member => {
      const memberId = member.userKey || member.tempUserId;
      if (!memberId) return false;

      if (groupId === 'admin') return member.userKey === adminId;
      if (groupId === 'moderators') return member.role === 'moderator';
      if (groupId === 'ungrouped') return !member.groupIds?.length;

      // Get the group and check its memberIds
      const group = groups.find(g => g.id === groupId);
      return group?.memberIds.includes(memberId);
    });

    // Get IDs of all members in the group (excluding admin)
    const groupMemberIds = groupMembers
      .map(m => m.userKey || m.tempUserId)
      .filter((id): id is string => id !== undefined && id !== adminId);

    // Check if all members in the group are currently selected
    const allGroupSelected = groupMemberIds.every(id => selectedMembers.has(id));

    // Toggle selection for all members in the group
    setSelectedMembers(prev => {
      const newSet = new Set(prev);
      groupMemberIds.forEach(id => {
        if (allGroupSelected) {
          newSet.delete(id);
        } else {
          newSet.add(id);
        }
      });
      return newSet;
    });
  };

  const handleSelectAll = () => {
    const allMemberIds = members
      .filter(m => m.userKey !== adminId) // Don't allow selecting admin
      .map(m => m.userKey || m.tempUserId || '');
    
    const allSelected = allMemberIds.every(id => selectedMembers.has(id));
    
    setSelectedMembers(new Set(allSelected ? [] : allMemberIds));
  };

  const handleBatchDelete = async () => {
    if (!selectedMembers.size) return;
    
    try {
      // For each group, remove all selected members
      const groupUpdates = groups.map(group => {
        const updatedMemberIds = group.memberIds.filter(id => !selectedMembers.has(id));
        if (updatedMemberIds.length !== group.memberIds.length) {
          // Cast the Promise<boolean> to Promise<void> by ignoring the return value
          return OrganisationManager.updateGroupMembers(
            organisationId,
            group.id,
            updatedMemberIds
          ).then(() => {});
        }
        return Promise.resolve();
      });

      // Remove members from groups first
      await Promise.all(groupUpdates);

      // Then remove the members from the organization
      await Promise.all(
        Array.from(selectedMembers).map(memberId =>
          OrganisationManager.removeMember(organisationId, memberId)
        )
      );

      setSelectedMembers(new Set());
      onMembersChange();
    } catch (err) {
      console.error('Error batch deleting members:', err);
      setError((err as Error).message);
    }
  };

  const renderSelectionActions = () => {
    if (!selectedMembers.size) return null;

    return (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        p: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        mb: 2
      }}>
        <Typography>
          {selectedMembers.size} {selectedMembers.size === 1 ? 'member' : 'members'} selected
        </Typography>
        <Button
          variant="contained"
          color="error"
          onClick={handleBatchDelete}
          startIcon={<DeleteIcon />}
        >
          Delete Selected
        </Button>
      </Box>
    );
  };

  const handleAddGroup = async () => {
    if (!newGroupName) return;
    setAddingGroup(true);
    try {
      await OrganisationManager.createGroup(organisationId, {
        name: newGroupName,
        memberIds: []
      });
      setIsAddGroupOpen(false);
      setNewGroupName('');
      onMembersChange();
    } catch (err) {
      console.error('Error adding group:', err);
      setError((err as Error).message);
    } finally {
      setAddingGroup(false);
    }
  };

  const renderMemberList = () => {
    // Group members by their groups and roles
    const membersByGroup = new Map<string, IMember[]>();
        
    // Only add admin and moderator groups if showAdminGroups is true
    if (showAdminGroups) {
      membersByGroup.set('admin', []);
      membersByGroup.set('moderators', []);
    }
    
    // Initialize regular groups
    groups.forEach(group => {
      membersByGroup.set(group.id, []);
    });
    membersByGroup.set('ungrouped', []); // Add ungrouped last
    
    
    members.forEach(member => {
      // Handle admin and moderators only if showAdminGroups is true
      if (showAdminGroups) {
        if (member.userKey === adminId) {
          membersByGroup.get('admin')?.push(member);
          return;
        }
        if (member.role === 'moderator') {
          membersByGroup.get('moderators')?.push(member);
          return;
        }
      }

      // Skip admin and moderators if not showing admin groups
      if (!showAdminGroups && (member.userKey === adminId || member.role === 'moderator')) {
        return;
      }

      // For regular members, find their group
      const memberId = member.userKey || member.tempUserId;
      const group = groups.find(g => g.memberIds.includes(memberId || ''));
      const groupId = group?.id || 'ungrouped';
      membersByGroup.get(groupId)?.push(member);
    });

    // Sort members within each group
    membersByGroup.forEach((groupMembers, groupId) => {
      groupMembers.sort((a, b) => {
        const aName = a.name || '';
        const bName = b.name || '';
        
        // Extract numbers from names for numerical sorting
        const aMatch = aName.match(/(\d+)$/);
        const bMatch = bName.match(/(\d+)$/);
        
        if (aMatch && bMatch) {
          // If both names end in numbers, sort numerically
          return parseInt(aMatch[1]) - parseInt(bMatch[1]);
        }
        // Otherwise sort alphabetically
        return aName.localeCompare(bName);
      });
    });

    // Filter out empty groups and sort them
    const sortedGroups = Array.from(membersByGroup.entries())
      .filter(([groupId, _]) => {
        // Keep admin and moderator groups only if they have members and showAdminGroups is true
        if (groupId === 'admin' || groupId === 'moderators') {
          const members = membersByGroup.get(groupId);
          return showAdminGroups && members ? members.length > 0 : false;
        }
        // Keep ungrouped only if it has members
        if (groupId === 'ungrouped') {
          const members = membersByGroup.get(groupId);
          return members ? members.length > 0 : false;
        }
        // Keep all other groups regardless of member count
        return true;
      })
      .sort((a, b) => {
        // Admin always first
        if (a[0] === 'admin') return -1;
        if (b[0] === 'admin') return 1;
        // Moderators second
        if (a[0] === 'moderators') return -1;
        if (b[0] === 'moderators') return 1;
        // Ungrouped last
        if (a[0] === 'ungrouped') return 1;
        if (b[0] === 'ungrouped') return -1;
        // Sort other groups alphabetically by name
        const groupA = groups.find(g => g.id === a[0]);
        const groupB = groups.find(g => g.id === b[0]);
        return (groupA?.name ?? '').localeCompare(groupB?.name ?? '');
      });

    return (
      <>
        {sortedGroups.map(([groupId, groupMembers]) => {
          const group = groups.find(g => g.id === groupId);
          const isCollapsed = collapsedGroups.has(groupId);
          const groupMemberIds = groupMembers.map(m => m.userKey || m.tempUserId || '');
          const allInGroupSelected = groupMemberIds.every(id => selectedMembers.has(id));
          const someInGroupSelected = groupMemberIds.some(id => selectedMembers.has(id));

          // Special group names for admin and moderators
          let groupName = group?.name || 'Ungrouped';
          if (groupId === 'admin') groupName = 'Administrator';
          if (groupId === 'moderators') {
            groupName = organisationType === 'school' ? 'Teachers' : 'Moderators';
          }

          return (
            <React.Fragment key={groupId}>
              <ListItem
                sx={{
                  backgroundColor: groupId === 'admin' ? 'primary.main' : 
                               groupId === 'moderators' ? 'primary.light' : 
                               'action.hover',
                  color: groupId === 'admin' ? 'primary.contrastText' : 'inherit',
                  borderRadius: 1,
                  mb: 1,
                  '& .MuiListItemText-root': {
                    flex: '1 1 auto',
                    mr: 2
                  }
                }}
              >
                {groupMembers.length > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                    <Checkbox
                      checked={allInGroupSelected}
                      indeterminate={!allInGroupSelected && someInGroupSelected}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectAllInGroup(groupId);
                      }}
                      disabled={groupId === 'admin'}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Box>
                )}
                <Box
                  onClick={() => groupMembers.length > 0 && toggleGroupCollapse(groupId)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: groupMembers.length > 0 ? 'pointer' : 'default',
                    flex: 1,
                    minWidth: 0
                  }}
                >
                  {groupMembers.length > 0 && (
                    <ListItemIcon 
                      sx={{ 
                        minWidth: 40,
                        color: groupId === 'admin' ? 'primary.contrastText' : 'inherit'
                      }}
                    >
                      {isCollapsed ? <AddIcon /> : <RemoveIcon />}
                    </ListItemIcon>
                  )}
                  <ListItemText 
                    primary={
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          fontWeight: 500,
                          color: groupId === 'admin' ? 'primary.contrastText' : 'inherit'
                        }}
                      >
                        {groupName} ({groupMembers.length} {groupMembers.length === 1 ? 'member' : 'members'})
                      </Typography>
                    }
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                  {groupId === 'moderators' ? (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsAddModeratorOpen(true);
                      }}
                      sx={{ 
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        '&:hover': {
                          bgcolor: 'action.hover'
                        }
                      }}
                    >
                      Add {organisationType === 'school' ? 'Teacher' : 'Moderator'}
                    </Button>
                  ) : groupId !== 'admin' && group ? (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedGroup(groupId);
                        setIsGenerateCodesOpen(true);
                      }}
                      sx={{ 
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        '&:hover': {
                          bgcolor: 'action.hover'
                        }
                      }}
                    >
                      Add {getMemberTerm()}
                    </Button>
                  ) : null}
                </Box>
              </ListItem>
              <Collapse in={!isCollapsed}>
                {groupMembers.map((member) => {
                  const memberId = member.userKey || member.tempUserId || '';
                  return (
                    <ListItem 
                      key={memberId}
                      sx={{
                        pl: 6,
                        py: 1,
                        pr: 1,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        '&:last-child': {
                          borderBottom: 'none'
                        }
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1 }}>
                        <Checkbox
                          checked={selectedMembers.has(memberId)}
                          onChange={() => handleSelectMember(memberId)}
                          disabled={member.userKey === adminId}
                        />
                        <Box sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          flex: 1,
                          minWidth: 0,
                          mr: 1
                        }}>
                          <Typography variant="body1" sx={{ 
                            fontWeight: 500,
                            flex: '0 1 auto',
                            minWidth: 0,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}>
                            {member.name}
                          </Typography>
                          <Box sx={{ 
                            display: 'flex', 
                            gap: 0.5,
                            flex: '0 0 auto'
                          }}>
                            <Chip
                              size="small"
                              label={member.role}
                              color={member.role === 'moderator' ? 'primary' : 'default'}
                            />
                            <Chip
                              size="small"
                              label={member.registered ? "Registered" : "Unregistered"}
                              color={member.registered ? "secondary" : "default"}
                            />
                          </Box>
                          {member.email && (
                            <Typography 
                              variant="body2" 
                              color="text.secondary"
                              sx={{ 
                                ml: 'auto',
                                flex: '0 1 auto',
                                minWidth: 0,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {member.email}
                            </Typography>
                          )}
                        </Box>
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveMember(memberId)}
                          disabled={removingMember === member.userKey || member.userKey === adminId}
                          sx={{ 
                            flex: '0 0 auto',
                            ml: 'auto',
                            width: 28,
                            height: 28
                          }}
                        >
                          {removingMember === member.userKey ? (
                            <CircularProgress size={16} />
                          ) : (
                            <DeleteIcon sx={{ fontSize: 16 }} />
                          )}
                        </IconButton>
                      </Box>
                    </ListItem>
                  );
                })}
              </Collapse>
            </React.Fragment>
          );
        })}
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setIsAddGroupOpen(true)}
          sx={{ mt: 2 }}
          fullWidth
        >
          Add New Group
        </Button>
      </>
    );
  };

  const renderGenerateCodesDialog = () => (
    <Dialog 
      open={isGenerateCodesOpen} 
      onClose={() => !generatingCodes && setIsGenerateCodesOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Generate {getMemberTerm()} Access Codes</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Generate access codes for {getMemberTerm(true).toLowerCase()} to join.
        </Typography>
        
        <TextField
          label={`Number of ${getMemberTerm(true)}`}
          type="number"
          value={studentCount}
          onChange={(e) => setStudentCount(Math.max(1, Math.min(50, parseInt(e.target.value) || 1)))}
          fullWidth
          helperText={`Maximum 50 ${getMemberTerm(true).toLowerCase()} at once`}
          disabled={generatingCodes}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsGenerateCodesOpen(false)} disabled={generatingCodes}>
          Cancel
        </Button>
        <Button 
          onClick={handleGenerateStudentCodes}
          variant="contained"
          disabled={generatingCodes}
          sx={{ minWidth: 120 }}
        >
          {generatingCodes ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} color="inherit" />
              <span>Generating...</span>
            </Box>
          ) : (
            'Generate Codes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderAddModeratorDialog = () => (
    <Dialog 
      open={isAddModeratorOpen} 
      onClose={() => !addingModerator && setIsAddModeratorOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Add {organisationType === 'school' ? 'Teacher' : 'Moderator'}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Enter the email address to invite a {organisationType === 'school' ? 'teacher' : 'moderator'}.
        </Typography>
        
        <TextField
          autoFocus
          label="Email Address"
          type="email"
          value={newModeratorEmail}
          onChange={(e) => setNewModeratorEmail(e.target.value)}
          fullWidth
          margin="dense"
          disabled={addingModerator}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsAddModeratorOpen(false)} disabled={addingModerator}>
          Cancel
        </Button>
        <Button 
          onClick={handleAddModerator}
          variant="contained"
          disabled={addingModerator || !newModeratorEmail}
          sx={{ minWidth: 120 }}
        >
          {addingModerator ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} color="inherit" />
              <span>Adding...</span>
            </Box>
          ) : (
            'Add'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderAddGroupDialog = () => (
    <Dialog 
      open={isAddGroupOpen} 
      onClose={() => !addingGroup && setIsAddGroupOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add New Group</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Group Name"
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
          fullWidth
          margin="dense"
          disabled={addingGroup}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsAddGroupOpen(false)} disabled={addingGroup}>
          Cancel
        </Button>
        <Button 
          onClick={handleAddGroup}
          variant="contained"
          disabled={addingGroup || !newGroupName}
        >
          {addingGroup ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} color="inherit" />
              <span>Adding...</span>
            </Box>
          ) : (
            'Add Group'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="outlined"
          onClick={handleSelectAll}
          startIcon={<Checkbox 
            checked={members.length > 0 && selectedMembers.size === members.filter(m => m.userKey !== adminId).length}
            indeterminate={selectedMembers.size > 0 && selectedMembers.size < members.filter(m => m.userKey !== adminId).length}
          />}
        >
          Select All
        </Button>
      </Box>
      {renderSelectionActions()}
      {renderMemberList()}
      {renderGenerateCodesDialog()}
      {renderAddModeratorDialog()}
      {renderAddGroupDialog()}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default MemberManagement; 