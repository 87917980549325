import React, { useState, useEffect, FunctionComponent } from 'react';
import { Select, MenuItem, Typography, SelectChangeEvent } from '@mui/material';
import { AdminNav, TagInputField } from '../components';
import { useAdminNav } from '../hooks/useAdminNav';
import { TagManager } from '../models/TagManager';
import "./TagsView.css"
import { PinManager, ProjectManager } from '../models';
import pinStore from '../stores/PinStore'; 


const TagsView: FunctionComponent = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [contentTag, setContentTag] = useState<string>('');
  const [contentTags, setContentTags] = useState<string[]>([]);
  const [subjectTags, setSubjectTags] = useState<string[]>([]);
  const handleContentTagUpdate = async (event: SelectChangeEvent<string>) => {
    const newContentTag = event.target.value;
    setContentTag(newContentTag); // Update the contentTag state
  
    if (selectedProject) {
      try {
        await ProjectManager.updateContentTagForProject(selectedProject, newContentTag);
      } catch (error) {
        console.error('Error updating content tag for project:', error);
      }
    } 
  };

  const handleTagsUpdate = async (newTags: string[]) => {

    // Fetch all pinKeys and recordingKeys for the project
    const pinKeys = pinStore.getPinKeysForProject(selectedProject);
  
  
    // Determine which tags have been added or removed
    const tagsToAdd = newTags.filter(tag => !subjectTags.includes(tag));
    const tagsToRemove = subjectTags.filter(tag => !newTags.includes(tag));
    
  
    // Update the tags in the Tags collection
    for (const tag of tagsToAdd) {
      await TagManager.updateProjectTags(selectedProject, tag, pinKeys);
      await ProjectManager.addSubjectTagToProject(selectedProject, tag); // Add tag to project
    }
  for (const tag of tagsToRemove) {
    // Assuming pinKeys is an array of all pin keys for the project
    for (const pinKey of pinKeys) {
      await TagManager.removeProjectTags(selectedProject, tag, pinKey);
    }
    // If you also need to remove the tag from the project after all pinKeys are processed
    await ProjectManager.removeSubjectTagFromProject(selectedProject, tag);
  }
  
    // Update the subjectTags state to reflect the changes
    setSubjectTags(newTags);
  };
  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole
  } = useAdminNav();

  useEffect(() => {
    const fetchContentTags = async () => {
      const fetchedContentTags = await TagManager.observeAdminContentTags();
      setContentTags(fetchedContentTags); // Assuming this returns an array of strings
    };
  
    fetchContentTags();
  }, []);

  useEffect(() => {
    const fetchProjectContentTag = async () => {
      if (selectedProject) {
        try {
          const projectData = await ProjectManager.getProject(selectedProject);
          if (projectData && projectData.tags && projectData.tags.contentTag) {
            // Set the contentTag state to the fetched tag, which should be a string
            setContentTag(projectData.tags.contentTag);
          }
        } catch (error) {
          console.error('Error fetching project content tag:', error);
        }
      }
    };
  
    fetchProjectContentTag();
  }, [selectedProject]);

  useEffect(() => {
    const fetchSubjectTags = async () => {
      if (selectedProject) {
        // Ensure selectedProject is a non-empty string
        const fetchedSubjectTags = await ProjectManager.getSubjectTagsForProject(selectedProject);
        setSubjectTags(fetchedSubjectTags);
      }
    };
  
    fetchSubjectTags();
  }, [selectedProject]);

  useEffect(() => {
    const fetchPinsForProject = async () => {
      if (selectedProject) {
        try {
          const pins = await PinManager.getPinsByProjectKey(selectedProject);
          pinStore.setPins(pins);
        } catch (error) {
          console.error('Error fetching pins for project:', error);
        }
      }
    };
  
    fetchPinsForProject();
  }, [selectedProject]);


  useEffect(() => {
    setTags(subjectTags); // Set the default tags to the fetched subject tags
  }, [subjectTags]);



  return (
    <div className="tags-view">
      <AdminNav
          imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
        projectName={projectName}
        userRole={userRole}
        projects={projects}
        selectedProject={selectedProject}
        onProjectChange={onProjectChange}
      />
        <a className="title">Project Tags</a>
     
      <div className='project-tags'>
        <div className='s'>
        <Typography variant="subtitle1" component="h2">
          Enter Tags here that are universal subjects to all Recordings in the Project
        </Typography>
        <TagInputField
          tags={tags}
          setTags={setTags}
          className="tag-input-field"
          chipContainerClassName="chip-container"
          onTagsChange={handleTagsUpdate}
        />
        </div>
        <div className='project-content-tags'>
        <Typography variant="subtitle1" component="h2">
          Set an over all content tag to the project here:
        </Typography>
        <Select
          className="tag-input-field"
          value={contentTag} // This should be a string, not an array
          onChange={handleContentTagUpdate}
          style={{ width: '200px' }}
        >
          {/* Assuming contentTags is an array of available tags for selection */}
          {contentTags.map((tag) => (
            <MenuItem key={tag} value={tag}>
              {tag}
            </MenuItem>
          ))}
        </Select>
        </div>

      </div>
    </div>
    // </div>
  );
};

export default TagsView;
