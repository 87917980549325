import { IPin, PinOrder } from '../types';
import { CollectionLogic } from '../types/Project';

export const sortPins = (pins: IPin[], collectionLogic?: CollectionLogic): IPin[] => {
  if (!pins?.length) return [];

  switch (collectionLogic) {
    case CollectionLogic.Sequential:
      return pins.sort((a, b) => {
        if (!a.pinOrder || !b.pinOrder) return 0;
        return (a.pinOrder.id || 0) - (b.pinOrder.id || 0);
      });

    case CollectionLogic.Branching:
      // First, create a map of parent pins to their children
      const parentChildMap = new Map<string | null, IPin[]>();
      
      // Initialize with root level (null parent)
      parentChildMap.set(null, []);
      
      // Group pins by their parent
      pins.forEach(pin => {
        const parentKey = pin.branchParentPinKey || null;
        if (!parentChildMap.has(parentKey)) {
          parentChildMap.set(parentKey, []);
        }
        parentChildMap.get(parentKey)?.push(pin);
      });

      // Helper function to sort pins within their level
      const sortLevel = (pins: IPin[]) => {
        return pins.sort((a, b) => {
          if (!a.pinOrder || !b.pinOrder) return 0;
          
          // First sort by id (hierarchy level)
          if (a.pinOrder.id !== b.pinOrder.id) {
            return a.pinOrder.id - b.pinOrder.id;
          }
          
          // Then by mainBranch
          if (a.pinOrder.mainBranch !== b.pinOrder.mainBranch) {
            return a.pinOrder.mainBranch - b.pinOrder.mainBranch;
          }
          
          // Finally by subBranch
          return a.pinOrder.subBranch - b.pinOrder.subBranch;
        });
      };

      // Function to flatten the hierarchy
      const flattenHierarchy = (parentKey: string | null, result: IPin[] = []): IPin[] => {
        const children = parentChildMap.get(parentKey) || [];
        const sortedChildren = sortLevel(children);
        
        for (const child of sortedChildren) {
          result.push(child);
          // Only get children if they belong to this branch path
          if (parentChildMap.has(child.pinKey)) {
            flattenHierarchy(child.pinKey, result);
          }
        }
        
        return result;
      };

      // Start with root pins (those with no parent)
      return flattenHierarchy(null);

    default:
      // For regular pins or undefined collection logic
      return pins.sort((a, b) => {
        const nameA = a.name?.toLowerCase() || '';
        const nameB = b.name?.toLowerCase() || '';
        return nameA.localeCompare(nameB);
      });
  }
};

export const calculateIndentationLevel = (pinOrder: PinOrder | null): number => {
  if (!pinOrder) return 0;
  const { branch } = pinOrder;
  if (branch === 0) return 0;
  
  const branchParts = branch.toString().split('.');
  const firstLevel = parseInt(branchParts[0]);
  
  // Return 1 for first level branches (1.x), 2 for higher level branches (2.x, 3.x)
  return firstLevel <= 1 ? 1 : 2;
};
