// src/components/AudioPlayer.tsx
import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { audioService } from '../services/AudioService';

interface AudioPlayerProps {
  audioUrl: string;
  onError?: () => void;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioUrl, onError }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    try {
      audioService.loadAudio(audioUrl).catch(error => {
        console.error('Error loading audio:', error);
        if (onError) {
          onError();
        }
      });
    } catch (error) {
      console.error('Error in audio service:', error);
      if (onError) {
        onError();
      }
    }
    return () => {
      audioService.stop();
    };
  }, [audioUrl, onError]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioService.pause();
    } else {
      audioService.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <IconButton onClick={togglePlayPause}>
      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
    </IconButton>
  );
};

export default AudioPlayer;