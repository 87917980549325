import { FC, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { BranchingPinCard } from './BranchingPinCard';
import { IPin, PinOrder } from '../types';
import './BranchingPinView.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { IconButton } from '@mui/material';
import { PinEditor } from './PinEditor';
import { PinEditorProps } from './PinEditor';
import { handleSavePin } from '../utilities/handleSavePin';
import { useAdminNav } from '../hooks/useAdminNav';
import PortalPopup from './PortalPopup';

interface BranchingPinViewProps {
  branchingTree: Map<number, IPin[]>;
  isOrphanDragging: boolean;
  dragOverPinKey: string | null;
  onPinClick: (pin: IPin) => void;
  onAddPin: (level: number, parentPinKey: string | null) => void;
}

export const BranchingPinView: FC<BranchingPinViewProps> = ({
  branchingTree,
  isOrphanDragging,
  dragOverPinKey,
  onPinClick,
  onAddPin
}) => {
  const { selectedProject } = useAdminNav();
  const [isPinEditorOpen, setIsPinEditorOpen] = useState(false);
  const [editorInitialData, setEditorInitialData] = useState<PinEditorProps | null>(null);

  const handleAddPin = (level: number, parentPinKey: string | null) => {
    const parentPin = parentPinKey ? 
      Array.from(branchingTree.values())
        .flat()
        .find(pin => pin.pinKey === parentPinKey) : null;

    setEditorInitialData({
      onClose: () => setIsPinEditorOpen(false),
      onSave: async (pin: IPin) => {
        try {
          // Root pin creation
          if (!parentPin) {
            const rootPinOrder: PinOrder = {
              id: 1,
              branch: 0,
              mainBranch: 0,
              subBranch: 0,
              branchLevel: 0
            };

            const rootPin = {
              ...pin,
              pinOrder: rootPinOrder,
              branchParentPinKey: null
            };

            await handleSavePin(rootPin, selectedProject, () => setIsPinEditorOpen(false));
            return;
          }
          
          // Get existing siblings at this level
          const siblings = Array.from(branchingTree.values())
            .flat()
            .filter(p => p.branchParentPinKey === parentPinKey);
          
          // Calculate new pin order based on parent's level
          const newPinOrder: PinOrder = {
            id: parentPin.pinOrder?.id ? parentPin.pinOrder.id + 1 : 2,
            mainBranch: parentPin.pinOrder?.mainBranch ? parentPin.pinOrder.mainBranch + 1 : 1,
            subBranch: siblings.length + 1,
            branchLevel: parentPin.pinOrder?.branchLevel ? parentPin.pinOrder.branchLevel + 1 : 1,
            branch: (parentPin.pinOrder?.mainBranch ? parentPin.pinOrder.mainBranch + 1 : 1) + 
                   ((siblings.length + 1) / 10)
          };

          const pinWithOrder = {
            ...pin,
            pinOrder: newPinOrder,
            branchParentPinKey: parentPinKey
          };

          await handleSavePin(pinWithOrder, selectedProject, () => setIsPinEditorOpen(false));
        } catch (error) {
          console.error('Error saving pin:', error);
        }
      },
      initialBranchingData: {
        branchParentPinKey: parentPinKey,
      },
    });
    setIsPinEditorOpen(true);
  };

  return (
    <div className="branching-container">
      {Array.from(branchingTree.entries()).map(([level, pins]) => {
        return (
          <div key={level} className={`branch-level level-${level}`}>
            <div className="level-pins">
              {pins.map((pin) => {
                if (!pin.pinKey) {
                  console.warn('Pin missing pinKey:', pin);
                  return null;
                }

                return (
                  <div key={pin.pinKey} className="pin-wrapper">
                    <BranchingPinCard
                      pinKey={pin.pinKey}
                      isDragOver={dragOverPinKey === pin.pinKey}
                      onClick={pin.pinOrder?.branch === 0 ? undefined : () => onPinClick(pin)}
                    />
                    
                    {(pin.pinOrder?.branchLevel || 0) < 2 && (
                      <div className="add-pin-wrapper">
                        <IconButton 
                          onClick={() => handleAddPin(level, pin.pinKey)}
                          title={`Add child to ${pin.name}`}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}

      {/* Pin Editor Modal with PortalPopup */}
      {isPinEditorOpen && editorInitialData && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={() => setIsPinEditorOpen(false)}
        >
          <PinEditor {...editorInitialData} />
        </PortalPopup>
      )}
    </div>
  );
};
