import { FunctionComponent, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import userStore from "../stores/UserStore";
import FormLogin from "../components/FormLogin";
import { ProjectManager } from "../models";
import "./LoginScreen.css";

type LoginType = 'admin' | 'organisation';
type AdminType = 'superAdmin' | 'projectAdmin';
type OrgType = 'orgAdmin' | 'orgModerator';

const LoginScreen: FunctionComponent = observer(() => {
  const [loginType, setLoginType] = useState<LoginType>('admin');
  const [adminType, setAdminType] = useState<AdminType>('projectAdmin');
  const [orgType, setOrgType] = useState<OrgType>('orgAdmin');
  const { loading, error } = userStore;
  const navigate = useNavigate();

  const handleLogin = async (data: { 
    email: string; 
    password: string; 
    accessCode?: string;
    projectId?: string;
  }) => {
    try {
      if (loginType === 'admin') {
        if (adminType === 'superAdmin') {
          // Handle super admin login
          await userStore.loginWithEmailPassword(data.email, data.password, 'superAdmin');
          navigate('/pin-list-view');
        } else {
          // Handle project admin login
          if (!data.projectId) {
            throw new Error('Project selection is required');
          }

          // First, get the project to check ownership
          const project = await ProjectManager.getProject(data.projectId);
          if (!project) {
            throw new Error('Project not found');
          }

          // Login the user to get their UID
          await userStore.loginWithEmailPassword(data.email, data.password, 'projectAdmin');
          
          // Check if the logged-in user's UID matches the project's owner key
          if (userStore.user?.userId !== project.projectOwnerUserKey) {
            // If not matching, clear the user and throw error
            userStore.clearUser();
            throw new Error('You do not have admin access to this project');
          }

          // If we get here, the user is the project owner
          userStore.setSelectedProject(data.projectId);
          navigate('/pin-list-view');
        }
      } else {
        // Handle organisation login
        if (orgType === 'orgAdmin') {
          await userStore.loginWithEmailPassword(data.email, data.password, 'organisationAdmin');
          navigate('/org-dashboard');
        } else {
          if (data.accessCode) {
            await userStore.loginWithAccessCode(data.accessCode, 'moderator');
          } else {
            await userStore.loginWithEmailPassword(data.email, data.password, 'moderator');
          }
          navigate('/moderator-dashboard');
        }
      }
    } catch (error) {
      console.error('Login error:', error);
      userStore.setError(error instanceof Error ? error : new Error('Login failed'));
    }
  };

  return (
    <Box className="loginscreen" sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ 
        p: 4, 
        width: '100%',
        maxWidth: 600, 
        mx: "auto",
        '& form': {
          width: '100%'
        }
      }}>
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <img
            className="logo-container-icon"
            alt="Overhear Logo"
            src="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
          />
        </Box>

        <Tabs
          value={loginType}
          onChange={(_, newValue: LoginType) => setLoginType(newValue)}
          centered
          variant="fullWidth"
          sx={{ 
            mb: 4,
            '& .MuiTabs-flexContainer': {
              justifyContent: 'space-between',
            },
            '& .MuiTab-root': {
              minWidth: 'auto',
              flex: 1,
            }
          }}
        >
          <Tab label="Admin Login" value="admin" />
          <Tab label="Organisation Login" value="organisation" />
        </Tabs>

        <FormLogin
          loginType={loginType}
          adminType={adminType}
          orgType={orgType}
          onAdminTypeChange={setAdminType}
          onOrgTypeChange={setOrgType}
          onSubmit={handleLogin}
          loading={loading}
          error={error}
        />
      </Paper>
    </Box>
  );
});

export default LoginScreen;