import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import RecordingEditor from '../components/RecordingEditor';
import { ProjectManager } from '../models/ProjectManager';
import AuthorManager from '../models/AuthorManager';
import UserManager from '../models/UserManager';
import './FormsView.css'
import "../components/EditRecording.css";
import { TextField, Modal, Box, CircularProgress, Alert, Snackbar } from '@mui/material';
import RecordingStore from '../stores/RecordingStore';
import authenticationStore from '../stores/AuthenticationStore';
import { IAuthor } from '../types/Author';
import { TermsAndConditions } from '../components';

const FormsView: React.FC = observer(() => {
  const { project_id } = useParams<{ project_id: string }>();
  const [config, setConfig] = useState({
    locationInfo: true,
    authorInfo: true,
    recordingInfo: true,
    uploadFields: true,
    password: ''
  });
  const [inputPassword, setInputPassword] = useState('');
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const [authorData, setAuthorData] = useState<IAuthor | null>(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  
  // New state variables for error handling and loading states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info'>('info');

  const showMessage = (message: string, severity: 'success' | 'error' | 'info' = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const fetchConfig = async () => {
    try {
      setIsLoading(true);
      setError(null);
      if (project_id) {
        const projectConfig = await ProjectManager.getProjectConfig(project_id);
        if (projectConfig) {
          setConfig(projectConfig);
        } else {
          throw new Error('Project configuration not found');
        }
      } else {
        throw new Error('Project ID is missing');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load project configuration';
      setError(errorMessage);
      showMessage(errorMessage, 'error');
      console.error('Error fetching config:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAuthorData = async () => {
    try {
      if (authenticationStore.isAuthenticated && authenticationStore.user) {
        const user = await UserManager.getCurrentUser(authenticationStore.user.uid);
        if (user && user.userProfile.authorKey) {
          const author = await AuthorManager.getAuthor(user.userProfile.authorKey);
          if (author) {
            setAuthorData(author);
          }
        }
      }
    } catch (err) {
      console.error('Error fetching author data:', err);
      showMessage('Failed to load author data. Some features may be limited.', 'error');
    }
  };

  useEffect(() => {
    fetchConfig();
    fetchAuthorData();
  }, [project_id, authenticationStore.isAuthenticated, authenticationStore.user]);

  useEffect(() => {
    // Suppress ResizeObserver loop error
    const resizeObserverError = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends resizeObserverError {
      constructor(callback: ResizeObserverCallback) {
        super((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
          // Prevent the error by wrapping the callback in requestAnimationFrame
          window.requestAnimationFrame(() => {
            callback(entries, observer);
          });
        });
      }
    };

    return () => {
      window.ResizeObserver = resizeObserverError;
    };
  }, []);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value);
    setError(null); // Clear any previous errors
  };

  const handleSubmitPassword = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      if (!inputPassword.trim()) {
        throw new Error('Please enter a password');
      }

      setPasswordSubmitted(true);
      await fetchConfig();
      
      if (!isPasswordCorrect()) {
        throw new Error('Incorrect password. Please try again.');
      }
      
      setOpenTermsModal(true);
      showMessage('Password accepted', 'success');
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      setError(errorMessage);
      setPasswordSubmitted(false);
      showMessage(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitPassword();
    }
  };

  const isPasswordCorrect = () => {
    return inputPassword === config.password;
  };

  const handleClose = () => {
    // Handle closing the RecordingEditor if needed
  };

  const handleRecordingAdded = () => {
    showMessage('Recording added successfully', 'success');
  };

  const handleSave = async (updatedRecording: any) => {
    try {
      setIsLoading(true);
      
      const user = authenticationStore.user;
      
      // Create a sanitized version of the recording
      const sanitizedRecording = { ...updatedRecording };
      
      // Add author if available
      if (user && authorData) {
        sanitizedRecording.authorId = authorData.authorKey;
      }

      // Handle file data - remove undefined file properties
      if (sanitizedRecording.file) {
        Object.keys(sanitizedRecording.file).forEach(key => {
          if (sanitizedRecording.file[key] === undefined) {
            delete sanitizedRecording.file[key];
          }
        });
        
        // If file object is empty, remove it entirely
        if (Object.keys(sanitizedRecording.file).length === 0) {
          delete sanitizedRecording.file;
        }
      }

      await RecordingStore.addRecording(sanitizedRecording);
      // Don't show success message here, let RecordingEditor handle it
    } catch (error) {
      console.error('Error in save process:', error);
      throw error; // Let RecordingEditor handle the error
    } finally {
      setIsLoading(false);
    }
  };

  const handleTermsAgreement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(event.target.checked);
  };

  const handleContinueAfterTerms = () => {
    setTermsAgreed(true);
    setOpenTermsModal(false);
    showMessage('Terms accepted. You can now proceed with the form.', 'success');
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" mb={2}>
        <img 
          src="/assets/icons/overhearlight.png" 
          alt="Overhear Light" 
          style={{ 
            width: 120, 
            height: 140, 
            marginTop: '2rem' 
          }} 
        />
      </Box>

      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {!passwordSubmitted || !isPasswordCorrect() ? (
        <div className='password-container'>
          <label>Enter Password to Access Form:</label>
          <TextField
            type="password"
            onChange={handlePasswordChange}
            value={inputPassword}
            onKeyDown={handleKeyDown}
            error={!!error}
            helperText={error}
            disabled={isLoading}
          />
          <button 
            onClick={handleSubmitPassword}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Submit'}
          </button>
        </div>
      ) : (
        <RecordingEditor
          onClose={handleClose}
          recordingKey={undefined}
          recording={undefined}
          sourceView="Form"
          config={config}
          project_id={project_id}
          onRecordingAdded={handleRecordingAdded}
          onSave={handleSave}
          prefillAuthorData={authorData}
        />
      )}

      <Modal
        open={openTermsModal}
        onClose={() => {}}
        aria-labelledby="terms-and-conditions-modal"
        aria-describedby="terms-and-conditions-description"
        disableAutoFocus
        disableEnforceFocus
        disablePortal
        keepMounted
      >
        <Box className="modal-box">
          <TermsAndConditions
            agreed={termsAgreed}
            onAgree={handleTermsAgreement}
            onContinue={handleContinueAfterTerms}
          />
        </Box>
      </Modal>
    </div>
  );
});

export default FormsView;