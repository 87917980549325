import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { SelectChangeEvent } from "@mui/material";
import "./InputForms.css";
import { ProjectManager, TagManager, AlbumManager } from "../models";
import { IPin, Location, GeoPointModel, Album, createPinOrder, PinOrder } from "../types";
import { constructUrl, handleFileUpload } from "../utilities";
import PinStore from "../stores/PinStore";
import AllDataManager from "../models/AllDataManager";
import { useAdminNav } from '../hooks';
import PinForm from './PinForm';
import { CollectionLogic } from "../types/Project";
import { Typography, Box } from '@mui/material';

// At the top of your file, add this type guard
function isValidCoordinate(value: any): value is number {
  return typeof value === 'number' && !isNaN(value);
}

export type PinEditorProps = {
  onClose: () => void;
  onSave: (pin: IPin) => Promise<void>;
  pinData?: IPin | null;
  initialCoords?: { lat: number; long: number };
  firstPinAlbumKey?: string | null;
  initialBranchingData?: {
    branchParentPinKey: string | null;
  };
};

export const PinEditor: React.FC<PinEditorProps> = ({ 
  onClose, 
  onSave, 
  pinData, 
  initialCoords, 
  firstPinAlbumKey,
  initialBranchingData
}) => {
  const isEditMode = !!pinData;
  const [isQREnabled, setIsQREnabled] = useState(pinData?.pinType === 'qrCode' || false);
  const [isCollectionLogicEnabled, setIsCollectionLogicEnabled] = useState(pinData?.isCollectionLogicEnabled || false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [pinIconUrl, setPinIconUrl] = useState<string>(''); // Initialize pinIconUrl as an empty string
  const [orderLogic, setOrderLogic] = useState(pinData?.collectionLogic || '');
  const [name, setPinName] = useState(pinData?.name || '');
  const [pinDescription, setPinDescription] = useState(pinData?.pinDescription || '');
  const [pinType, setPinType] = useState(pinData?.pinType || 'geoLocation');
  const [location, setLocation] = useState<Location>({
    geoPoint: pinData?.location?.geoPoint || { lat: 0, long: 0 },
    radius: pinData?.location?.radius || null
  });
  const [radius, setRadius] = useState<number | null>(pinData?.location?.radius || 30);
  const [locationTags, setLocationTags] = useState<string[]>(pinData?.tags?.locationTags || []);
  const [isUploading, setIsUploading] = useState(false);

  const { projects, selectedProject } = useAdminNav();
  const selectedProjectDetails = projects.find(project => project.project_id === selectedProject);
  const [branchParentPinKey, setBranchParentPinKey] = useState(
    initialBranchingData?.branchParentPinKey ?? pinData?.branchParentPinKey ?? null
  );

  const [parentPinName, setParentPinName] = useState<string | null>(null);

  useEffect(() => {
    if (isEditMode && pinData?.pinIcon) {
      // Construct the full URL for the pin icon
      const fullUrl = constructUrl(pinData.pinIcon);
      setPinIconUrl(fullUrl);
    } else if (!isEditMode) {
      // Fetch project icon logic (existing code)
      const fetchProjectIcon = async () => {
        try {
          const projectIconPath = await ProjectManager.getProjectIcon(selectedProject);
          if (projectIconPath) {
            const fullUrl = constructUrl(projectIconPath);
            setPinIconUrl(fullUrl);
          }
        } catch (error) {
          console.error('Error fetching project icon:', error);
        }
      };

      if (!selectedFile) {
        fetchProjectIcon();
      }
    }

    if (initialCoords) {
      setLocation(prevState => ({
        ...prevState,
        geoPoint: {
          lat: initialCoords.lat,
          long: initialCoords.long,
        },
      }));
    }
  }, [isEditMode, pinData, selectedFile, selectedProject, initialCoords]);

  useEffect(() => {
    const fetchParentPinInfo = async () => {
      const parentKey = initialBranchingData?.branchParentPinKey || pinData?.branchParentPinKey;
      if (parentKey && selectedProjectDetails?.collectionLogic === CollectionLogic.Branching) {
        const parentPin = PinStore.pins.get(parentKey);
        if (parentPin) {
          setParentPinName(parentPin.name || 'Unnamed Parent Pin');
        }
      }
    };

    fetchParentPinInfo();
  }, [initialBranchingData?.branchParentPinKey, pinData?.branchParentPinKey, selectedProjectDetails?.collectionLogic]);

  const handlePinNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPinName(event.target.value);
  };

  const handlePinDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPinDescription(event.target.value);
  };

  // Then update the handleLocationChange function
  const handleLocationChange = (fieldName: keyof GeoPointModel) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value);
      if (isValidCoordinate(value)) {
        setLocation(prevState => ({
          ...prevState,
          geoPoint: {
            ...prevState.geoPoint!,
            [fieldName]: value
          }
        }));
      }
    };

  const handleQrToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsQREnabled(event.target.checked);
  };

  const handleCollectionLogicToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCollectionLogicEnabled(event.target.checked);
  };

  const handleOrderLogicChange = (event: SelectChangeEvent<string>) => {
    setOrderLogic(event.target.value);
  };

  const handleRadiusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === '' ? null : parseFloat(event.target.value);
    setRadius(value);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
    if (file) {
      const filePath = 'pin-images/';
      const fileName = `pin-icon-${isEditMode ? pinData!.pinKey : 'new'}-${file.name}`;

      try {
        const uploadedUrl = await handleFileUpload(file, setPinIconUrl, filePath, fileName);
        setPinIconUrl(uploadedUrl);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleDelete = async () => {
    if (isEditMode && window.confirm('Are you sure you want to delete this pin and all associated data?')) {
      try {
        await TagManager.removePinFromTags(pinData!.pinKey);
        await AllDataManager.removePinAndAssociatedData(pinData!.pinKey);
        PinStore.removePin(pinData!.pinKey);
        onClose();
      } catch (error) {
        console.error('Error deleting pin and associated data:', error);
      }
    }
  };

  const handleSave = async () => {
    setIsUploading(true);
    let pinIconPath = pinIconUrl;

    // Only process the file if a new one has been selected
    if (selectedFile) {
      try {
        const filePath = 'pin-images/';
        const fileName = `${Date.now()}${selectedFile.name}`;
        pinIconPath = await handleFileUpload(selectedFile, setPinIconUrl, filePath, fileName);
      } catch (error) {
        console.error('Error uploading file:', error);
        setIsUploading(false);
        return;
      }
    } else {
      // Fetch the project icon if no file is selected, it's not edit mode, and there's no existing icon
      try {
        const projectIcon = await ProjectManager.getProjectIcon(selectedProject);
        if (projectIcon) {
          pinIconPath = projectIcon;
        }
      } catch (error) {
        console.error('Error fetching project icon:', error);
        pinIconPath = '/default-pin-icon.png';
      }
    }

    const lat = parseFloat(location.geoPoint?.lat?.toString() || '0');
    const long = parseFloat(location.geoPoint?.long?.toString() || '0');

    if (!isValidCoordinate(lat) || !isValidCoordinate(long)) {
      console.error('Invalid latitude or longitude');
      setIsUploading(false);
      return;
    }

    const parentPin = branchParentPinKey ? PinStore.getPin(branchParentPinKey) : null;
    let newPinOrder: PinOrder | null = null;
    
    if (selectedProjectDetails?.collectionLogic === CollectionLogic.Branching) {
      if (!parentPin) {
        // Root pin
        newPinOrder = createPinOrder(1, 0);
      } else {
        // Get existing siblings
        const siblings = Array.from(PinStore.pins.values())
          .filter(p => p.branchParentPinKey === branchParentPinKey);
        
        const nextId = parentPin.pinOrder?.id ? parentPin.pinOrder.id + 1 : 2;
        const nextMainBranch = parentPin.pinOrder?.mainBranch ? parentPin.pinOrder.mainBranch + 1 : 1;
        const nextSubBranch = siblings.length + 1;
        
        const branch = nextMainBranch + (nextSubBranch / 10);
        newPinOrder = createPinOrder(nextId, branch);
      }
    }

    const updatedPin: IPin = {
      pinKey: isEditMode ? pinData!.pinKey : uuidv4(),
      name,
      pinDescription,
      pinType: selectedProjectDetails?.isTransitProjectEnabled && isQREnabled ? 'QRTransit' : selectedProjectDetails?.isTransitProjectEnabled ? 'transit' : isQREnabled ? 'qrCode' : 'geoLocation',
      location: {
        geoPoint: { lat, long },
        radius,
      },
      albumKey: isEditMode ? pinData!.albumKey : (selectedProjectDetails?.isTransitProjectEnabled && firstPinAlbumKey) ? firstPinAlbumKey : uuidv4(),
      isCollectionLogicEnabled,
      collectionLogic: orderLogic,
      pinOrder: newPinOrder,
      tags: { locationTags },
      project: selectedProject,
      pinIcon: pinIconPath,
      isAvailable: true,
      branchParentPinKey
    };

    try {
      if (!isEditMode) {
        const newAlbum: Album = {
          albumKey: updatedPin.albumKey,
          name: `${name || 'Unnamed Pin'}`,
          projectKey: selectedProject,
          userKey: null,
        };
        await AlbumManager.createAlbum(newAlbum);
      }

      const uniqueTags = Array.from(new Set(locationTags));
      for (const tagName of uniqueTags) {
        if (tagName) {
          await TagManager.addTagToPin(tagName, updatedPin.pinKey);
        }
      }

      if (isEditMode) {
        const removedTags = pinData!.tags?.locationTags.filter(tag => !locationTags.includes(tag)) || [];
        for (const tag of removedTags) {
          await TagManager.removePinKeyFromTag(tag, pinData!.pinKey);
        }
      }

      await onSave(updatedPin);

      // Ensure selectedProject is a string before calling addPinToProject
      if (selectedProject) {
        await ProjectManager.addPinToProject(selectedProject, updatedPin.pinKey);
      } else {
        console.error('Selected project is null or undefined');
      }

      setIsUploading(false);
      onClose();
    } catch (error) {
      console.error('Error saving pin:', error);
      setIsUploading(false);
    }
  };

  return (
    <div className="edit-recording edit-recording-pin">
      {selectedProjectDetails?.collectionLogic === CollectionLogic.Branching && 
       (initialBranchingData?.branchParentPinKey || pinData?.branchParentPinKey) && (
        <Box sx={{ 
          padding: 2, 
          marginBottom: 2, 
          backgroundColor: '#f5f5f5',
          borderRadius: 1
        }}>
          <Typography variant="subtitle2" color="textSecondary">
            Parent Pin
          </Typography>
          <Typography variant="body1">
            {parentPinName || 'Loading...'}
          </Typography>
        </Box>
      )}

      <PinForm
        pinData={pinData || {}}
        isQREnabled={isQREnabled}
        isCollectionLogicEnabled={isCollectionLogicEnabled}
        orderLogic={orderLogic}
        pinIconUrl={pinIconUrl}
        name={name}
        pinDescription={pinDescription}
        location={location}
        radius={radius}
        locationTags={locationTags}
        isProjectQREnabled={!!selectedProjectDetails?.isQREnabled}
        collectionLogic={selectedProjectDetails?.collectionLogic}
        onPinNameChange={handlePinNameChange}
        onPinDescriptionChange={handlePinDescriptionChange}
        onLocationChange={handleLocationChange}
        onQrToggleChange={handleQrToggleChange}
        onCollectionLogicToggleChange={handleCollectionLogicToggleChange}
        onOrderLogicChange={handleOrderLogicChange}
        onRadiusChange={handleRadiusChange}
        onFileChange={handleFileChange}
        setLocationTags={setLocationTags}
        onCancel={onClose}
        onSave={handleSave}
        onDelete={isEditMode ? handleDelete : undefined}
        isUploading={isUploading}
        showDelete={isEditMode}
      />
    </div>
  );
};

export default PinEditor;