import React from "react";
import { TextField } from "@mui/material";
import "./InputForms.css";

interface WanderFormProps {
  name: string;
  setName: (name: string) => void;
  description?: string;
  setDescription?: (description: string) => void;
  isUploading: boolean;
  showDelete: boolean;
  onCancel: () => void;
  onSave: () => Promise<void>;
  onDelete?: () => Promise<void>;
}

const WanderForm: React.FC<WanderFormProps> = ({
  name,
  setName,
  description,
  setDescription,
  isUploading,
  showDelete,
  onCancel,
  onSave,
  onDelete,
}) => {
  return (
    <>
      <TextField
        className="input-container"
        color="primary"
        label="Name"
        size="medium"
        variant="filled"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      {setDescription && (
        <TextField
          className="input-container"
          color="primary"
          label="Description"
          size="medium"
          variant="filled"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      )}
      <div className="buttons">
        {showDelete && (
          <button className="button-action-delete" onClick={onDelete}>
            <b className="delete-text">Delete</b>
          </button>
        )}
        <button className="button-action-cancel" onClick={onCancel}>
          <b className="cancel-text">Cancel</b>
        </button>
        <button className="button-action-save" onClick={onSave} disabled={isUploading}>
          <b className="save-text">{isUploading ? 'Saving...' : 'Save'}</b>
        </button>
      </div>
    </>
  );
};

export default WanderForm;
