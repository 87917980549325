// src/components/ProjectItem.tsx
import React, { FunctionComponent, useCallback, useState } from "react";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import { observer } from "mobx-react-lite";
import { IProject } from "../types/Project";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { constructUrl } from "../utilities/constructUrl";
import "./ProjectItem.css";
import { IconButton } from "@mui/material";
import PortalPopup from "./PortalPopup";
import ProjectEditor from "./ProjectEditor";
import { useProjectData } from "../hooks/useProjectData";
import { projectStore } from "../stores/ProjectStore";
import { useNavigate } from "react-router-dom";
import { useAdminNav } from "../hooks/useAdminNav";

export const ProjectItem: FunctionComponent<{ project: IProject; onToggleAvailability?: (project: IProject) => void }> = observer(({ project, onToggleAvailability }) => {
  const [isEditProjectOpen, setEditProjectOpen] = useState(false);
  const { projectData } = useProjectData(project.project_id);
  const navigate = useNavigate();
  const { onProjectChange } = useAdminNav();

  // Get the latest project data from the store
  const currentProject = projectStore.projects.find(p => p.project_id === project.project_id) || project;
  
  const handleSave = async (updatedProject: IProject) => {
    try {
      await projectStore.updateProject(updatedProject.project_id, updatedProject);
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };
  
  const handleToggleAvailability = useCallback(() => {
    try {
      if (onToggleAvailability) {
        onToggleAvailability(currentProject);
      } else {
        projectStore.toggleProjectAvailability(currentProject.project_id);
      }
    } catch (error) {
      console.error('Error toggling availability:', error);
    }
  }, [onToggleAvailability, currentProject]);

  const toggleEditProject = useCallback((e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    setEditProjectOpen(prev => !prev);
  }, []);

  const handleProjectClick = useCallback(() => {
    onProjectChange(currentProject.project_id);
    navigate("/pin-list-view");
  }, [navigate, currentProject.project_id, onProjectChange]);

  const handleIconClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const imageUrl = currentProject.icon 
    ? constructUrl(currentProject.icon) 
    : "/assets/overhear-assets/images/ovh-logoartboard-12x-1.png";
  const subjectTags = currentProject.tags?.subjectTag?.filter(tag => tag !== currentProject.projectName) || [];

  return (
    <div className="project-item" onClick={handleProjectClick}>
      <div className="project-header">
        <IconButton 
          onClick={(e) => {
            e.stopPropagation();
            handleToggleAvailability();
          }}
          onMouseDown={handleIconClick}
        >
          {currentProject?.isAvailable ? (
            <CheckBoxIcon style={{ color: 'white', fontSize: 24 }} className="check-icon" />
          ) : (
            <CheckBoxOutlineBlankIcon style={{ color: 'white', fontSize: 24 }} className="check-icon" />
          )}
        </IconButton>
        <img className="project-icon" alt={currentProject.projectName} src={imageUrl} />
        
        <div className="project-name">{currentProject.projectName}</div>
        <div className="pin-count"> ({currentProject.pins?.length || 0})</div>

        <div className="project-subject-tags">
          {subjectTags.map(tag => <div className="tag" key={tag}>{tag}</div>)}
        </div>
        <IconButton 
          className="edit-container" 
          color="primary" 
          onClick={(e) => {
            e.stopPropagation();
            toggleEditProject();
          }}
          onMouseDown={handleIconClick}
        >
          <EditSharpIcon style={{ fontSize: 40, color: 'black' }}/>
        </IconButton>
      </div>
      {isEditProjectOpen && projectData && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={toggleEditProject}
        >
          <ProjectEditor 
            key={currentProject.project_id}
            onClose={toggleEditProject} 
            project_id={currentProject.project_id} 
            initialProjectData={projectData} 
            onSave={handleSave}
          />
        </PortalPopup>
      )}
    </div>
  );
});

export default ProjectItem;