import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select as MuiSelect,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Checkbox,
  Collapse,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Group as GroupIcon,
  QrCode as QrCodeIcon,
  ContentCopy as ContentCopyIcon,
  Download as DownloadIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import QRCode from 'qrcode';
import { OrganisationManager } from '../models/OrganisationManager';
import userStore from '../stores/UserStore';
import { IMember, IGroup, MemberCode } from '../types/organisation';
import { MemberManagement } from '../components/members/MemberManagement';
import { useNavigate } from 'react-router-dom';
import { AdminNav } from '../components/AdminNav';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const ANIMAL_EMOJIS = [
  '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐨', '🐯',
  '🦁', '🐮', '🐷', '🐸', '🐵', '🐔', '🦆', '🦅', '🦉', '🦋',
  '🐢', '🐙', '🦈', '🦒', '🦘', '🦡', '🦃', '🐝', '🐞', '🦕',
  '🐬', '🦭', '🐘', '🦬', '🦩', '🦜', '🐧', '🐦',
  '🦢', '🦫', '🦦', '🦥', '🦨', '🦘', '🦙', '🦛', '🐪', '🐫'
];

const ModeratorDashboard: React.FC = observer(() => {
  const [tabValue, setTabValue] = useState(0);
  const [members, setMembers] = useState<IMember[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [generatingCodes, setGeneratingCodes] = useState(false);
  const { currentOrganisation } = userStore;
  const [isCreateGroupOpen, setIsCreateGroupOpen] = useState(false);
  const [isGenerateCodesOpen, setIsGenerateCodesOpen] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [studentCount, setStudentCount] = useState<number>(1);
  const [generatedCodes, setGeneratedCodes] = useState<MemberCode[]>([]);
  const [removingMember, setRemovingMember] = useState<string | null>(null);
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
  const [groupMode, setGroupMode] = useState<'new' | 'existing'>('new');
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [collapsedGroups, setCollapsedGroups] = useState<Set<string>>(new Set());
  const [collapsedQRGroups, setCollapsedQRGroups] = useState<Set<string>>(new Set());
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is authenticated and has moderator role
    if (!userStore.currentUser) {
      navigate('/loginscreen');
      return;
    }

    if (userStore.currentRole !== 'moderator' && userStore.currentRole !== 'organisationAdmin') {
      console.error('User does not have required role:', userStore.currentRole);
      setError('You do not have access to this organization');
      navigate('/loginscreen');
      return;
    }

    loadData();
  }, [userStore.currentUser, userStore.currentRole]);

  const loadData = async () => {
    if (!currentOrganisation) {
      setError('No organisation selected');
      return;
    }
    
    setLoading(true);
    try {
      // Load groups and generated codes in parallel first
      const [loadedGroups, loadedCodes] = await Promise.all([
        OrganisationManager.getOrganisationGroups(currentOrganisation.id),
        OrganisationManager.getGeneratedCodes(currentOrganisation.id)
      ]);

      setGroups(loadedGroups);

      // Process the loaded codes
      const codesWithQR = await Promise.all(
        loadedCodes.map(async (code: any) => ({
          identifier: code.identifier,
          code: code.code,
          link: code.link,
          animalEmoji: code.animalEmoji,
          tempUserId: code.tempUserId,
          groupId: code.groupId,
          qrCode: await QRCode.toDataURL(code.link)
        } as MemberCode))
      );
      setGeneratedCodes(codesWithQR);

      // Now load members with the updated groups and codes
      await loadMembers();
    } catch (err) {
      console.error('ModeratorDashboard - Error loading data:', err);
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const loadMembers = async () => {
    if (!currentOrganisation) return;
    try {
      const orgMembers = await OrganisationManager.getOrganisationMembers(currentOrganisation.id);
      
      // Create a Map to deduplicate members by their ID
      const memberMap = new Map();
      
      // First, create a map of all group memberships from the groups collection
      const groupMemberships = new Map();
      groups.forEach(group => {
        group.memberIds.forEach(memberId => {
          groupMemberships.set(memberId, group.id);
        });
      });

      // Then, create a map of temporary user group assignments from generated codes
      const tempUserGroupMap = new Map(
        generatedCodes.map(code => [code.tempUserId, code.groupId])
      );
      
      orgMembers.forEach(member => {
        const id = member.userKey || member.tempUserId;
        if (id) {
          // For temporary users, prioritize the group from generated codes
          // For regular users, use the group from groups collection
          let groupId = member.isTemporary 
            ? tempUserGroupMap.get(id) || groupMemberships.get(id)
            : groupMemberships.get(id) || tempUserGroupMap.get(id);
          memberMap.set(id, {
            ...member,
            groupIds: groupId ? [groupId] : []
          });
        }
      });
      
      const uniqueMembers = Array.from(memberMap.values());
      setMembers(uniqueMembers);
    } catch (err) {
      console.error('ModeratorDashboard - Error loading members:', err);
      setError((err as Error).message);
    }
  };

  const getMemberTerm = (isPlural: boolean = false) => {
    return currentOrganisation?.type === 'school' 
      ? `Student${isPlural ? 's' : ''}`
      : `Member${isPlural ? 's' : ''}`;
  };

  const generateQRWithText = async (link: string, identifier: string): Promise<string> => {
    // Create a canvas to combine QR code and text
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get canvas context');

    // Set canvas size (larger than before)
    canvas.width = 400;
    canvas.height = 500; // Extra height for text

    // Generate QR code
    const qrCodeDataUrl = await QRCode.toDataURL(link, {
      width: 400,
      margin: 2,
      color: {
        dark: '#000000',
        light: '#ffffff'
      }
    });

    // Create an image from the QR code
    const qrImage = new Image();
    await new Promise((resolve, reject) => {
      qrImage.onload = resolve;
      qrImage.onerror = reject;
      qrImage.src = qrCodeDataUrl;
    });

    // Draw white background
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw QR code
    ctx.drawImage(qrImage, 0, 0, 400, 400);

    // Draw text
    ctx.fillStyle = '#000000';
    ctx.font = 'bold 24px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    
    // Draw identifier text (possibly in multiple lines if too long)
    const maxWidth = 380;
    const words = identifier.split(' ');
    let line = '';
    let lines = [];
    
    for (let word of words) {
      const testLine = line + (line ? ' ' : '') + word;
      const metrics = ctx.measureText(testLine);
      if (metrics.width > maxWidth && line) {
        lines.push(line);
        line = word;
      } else {
        line = testLine;
      }
    }
    lines.push(line);

    // Draw each line of text
    lines.forEach((line, index) => {
      ctx.fillText(line, canvas.width / 2, 425 + (index * 30));
    });

    return canvas.toDataURL('image/png');
  };

  const handleGenerateMemberCodes = async () => {
    if (!currentOrganisation || !selectedGroup) return;
    setGeneratingCodes(true);
    try {
      const group = groups.find(g => g.id === selectedGroup);
      if (!group) throw new Error('Selected group not found');

      // Get the highest existing member number for this group
      const existingNumbers = generatedCodes
        .filter(code => code.groupId === selectedGroup)
        .map(code => {
          const match = code.identifier.match(/(?:Student|Member) (\d+)/);
          return match ? parseInt(match[1]) : 0;
        })
        .filter(num => !isNaN(num));
      
      const startingNumber = existingNumbers.length > 0 ? Math.max(...existingNumbers) + 1 : 1;

      // Create a Set of used emojis for this group
      const usedEmojis = new Set(
        generatedCodes
          .filter(code => code.groupId === selectedGroup)
          .map(code => code.animalEmoji)
      );

      // Filter out already used emojis and shuffle the remaining ones
      const availableEmojis = ANIMAL_EMOJIS.filter(emoji => !usedEmojis.has(emoji))
        .sort(() => Math.random() - 0.5)
        .slice(0, studentCount);

      const newCodes = await Promise.all(
        availableEmojis.map(async (emoji, index) => {
          const studentNumber = startingNumber + index;
          const identifier = `${group.name} ${getMemberTerm()} ${studentNumber}`;
          const response = await OrganisationManager.generateAnonymousMemberCode(
            currentOrganisation.id,
            identifier,
            emoji,
            selectedGroup,
            userStore.currentUser?.email || ''
          );

          const code: MemberCode = {
            identifier: response.identifier,
            code: response.code,
            link: response.link,
            animalEmoji: response.animalEmoji,
            tempUserId: response.tempUserId,
            groupId: selectedGroup,
            qrCode: await generateQRWithText(response.link, response.identifier)
          };
          return { code, tempUserId: response.tempUserId };
        })
      );

      // Update the group with all new members at once
      const newMemberIds = newCodes.map(item => item.tempUserId);
      
      
      await OrganisationManager.updateGroupMembers(
        currentOrganisation.id,
        selectedGroup,
        [...group.memberIds, ...newMemberIds]
      );

      // Update local state
      setGeneratedCodes(prev => [...prev, ...newCodes.map(item => item.code)]);
      setGroups(prev => prev.map(g => 
        g.id === selectedGroup 
          ? { ...g, memberIds: [...g.memberIds, ...newMemberIds] }
          : g
      ));
      
      // Reload members to reflect the changes
      await loadMembers();
      setIsGenerateCodesOpen(false);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setGeneratingCodes(false);
    }
  };

  const handleCreateGroup = async () => {
    if (!currentOrganisation || !groupName) return;
    setCreatingGroup(true);
    try {
      await OrganisationManager.createGroup(currentOrganisation.id, {
        name: groupName,
        memberIds: selectedMembers
      });
      setIsCreateGroupOpen(false);
      setGroupName('');
      setSelectedMembers([]);
      await loadData();
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setCreatingGroup(false);
    }
  };

  const handleRemoveMember = async (memberId: string) => {
    if (!currentOrganisation) return;
    setRemovingMember(memberId);
    try {
      // First, remove member from any groups they're in
      const updatedGroups = await Promise.all(
        groups.map(async group => {
          if (group.memberIds.includes(memberId)) {
            const updatedMemberIds = group.memberIds.filter(id => id !== memberId);
            await OrganisationManager.updateGroupMembers(
              currentOrganisation.id,
              group.id,
              updatedMemberIds
            );
            return { ...group, memberIds: updatedMemberIds };
          }
          return group;
        })
      );
      setGroups(updatedGroups);

      // Then remove the member and their generated code
      await OrganisationManager.removeMember(currentOrganisation.id, memberId);
      setGeneratedCodes(prev => prev.filter(code => code.tempUserId !== memberId));
      
      // Finally reload the members list
      await loadMembers();
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setRemovingMember(null);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const downloadAllQRCodes = async () => {
    for (const code of generatedCodes) {
      if (code.link && code.identifier) {
        const qrWithText = await generateQRWithText(code.link, code.identifier);
        const link = document.createElement('a');
        link.download = `${code.identifier.toLowerCase()}-qr.png`;
        link.href = qrWithText;
        link.click();
      }
    }
  };

  const downloadAllCodes = () => {
    const csvContent = "data:text/csv;charset=utf-8," + 
      "Identifier,Animal,Access Code,Join Link\n" +
      generatedCodes.map(code => 
        `${code.identifier},${code.animalEmoji},${code.code},${code.link}`
      ).join("\n");

    const link = document.createElement('a');
    link.href = encodeURI(csvContent);
    link.download = 'student-access-codes.csv';
    link.click();
  };

  const renderCodeCard = (code: MemberCode) => (
    <Grid item xs={12} sm={6} md={4} key={code.code}>
      <Card variant="outlined" sx={{ height: '100%' }}>
        <CardContent>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1, 
            mb: 2 
          }}>
            <Typography variant="h6">
              {code.identifier}
            </Typography>
          </Box>
          
          <Box sx={{ mb: 2 }}>
            <Typography variant="caption" display="block" gutterBottom color="text.secondary">
              Access Code
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              backgroundColor: 'action.hover',
              p: 1.5,
              borderRadius: 1,
              width: '100%',
              maxWidth: '100%',
              overflow: 'hidden'
            }}>
              <Typography
                sx={{
                  fontFamily: 'monospace',
                  letterSpacing: '0.1em',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  flexGrow: 1,
                  backgroundColor: 'background.paper',
                  p: 1,
                  borderRadius: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {code.code}
              </Typography>
              <IconButton 
                onClick={() => copyToClipboard(code.code)}
                sx={{ 
                  flexShrink: 0,
                  width: 40,
                  height: 40,
                  backgroundColor: 'background.paper',
                  '&:hover': {
                    backgroundColor: 'action.hover'
                  }
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>

          {code.qrCode && (
            <Box sx={{ 
              textAlign: 'center', 
              mt: 2,
              p: 2,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              backgroundColor: 'background.paper'
            }}>
              <img 
                src={code.qrCode} 
                alt="QR Code" 
                style={{ 
                  width: '100%',
                  maxWidth: '300px',
                  height: 'auto',
                  display: 'block',
                  margin: '0 auto'
                }} 
              />
              <Button
                variant="outlined"
                size="small"
                startIcon={<DownloadIcon />}
                onClick={async () => {
                  if (code.link && code.identifier) {
                    const qrWithText = await generateQRWithText(code.link, code.identifier);
                    const link = document.createElement('a');
                    link.download = `${code.identifier.toLowerCase()}-qr.png`;
                    link.href = qrWithText;
                    link.click();
                  }
                }}
                sx={{ mt: 2 }}
              >
                Download QR
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );

  const handleSelectStudent = (memberId: string) => {
    setSelectedStudents(prev => 
      prev.includes(memberId) 
        ? prev.filter(id => id !== memberId)
        : [...prev, memberId]
    );
  };

  const handleSelectAll = () => {
    if (selectedStudents.length === members.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(members.map(m => m.userKey || m.tempUserId || '').filter(Boolean));
    }
  };

  const handleBatchDelete = async () => {
    if (!currentOrganisation || !selectedStudents.length) return;
    try {
      // First, remove selected members from any groups they're in
      const updatedGroups = await Promise.all(
        groups.map(async group => {
          const updatedMemberIds = group.memberIds.filter(id => !selectedStudents.includes(id));
          if (updatedMemberIds.length !== group.memberIds.length) {
            await OrganisationManager.updateGroupMembers(
              currentOrganisation.id,
              group.id,
              updatedMemberIds
            );
            return { ...group, memberIds: updatedMemberIds };
          }
          return group;
        })
      );
      setGroups(updatedGroups);

      // Then remove the members and their generated codes
      await Promise.all(
        selectedStudents.map(memberId => 
          OrganisationManager.removeMember(currentOrganisation.id, memberId)
        )
      );
      setGeneratedCodes(prev => prev.filter(code => !selectedStudents.includes(code.tempUserId || '')));
      setSelectedStudents([]);
      
      // Finally reload the members list
      await loadMembers();
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handleBatchAddToGroup = () => {
    setSelectedMembers(selectedStudents);
    setGroupMode('new');
    setIsCreateGroupOpen(true);
  };

  const handleAddToExistingGroup = async () => {
    if (!currentOrganisation || !selectedMembers.length || !groupName) return;
    setCreatingGroup(true);
    try {
      const selectedGroup = groups.find(g => g.id === groupName);
      if (selectedGroup) {
        const updatedMemberIds = [...new Set([...selectedGroup.memberIds, ...selectedMembers])];
        await OrganisationManager.updateGroupMembers(currentOrganisation.id, selectedGroup.id, updatedMemberIds);
        setIsCreateGroupOpen(false);
        setGroupName('');
        setSelectedMembers([]);
        await loadData();
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setCreatingGroup(false);
    }
  };

  const renderSelectionActions = () => (
    selectedStudents.length > 0 && (
      <Box 
        sx={{ 
          mb: 2,
          mt: 2,
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
          boxShadow: 1
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {selectedStudents.length} {getMemberTerm(selectedStudents.length > 1).toLowerCase()} selected
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<GroupIcon />}
          onClick={() => {
            setSelectedMembers(selectedStudents);
            setGroupMode('existing');
            setIsCreateGroupOpen(true);
          }}
        >
          Add to Existing Group
        </Button>
        <Button
          variant="contained"
          size="small"
          startIcon={<GroupIcon />}
          onClick={handleBatchAddToGroup}
        >
          Create New Group
        </Button>
        <Button
          variant="contained"
          size="small"
          startIcon={<DeleteIcon />}
          color="error"
          onClick={handleBatchDelete}
        >
          Delete Selected
        </Button>
      </Box>
    )
  );

  const renderGroupDialogContent = () => (
    <DialogContent>
      {groupMode === 'new' ? (
        <TextField
          autoFocus
          margin="dense"
          label="Group Name"
          fullWidth
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          disabled={creatingGroup}
        />
      ) : (
        <MuiFormControl fullWidth margin="dense">
          <MuiInputLabel>Select Group</MuiInputLabel>
          <MuiSelect
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            disabled={creatingGroup}
          >
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name} ({group.memberIds.length} members)
              </MenuItem>
            ))}
          </MuiSelect>
        </MuiFormControl>
      )}

      {selectedMembers.length > 0 && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
            Selected {getMemberTerm(true)}:
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1,
            p: 1,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            minHeight: '50px'
          }}>
            {selectedMembers.map((memberId) => {
              const member = members.find(m => (m.userKey || m.tempUserId) === memberId);
              return (
                <Chip
                  key={memberId}
                  label={member?.name || memberId}
                  onDelete={() => setSelectedMembers(prev => prev.filter(id => id !== memberId))}
                  size="small"
                />
              );
            })}
          </Box>
        </>
      )}

      {groupMode === 'new' && (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          You can create an empty group now and add {getMemberTerm(true).toLowerCase()} later.
        </Typography>
      )}
    </DialogContent>
  );

  const renderGroupDialogActions = () => (
    <DialogActions>
      <Button onClick={() => setIsCreateGroupOpen(false)} disabled={creatingGroup}>
        Cancel
      </Button>
      <Button 
        onClick={groupMode === 'new' ? handleCreateGroup : handleAddToExistingGroup}
        variant="contained"
        disabled={creatingGroup || !groupName || (groupMode === 'existing' && selectedMembers.length === 0)}
        sx={{ minWidth: 120 }}
      >
        {creatingGroup ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CircularProgress size={20} color="inherit" />
            <span>Creating...</span>
          </Box>
        ) : groupMode === 'new' ? (
          'Create Group'
        ) : (
          'Add to Group'
        )}
      </Button>
    </DialogActions>
  );

  const renderMemberListItem = (member: IMember) => {
    const memberId = member.userKey || member.tempUserId || '';
    
    return (
      <ListItem key={memberId}>
        <Checkbox
          edge="start"
          checked={selectedStudents.includes(memberId)}
          onChange={() => handleSelectStudent(memberId)}
        />
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {member.animalEmoji && (
                <Typography component="span" variant="h6">
                  {member.animalEmoji}
                </Typography>
              )}
              <Typography component="span">{member.name}</Typography>
            </Box>
          }
          secondary={
            <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography component="span" color="text.secondary">
                {member.email || `Anonymous ${getMemberTerm()}`}
              </Typography>
              <Chip
                size="small"
                label={member.role}
              />
              {member.registered && (
                <Chip
                  size="small"
                  label="Anonymous"
                  color="secondary"
                />
              )}
            </Box>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            onClick={() => handleRemoveMember(memberId)}
            disabled={removingMember === memberId}
          >
            {removingMember === memberId ? (
              <CircularProgress size={24} />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const toggleGroupCollapse = (groupId: string) => {
    setCollapsedGroups(prev => {
      const newSet = new Set(prev);
      if (newSet.has(groupId)) {
        newSet.delete(groupId);
      } else {
        newSet.add(groupId);
      }
      return newSet;
    });
  };

  const toggleQRGroupCollapse = (groupId: string) => {
    setCollapsedQRGroups(prev => {
      const newSet = new Set(prev);
      if (newSet.has(groupId)) {
        newSet.delete(groupId);
      } else {
        newSet.add(groupId);
      }
      return newSet;
    });
  };

  const handleSelectAllInGroup = (groupMembers: IMember[]) => {
    const memberIds = groupMembers.map(m => m.userKey || m.tempUserId || '').filter(Boolean);
    const allSelected = memberIds.every(id => selectedStudents.includes(id));
    
    if (allSelected) {
      setSelectedStudents(prev => prev.filter(id => !memberIds.includes(id)));
    } else {
      setSelectedStudents(prev => [...new Set([...prev, ...memberIds])]);
    }
  };

  const renderMemberList = () => (
    <>
      {generatedCodes.length > 0 && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Generated Access Codes</Typography>
              <Box>
                <Button
                  startIcon={<QrCodeIcon />}
                  onClick={downloadAllQRCodes}
                  sx={{ mr: 1 }}
                >
                  Download All QR Codes
                </Button>
                <Button
                  startIcon={<DownloadIcon />}
                  onClick={downloadAllCodes}
                >
                  Download CSV
                </Button>
              </Box>
            </Box>

            {renderQRCodesByGroup()}
          </CardContent>
        </Card>
      )}

      <MemberManagement
        organisationId={currentOrganisation?.id || ''}
        organisationType={currentOrganisation?.type}
        adminId={currentOrganisation?.adminId}
        members={members}
        groups={groups}
        onMembersChange={loadData}
        showAdminGroups={false}
      />
    </>
  );

  const renderGenerateCodesDialog = () => (
    <Dialog 
      open={isGenerateCodesOpen} 
      onClose={() => !generatingCodes && setIsGenerateCodesOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Generate {getMemberTerm()} Access Codes</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Generate access codes for {getMemberTerm(true).toLowerCase()} to join. Select a group and specify how many to generate.
        </Typography>
        
        {groups.length === 0 ? (
          <Typography color="error">
            Please create a group first before generating {getMemberTerm(true).toLowerCase()}.
          </Typography>
        ) : (
          <>
            <MuiFormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <MuiInputLabel>Select Group</MuiInputLabel>
              <MuiSelect
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
                disabled={generatingCodes}
              >
                {groups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </MuiSelect>
            </MuiFormControl>

            <TextField
              label={`Number of ${getMemberTerm(true)}`}
              type="number"
              value={studentCount}
              onChange={(e) => setStudentCount(Math.max(1, Math.min(50, parseInt(e.target.value) || 1)))}
              fullWidth
              helperText={`Maximum 50 ${getMemberTerm(true).toLowerCase()} at once`}
              disabled={generatingCodes}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsGenerateCodesOpen(false)} disabled={generatingCodes}>
          Cancel
        </Button>
        <Button 
          onClick={handleGenerateMemberCodes}
          variant="contained"
          disabled={generatingCodes || !selectedGroup || groups.length === 0}
          sx={{ minWidth: 120 }}
        >
          {generatingCodes ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} color="inherit" />
              <span>Generating...</span>
            </Box>
          ) : (
            'Generate Codes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderQRCodesByGroup = () => {
    // Group codes by their group
    const codesByGroup = new Map<string, MemberCode[]>();
    
    // Initialize with existing groups
    groups.forEach(group => {
      codesByGroup.set(group.id, []);
    });
    codesByGroup.set('ungrouped', []); // Add ungrouped last

    // Sort codes into groups
    generatedCodes.forEach(code => {
      const groupId = code.groupId || 'ungrouped';
      if (!codesByGroup.has(groupId)) {
        codesByGroup.set(groupId, []);
      }
      codesByGroup.get(groupId)?.push(code);
    });

    // Filter out empty groups and sort them
    const sortedGroups = Array.from(codesByGroup.entries())
      .filter(([_, codes]) => codes.length > 0)
      .sort((a, b) => {
        if (a[0] === 'ungrouped') return 1;
        if (b[0] === 'ungrouped') return -1;
        return 0;
      });

    return (
      <Grid container spacing={2}>
        {sortedGroups.map(([groupId, codes]) => {
          const group = groups.find(g => g.id === groupId);
          const isCollapsed = collapsedQRGroups.has(groupId);

          return (
            <Grid item xs={12} key={groupId}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                  backgroundColor: 'action.hover',
                  p: 1,
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: 'action.selected'
                  }
                }}
              >
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    flex: 1, 
                    cursor: 'pointer',
                    minWidth: 0
                  }} 
                  onClick={() => toggleQRGroupCollapse(groupId)}
                >
                  <IconButton 
                    size="small" 
                    sx={{ 
                      mr: 1,
                      p: 0.5,
                      flexShrink: 0,
                      width: 28,
                      height: 28
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleQRGroupCollapse(groupId);
                    }}
                  >
                    {isCollapsed ? <AddIcon fontSize="small" /> : <RemoveIcon fontSize="small" />}
                  </IconButton>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      fontWeight: 500,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      minWidth: 0
                    }}
                  >
                    {group?.name || 'Ungrouped'} ({codes.length} codes)
                  </Typography>
                </Box>
                <Box sx={{ 
                  display: 'flex', 
                  gap: 1,
                  ml: 2,
                  flexShrink: 0
                }}>
                  <Button
                    size="small"
                    startIcon={<QrCodeIcon />}
                    onClick={async (e) => {
                      e.stopPropagation();
                      for (const code of codes) {
                        if (code.link && code.identifier) {
                          const qrWithText = await generateQRWithText(code.link, code.identifier);
                          const link = document.createElement('a');
                          link.download = `${code.identifier.toLowerCase()}-qr.png`;
                          link.href = qrWithText;
                          link.click();
                        }
                      }
                    }}
                  >
                    Download QRs
                  </Button>
                  <Button
                    size="small"
                    startIcon={<DownloadIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      const groupCodes = codes.map(code => 
                        `${code.identifier},${code.animalEmoji},${code.code},${code.link}`
                      ).join("\n");
                      const csvContent = "data:text/csv;charset=utf-8," + 
                        "Identifier,Animal,Access Code,Join Link\n" + groupCodes;
                      const link = document.createElement('a');
                      link.href = encodeURI(csvContent);
                      link.download = `${group?.name || 'ungrouped'}-access-codes.csv`;
                      link.click();
                    }}
                  >
                    Download CSV
                  </Button>
                </Box>
              </Box>
              <Collapse in={!isCollapsed}>
                <Grid container spacing={2}>
                  {codes.map(code => renderCodeCard(code))}
                </Grid>
              </Collapse>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const handleCleanupTestData = async () => {
    if (!currentOrganisation || !window.confirm('Are you sure? This will delete ALL members, generated codes, and group assignments for this organisation.')) {
      return;
    }

    try {
      setLoading(true);
      // First, get all members to identify which ones to remove
      const orgMembers = await OrganisationManager.getOrganisationMembers(currentOrganisation.id);
      
      // Delete all members that belong to this organization (except admin and superAdmin)
      const memberDeletions = orgMembers
        .filter(member => member.role !== 'organisationAdmin' && member.role !== 'superAdmin')
        .map(member => {
          const memberId = member.userKey || member.tempUserId;
          if (memberId) {
            return OrganisationManager.removeMember(currentOrganisation.id, memberId);
          }
          return Promise.resolve();
        });

      // Delete all generated codes for this organization
      await OrganisationManager.deleteAllGeneratedCodes(currentOrganisation.id);
      
      // Delete all groups for this organization
      const groupDeletions = groups.map(group => 
        OrganisationManager.deleteGroup(currentOrganisation.id, group.id)
      );

      // Wait for all deletions to complete
      await Promise.all([...memberDeletions, ...groupDeletions]);

      // Reset local state
      setMembers([]);
      setGroups([]);
      setGeneratedCodes([]);
      setSelectedStudents([]);
      setSelectedMembers([]);
      
      // Reload data to ensure everything is in sync
      await loadData();
    } catch (err) {
      console.error('Error cleaning up test data:', err);
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100vh',
      marginTop: '80px'
    }}>
      <AdminNav 
        showProjectDropdown={false}
        imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
      />
      <Box sx={{ p: 3, flexGrow: 1, overflow: 'auto' }}>
        <Paper elevation={3} sx={{ p: 4, maxWidth: 1200, mx: "auto" }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 3 
          }}>
            <Typography variant="h4">
              {currentOrganisation?.name} - Moderator Dashboard
            </Typography>
          </Box>

          <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
            <Tab label={`${getMemberTerm(true)}`} />
            <Tab label="Progress" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            {renderMemberList()}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Typography variant="h6" gutterBottom>
              {getMemberTerm()} Progress
            </Typography>
            {/* Progress tracking will go here */}
          </TabPanel>

          {renderGenerateCodesDialog()}

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Paper>
      </Box>
      {process.env.NODE_ENV === 'development' && (
        <Button
          variant="outlined"
          color="error"
          onClick={handleCleanupTestData}
          sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}
        >
          🧹 Clean Test Data
        </Button>
      )}
    </Box>
  );
});

export default ModeratorDashboard; 