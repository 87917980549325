import { Location } from "./Location"

// Define the PinOrder interface with expanded branching information
export interface PinOrder {
    id: number;         // Represents the top-level hierarchy (e.g., 1)
    branch: number;     // Full branch number (e.g., 1.1)
    mainBranch: number; // Main branch number (e.g., 1)
    subBranch: number;  // Sub-branch number (e.g., 1)
    branchLevel: number;// Depth in branch tree (0 for root, 1 for first level, etc.)
}

// Helper function to create pin orders
export const createPinOrder = (id: number, branch: number): PinOrder => {
    const mainBranch = Math.floor(branch);
    const subBranch = Math.round((branch % 1) * 10);
    const branchLevel = mainBranch;
    
    return {
        id,
        branch,
        mainBranch,
        subBranch,
        branchLevel
    };
};

export interface IPin {
    albumKey: string | null;
    pinDescription: string | null;
    isAvailable: boolean;
    location: Location;
    name: string | null;
    pinIcon: string;
    pinKey: string;
    pinType: string | null;
    project: string | null;
    tags?: {
        locationTags: string[];
    };
    isCollectionLogicEnabled: boolean;
    collectionLogic: string | null;
    pinOrder: PinOrder | null;
    branchParentPinKey: string | null;
}

// Helper functions for pin branching logic
export const isBranchingPin = (pin: IPin): boolean => {
    return pin.collectionLogic === "2" && pin.pinOrder !== null;
};

export const isRootBranchingPin = (pin: IPin): boolean => {
    return isBranchingPin(pin) && 
           pin.pinOrder?.branch === 0 && 
           pin.pinOrder?.id === 1;
};

export const isFirstLevelBranch = (pin: IPin): boolean => {
    return isBranchingPin(pin) && 
           pin.pinOrder?.mainBranch === 1;
};

export const isSameBranchLevel = (pin: IPin, otherPin: IPin): boolean => {
    return isBranchingPin(pin) && 
           isBranchingPin(otherPin) && 
           pin.pinOrder?.mainBranch === otherPin.pinOrder?.mainBranch;
};

export const isNextBranchLevel = (pin: IPin, currentBranch: number): boolean => {
    return isBranchingPin(pin) && 
           pin.pinOrder?.mainBranch === Math.floor(currentBranch) + 1;
};

// Example usage in comments:
/*
Root pin:
{
    ...pinData,
    pinOrder: createPinOrder(1, 0)  // id: 1, branch: 0, mainBranch: 0, subBranch: 0, branchLevel: 0
}

First level branches:
{
    ...pinData,
    pinOrder: createPinOrder(1, 1.0)  // id: 1, branch: 1.0, mainBranch: 1, subBranch: 0, branchLevel: 1
    pinOrder: createPinOrder(1, 1.1)  // id: 1, branch: 1.1, mainBranch: 1, subBranch: 1, branchLevel: 1
    pinOrder: createPinOrder(1, 1.2)  // id: 1, branch: 1.2, mainBranch: 1, subBranch: 2, branchLevel: 1
}

Second level branches:
{
    ...pinData,
    pinOrder: createPinOrder(1, 2.0)  // id: 1, branch: 2.0, mainBranch: 2, subBranch: 0, branchLevel: 2
    pinOrder: createPinOrder(1, 2.1)  // id: 1, branch: 2.1, mainBranch: 2, subBranch: 1, branchLevel: 2
}
*/


