import React from 'react';
import { Routes, Route, useNavigationType, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import {
  LoginScreen,
  PinListView,
  TagsView,
  MapView,
  WanderView,
  RecordingFormConfigurator,
  InviteLanding,
  TestInviteLanding,
  AuthorDashboard,
  ProjectView,
  AdminMessageView,
  AuthorClaimProcess,
  AuthorLoginScreen,
  UserAccessView,
  BranchView,
  OrgRegistrationScreen,
  OrgDashboardScreen,
  JoinOrgScreen,
  ModeratorDashboard,
  FormsView,
  ModerationView,
  ArtistsView,
  OrphanedFilesView
} from '../pages';
import { ProjectProvider } from '../contexts/ProjectContext';
import { ErrorBoundary } from '../utilities/bugsnag';

const AppRoutes: React.FC = () => {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const handleConfigChange = (newConfig: Record<string, string | boolean>) => {
    console.log("Config changed:", newConfig);
  };

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/loginscreen":
        title = "";
        metaDescription = "";
        break;
      case "/pin-list-view":
        title = "";
        metaDescription = "";
        break;
      case "/map-view":
        title = "";
        metaDescription = "";
        break;
      case "/tags-view":
        title = "";
        metaDescription = "";
        break;
      case "/project-view":
        title = "";
        metaDescription = "";
        break;
      case "/message-view":
        title = "";
        metaDescription = "";
        break;
      case "/wanders-view":
        title = "";
        metaDescription = "";
        break;
      case "/user-access-view":
        title = "";
        metaDescription = "";
        break;
      case "/form-builder-view":
        title = "";
        metaDescription = "";
        break;
      case "/forms-view":
        title = "";
        metaDescription = "";
        break;
      case "/author-dashboard":
        title = "";
        metaDescription = "";
        break;
      case "/author-login":
        title = "";
        metaDescription = "";
        break;
      case "/author-claim-process":
        title = "";
        metaDescription = "";
        break;
      case "/invite/:inviteId":
        title = "Invite";
        metaDescription = "You've been invited to a project.";
        break;
      case "/test-invite":
        title = "Test Invite";
        metaDescription = "A test invite link.";
        break;
      case "/branch-view":
        title = "";
        metaDescription = "";
        break;
      case "/register-org":
        title = "Register Organisation";
        metaDescription = "Register your organisation";
        break;
      case "/org-dashboard":
        title = "Organisation Dashboard";
        metaDescription = "Manage your organisation";
        break;
      case "/join-org/:accessCode":
        title = "Join Organisation";
        metaDescription = "Join an organisation with your access code";
        break;
      case "/moderator-dashboard":
        title = "Moderator Dashboard";
        metaDescription = "Manage your organisation's members and groups";
        break;
      case "/moderation-view":
        title = "Content Moderation";
        metaDescription = "Review and moderate content that requires attention";
        break;
      case "/artists-view":
        title = "Artist Profiles Management";
        metaDescription = "Manage and merge artist profiles";
        break;
      case "/orphaned-files":
        title = "Orphaned Files";
        metaDescription = "Manage orphaned author images";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <ErrorBoundary>
      <ProjectProvider>
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/loginscreen" element={<LoginScreen />} />
          <Route path="/register-org" element={<OrgRegistrationScreen />} />
          <Route path="/org-dashboard" element={<OrgDashboardScreen />} />
          <Route path="/join-org/:accessCode" element={<JoinOrgScreen />} />
          <Route path="/moderator-dashboard" element={<ModeratorDashboard />} />
          <Route path="/pin-list-view" element={<PinListView />} />
          <Route path="/map-view" element={<MapView />} />
          <Route path="/tags-view" element={<TagsView />} />
          <Route path="/project-view" element={<ProjectView />} />
          <Route path="/message-view" element={<AdminMessageView />} />
          <Route path="/wanders-view" element={<WanderView />} />
          <Route path="/user-access-view" element={<UserAccessView />} />
          <Route path="/moderation-view" element={<ModerationView />} />
          <Route path="/artists-view" element={<ArtistsView />} />
          <Route path="/orphaned-files" element={<OrphanedFilesView />} />
          <Route path="/form-builder-view" element={<RecordingFormConfigurator onChange={handleConfigChange} />} />
          <Route path="/forms-view/:project_id" element={<FormsView />} />
          <Route path="/invite/:inviteId" element={<InviteLanding />} />
          <Route path="/test-invite" element={<TestInviteLanding />} />
          <Route path="/author-dashboard" element={<AuthorDashboard />} />
          <Route path="/author-login" element={<AuthorLoginScreen />} />
          <Route path="/author-claim-process" element={<AuthorClaimProcess />} />
          <Route path="/branch-view" element={<BranchView />} />
          <Route path="*" element={<div>Page not found</div>} />
        </Routes>
      </ProjectProvider>
    </ErrorBoundary>
  );
};

export default AppRoutes; 