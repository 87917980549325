import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { OrganisationManager } from '../models/OrganisationManager';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import userStore from '../stores/UserStore';

const JoinOrgScreen: React.FC = observer(() => {
  const { accessCode } = useParams<{ accessCode: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(true);
  const [orgName, setOrgName] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);

  useEffect(() => {
    verifyAccessCode();
  }, [accessCode]);

  const verifyAccessCode = async () => {
    if (!accessCode) {
      setError('Invalid access code');
      setLoading(false);
      return;
    }

    try {
      const accessCodeDoc = await OrganisationManager.verifyAccessCode(accessCode);
      if (!accessCodeDoc) {
        setError('Invalid or expired access code');
        setLoading(false);
        return;
      }

      const org = await OrganisationManager.getOrganisation(accessCodeDoc.organisationId);
      setOrgName(org.name);
      setRole(accessCodeDoc.role);
    } catch (err) {
      setError('Failed to verify access code');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!accessCode) return;

    if (isSignUp && password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    setSubmitting(true);
    setError(null);

    try {
      const auth = getAuth();
      let userCredential;

      if (isSignUp) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }

      // Add user to organization
      await OrganisationManager.addMemberWithAccessCode(accessCode, userCredential.user.uid);
      
      // Redirect to appropriate dashboard based on role
      if (role === 'teacher' || role === 'moderator') {
        navigate('/moderator-dashboard');
      } else {
        navigate('/member-dashboard');
      }
    } catch (err) {
      if (err instanceof Error) {
        if (err.message.includes('email-already-in-use')) {
          setError('An account with this email already exists. Please sign in instead.');
          setIsSignUp(false);
        } else {
          setError(err.message);
        }
      } else {
        setError('An error occurred during authentication');
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%' }}>
        <Typography variant="h4" gutterBottom align="center">
          Join {orgName}
        </Typography>
        
        <Typography variant="subtitle1" gutterBottom align="center">
          You've been invited as a {role}
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={submitting}
          />

          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={submitting}
          />

          {isSignUp && (
            <TextField
              fullWidth
              margin="normal"
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              disabled={submitting}
            />
          )}

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={submitting}
          >
            {submitting ? <CircularProgress size={24} /> : (isSignUp ? 'Sign Up' : 'Sign In')}
          </Button>

          <Button
            fullWidth
            sx={{ mt: 1 }}
            onClick={() => setIsSignUp(!isSignUp)}
            disabled={submitting}
          >
            {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
          </Button>
        </form>
      </Paper>
    </Box>
  );
});

export default JoinOrgScreen; 