import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, List, ListItem, Button, CircularProgress, Tabs, Tab, Snackbar, Alert } from '@mui/material';
import { storage } from '../firebase/firebase';
import { ref, deleteObject } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AdminNav from '../components/AdminNav';
import { AdminButtonRow } from '../components/AdminButtonRow';
import userStore from '../stores/UserStore';
import { useNavigate } from 'react-router-dom';
import { StorageManager, StorageFile } from '../models/StorageManager';

interface DeletedBatch {
  files: StorageFile[];
  paths: string[];
  timestamp: number;
}

const OrphanedFilesView: React.FC = () => {
  const [orphanedFiles, setOrphanedFiles] = useState<StorageFile[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<'author' | 'recording' | 'pin' | 'project' | 'message' | 'qr'>('author');
  const [error, setError] = useState<string | null>(null);
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [lastDeletedBatch, setLastDeletedBatch] = useState<DeletedBatch | null>(null);
  const [showUndoSnackbar, setShowUndoSnackbar] = useState(false);
  const userRole = userStore.currentUser?.role || 'user';
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);

  const handleAdminMessageViewClick = () => {
    navigate("/message-view", { state: { userRole } });
  };

  const handleProjectViewClick = () => {
    navigate("/project-view", { state: { userRole } });
  };

  const handleWandersViewClick = () => {
    navigate("/wanders-view", { state: { userRole } });
  };

  const handleUserAccessViewClick = () => {
    navigate("/user-access-view", { state: { userRole } });
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthInitialized(true);
      if (!user) {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (!isAuthInitialized) {
      return;
    }

    const auth = getAuth();
    if (!auth.currentUser) {
      navigate('/login');
      return;
    }
    fetchOrphanedFiles();
  }, [activeTab, isAuthInitialized]);

  const fetchOrphanedFiles = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const auth = getAuth();
      
      if (!auth.currentUser) {
        console.error('No authenticated user');
        setError('You must be logged in to view orphaned files');
        return;
      }

      let files: StorageFile[] = [];

      switch (activeTab) {
        case 'author':
          try {
            files = await StorageManager.getOrphanedAuthorImages();
          } catch (error) {
            console.error('Error getting orphaned author images:', error);
            setError('Error getting orphaned author images: ' + (error as Error).message);
          }
          break;

        case 'recording':
          try {
            files = await StorageManager.getOrphanedRecordings();
          } catch (error) {
            console.error('Error getting orphaned recordings:', error);
            setError('Error getting orphaned recordings: ' + (error as Error).message);
          }
          break;

        case 'pin':
          try {
            files = await StorageManager.getOrphanedIcons();
          } catch (error) {
            console.error('Error getting orphaned icons:', error);
            setError('Error getting orphaned icons: ' + (error as Error).message);
          }
          break;

        case 'qr':
          try {
            files = await StorageManager.getOrphanedQRCodes();
          } catch (error) {
            console.error('Error getting orphaned QR codes:', error);
            setError('Error getting orphaned QR codes: ' + (error as Error).message);
          }
          break;

        // ... other cases remain TODO ...
      }

      setOrphanedFiles(files);
      setIsLoading(false);
    } catch (err) {
      const error = err as Error;
      console.error('Error fetching orphaned files:', error);
      setError(error.message || 'An error occurred while fetching orphaned files');
      setIsLoading(false);
    }
  };

  const handleFileSelection = (path: string) => {
    setSelectedFiles(prev => 
      prev.includes(path)
        ? prev.filter(p => p !== path)
        : [...prev, path]
    );
  };

  const handleDeleteSelected = async () => {
    if (!selectedFiles.length) return;

    try {
      setIsLoading(true);
      // Store the files being deleted for potential undo
      const deletedFiles = orphanedFiles.filter(file => selectedFiles.includes(file.path));
      const deletedBatch: DeletedBatch = {
        files: deletedFiles,
        paths: selectedFiles,
        timestamp: Date.now()
      };

      await StorageManager.deleteFiles(deletedFiles);
      
      // Update the UI immediately
      setOrphanedFiles(prev => prev.filter(file => !selectedFiles.includes(file.path)));
      setSelectedFiles([]);
      setSelectAll(false);
      
      // Store the deleted batch and show undo option
      setLastDeletedBatch(deletedBatch);
      setShowUndoSnackbar(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting files:', error);
      setError('Error deleting files: ' + (error as Error).message);
      setIsLoading(false);
    }
  };

  const handleUndo = async () => {
    if (!lastDeletedBatch) return;

    try {
      setIsLoading(true);
      // Add the files back to the list
      setOrphanedFiles(prev => [...prev, ...lastDeletedBatch.files]);
      setShowUndoSnackbar(false);
      setLastDeletedBatch(null);
      setIsLoading(false);
    } catch (error) {
      console.error('Error undoing delete:', error);
      setError('Error undoing delete: ' + (error as Error).message);
      setIsLoading(false);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(orphanedFiles.map(file => file.path));
    }
    setSelectAll(!selectAll);
  };

  return (
    <Box>
      <AdminNav showProjectDropdown={false} />
      <Box sx={{ marginTop: '64px' }}>
        <AdminButtonRow 
          defaultView="orphaned-files" 
          userRole={userRole}
          handleAdminMessageViewClick={handleAdminMessageViewClick}
          handleProjectViewClick={handleProjectViewClick}
          handleWandersViewClick={handleWandersViewClick}
          handleUserAccessViewClick={handleUserAccessViewClick}
        />
        <Box sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            Orphaned Files Manager
          </Typography>

          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              Error: {error}
            </Typography>
          )}

          <Tabs 
            value={activeTab} 
            onChange={(_, newValue) => setActiveTab(newValue)}
            sx={{ mb: 3 }}
          >
            <Tab label="Author Images" value="author" />
            <Tab label="Recordings" value="recording" />
            <Tab label="Pin Icons" value="pin" />
            <Tab label="Project Icons" value="project" />
            <Tab label="QR Codes" value="qr" />
            <Tab label="Messages" value="message" />
          </Tabs>

          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="body2" color="text.secondary">
              {orphanedFiles.length} orphaned files found in {activeTab} directory
            </Typography>
            {orphanedFiles.length > 0 && (
              <>
                <Button
                  variant="outlined"
                  onClick={handleSelectAll}
                  sx={{ ml: 'auto' }}
                >
                  {selectAll ? 'Deselect All' : 'Select All'}
                </Button>
                {selectedFiles.length > 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteSelected}
                  >
                    Delete Selected ({selectedFiles.length})
                  </Button>
                )}
              </>
            )}
          </Box>

          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {orphanedFiles.map((file) => (
                <Paper 
                  key={file.path} 
                  elevation={3}
                  sx={{ mb: 2 }}
                >
                  <ListItem>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'flex-start', 
                      gap: 2,
                      width: '100%',
                      position: 'relative'
                    }}>
                      <Box sx={{ 
                        position: 'absolute', 
                        left: 0, 
                        top: '50%', 
                        transform: 'translateY(-50%)',
                        width: 24,
                        height: 24,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <input
                          type="checkbox"
                          checked={selectedFiles.includes(file.path)}
                          onChange={() => handleFileSelection(file.path)}
                        />
                      </Box>
                      <Box sx={{ ml: 4, display: 'flex', gap: 2, width: '100%' }}>
                        {file.type === 'author' && (
                          <img
                            src={file.url}
                            alt={file.path}
                            style={{ 
                              width: '100px',
                              height: '100px',
                              objectFit: 'cover'
                            }}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = '/assets/icons/user.svg';
                            }}
                          />
                        )}
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>{file.path}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            Type: {file.type}
                            {file.isOrphanedDocument && ' (Orphaned Document)'}
                          </Typography>
                          {file.size && (
                            <Typography variant="body2" color="text.secondary">
                              Size: {(file.size / 1024).toFixed(2)} KB
                            </Typography>
                          )}
                          <Typography variant="body2" color="text.secondary">
                            Created: {new Date(file.timeCreated || '').toLocaleString()}
                          </Typography>
                          {file.recordingKey && (
                            <Typography variant="body2" color="text.secondary">
                              Recording Key: {file.recordingKey}
                            </Typography>
                          )}
                          {file.errors && file.errors.length > 0 && (
                            <Box sx={{ mt: 1 }}>
                              <Typography variant="body2" color="error" sx={{ fontWeight: 'bold' }}>
                                Reasons for being orphaned:
                              </Typography>
                              <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                                {file.errors.map((error, index) => (
                                  <li key={index}>
                                    <Typography variant="body2" color="error">
                                      {error}
                                    </Typography>
                                  </li>
                                ))}
                              </ul>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                </Paper>
              ))}
            </List>
          )}
        </Box>
      </Box>
      <Snackbar 
        open={showUndoSnackbar} 
        autoHideDuration={6000} 
        onClose={() => setShowUndoSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowUndoSnackbar(false)} 
          severity="success" 
          action={
            <Button color="inherit" size="small" onClick={handleUndo}>
              UNDO
            </Button>
          }
        >
          {lastDeletedBatch?.paths.length} files deleted successfully
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OrphanedFilesView; 