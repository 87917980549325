export const branchColors = [
    '#4CAF50', // Green
    '#2196F3', // Blue
    '#F44336', // Red
    '#FF9800', // Orange
    '#9C27B0', // Purple
    '#00BCD4', // Cyan
    '#FFEB3B', // Yellow
    '#795548', // Brown
  ];
  
  export const getBranchColor = (branchOrder: number | undefined): string => {
    if (branchOrder === undefined) return '#757575'; // Default gray
    return branchColors[Math.floor(branchOrder) % branchColors.length];
  };