import React, { FunctionComponent, useEffect, useState, useMemo, useRef, ChangeEvent } from "react";
import AuthorInformationFields from "./AuthorInformationFields";
import RecordingUploadFields from "./RecordingUploadFields";
import RecordingInformationFields from "./RecordingInformationFields";
import FormButtons from './FormButtons';
import { TextField, Button, InputAdornment, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AuthorManager } from '../models';
import { ContentRating } from '../types';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Move styled components outside of the main component
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    borderRadius: '4px 0 0 4px',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '0 4px 4px 0',
  height: '56px',
  minWidth: '56px',
  maxWidth: '150px',
  padding: '0 16px',
}));

type RecordingFormProps = {
  sourceView: 'PinList' | 'WanderView' | 'Form' | 'SideBar';
  config: {
    locationInfo: boolean;
    authorInfo: boolean;
    recordingInfo: boolean;
    uploadFields: boolean;
  };
  authorDetails: {
    authorName: string;
    authorBio: string;
    authorWebsite: string;
    authorTags: string[];
    authorImageUrl: string;
    userKey: string;
  };
  recordingDetails: {
    recordingURL: string;
    fileSize: number | undefined;
    fileName: string | undefined;
    transcription: string;
    title: string;
    description: string;
    narrator: string;
    subjectTags: string[];
    genreTags: string[];
    whereQRFind?: string;
    selectedButton: 'mp3' | 'url' | 'record';
    contentRating?: ContentRating;
  };
  locationDescription: string;
  pinType?: string;
  isUploading: boolean;
  onAuthorDetailsChange: (details: Partial<{
    authorName: string;
    authorBio: string;
    authorWebsite: string;
    authorTags: string[];
    imageFile?: File | null;
  }>) => void;
  onRecordingDetailsChange: (details: Partial<RecordingFormProps['recordingDetails']>) => void;
  onLocationDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onSave: () => void;
  onDelete: () => void;
  showDelete: boolean;
  saveSuccess: boolean;
  setSaveSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  resetForm: () => void;
  setRetainArtistDetails: (retain: boolean) => void;
  availableGenreTags: string[];
  setAuthorTags: React.Dispatch<React.SetStateAction<string[]>>;
  setSubjectTags: React.Dispatch<React.SetStateAction<string[]>>;
  setGenreTags: React.Dispatch<React.SetStateAction<string[]>>;
  projectIconUrl: string;
  onMarkerPositionChange: (position: L.LatLng | null) => void;
  error?: string; // Add error prop
};

const RecordingForm: FunctionComponent<RecordingFormProps> = ({
  sourceView,
  config,
  authorDetails,
  recordingDetails,
  locationDescription,
  pinType,
  isUploading,
  onAuthorDetailsChange,
  onRecordingDetailsChange,
  onLocationDescriptionChange,
  onCancel,
  onSave,
  onDelete,
  showDelete,
  saveSuccess,
  setSaveSuccess,
  resetForm,
  setRetainArtistDetails,
  availableGenreTags,
  setAuthorTags,
  setSubjectTags,
  setGenreTags,
  projectIconUrl,
  onMarkerPositionChange,
  error, // Add error prop
}) => {
  const prevRecordingDetailsRef = useRef(recordingDetails);

  useEffect(() => {
    if (JSON.stringify(prevRecordingDetailsRef.current) !== JSON.stringify(recordingDetails)) {
      prevRecordingDetailsRef.current = recordingDetails;
    }
  }, [recordingDetails]);

  const prevGenreTagsRef = useRef<string[]>(recordingDetails.genreTags);

  useEffect(() => {
    if (JSON.stringify(prevGenreTagsRef.current) !== JSON.stringify(recordingDetails.genreTags)) {
      prevGenreTagsRef.current = recordingDetails.genreTags;
    }
  }, [recordingDetails.genreTags]);


  const handleAuthorTagsChange = (newTags: string[] | ((prevTags: string[]) => string[])) => {
    setAuthorTags(newTags);
  };

  const handleSubjectTagsChange = (newTags: string[] | ((prevTags: string[]) => string[])) => {
    setSubjectTags(newTags);
  };

  const handleGenreTagsChange = (newTags: string[] | ((prevTags: string[]) => string[])) => {
    setGenreTags(newTags);
  };

  const handleSave = () => {
    onSave();
  };

  const navigate = useNavigate();

  const handleGoToAuthorDashboard = async () => {
    if (sourceView === 'Form') {
      // Check for potential authors
      const potentialAuthors = await AuthorManager.getAuthorsByName(authorDetails.authorName);
      
      if (potentialAuthors.length > 0) {
        // Navigate to AuthorClaimProcess if potential authors found
        navigate('/author-claim-process', { 
          state: { 
            name: authorDetails.authorName, 
            userKey: authorDetails.userKey // Now this line should work
          } 
        });
      } else {
        // Navigate directly to author dashboard if no potential authors
        navigate('/author-dashboard');
      }
    }
  };

  const memoizedAuthorDetails = useMemo(() => authorDetails, [
    authorDetails.authorName,
    authorDetails.authorBio,
    authorDetails.authorWebsite,
    authorDetails.authorTags.join(','),
    authorDetails.authorImageUrl
  ]);

  const handleLocationDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    onLocationDescriptionChange(event);
  };

  const [mapVisible, setMapVisible] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<L.LatLng | null>(null);
  const mapRef = useRef<L.Map | null>(null);
  const markerRef = useRef<L.Marker | null>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const handleSearchLocation = async () => {
    if (locationDescription.length >= 5) {
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(locationDescription)}`);
        const data = await response.json();
        if (data && data.length > 0) {
          const { lat, lon } = data[0];
          setMapVisible(true);
          // Instead of calling initializeMap directly, we'll set the position
          setMarkerPosition(new L.LatLng(parseFloat(lat), parseFloat(lon)));
        }
      } catch (error) {
        console.error('Error geocoding address:', error);
      }
    }
  };

  useEffect(() => {
    if (mapVisible && markerPosition && mapContainerRef.current) {
      // Initialize map when mapVisible is true and we have a position
      if (!mapRef.current) {
        mapRef.current = L.map(mapContainerRef.current).setView([markerPosition.lat, markerPosition.lng], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; OpenStreetMap contributors'
        }).addTo(mapRef.current);
      } else {
        mapRef.current.setView([markerPosition.lat, markerPosition.lng], 13);
      }

      if (markerRef.current) {
        markerRef.current.setLatLng(markerPosition);
      } else {
        const icon = L.icon({
          iconUrl: projectIconUrl,
          iconSize: [60, 60],
          iconAnchor: [20, 40],
          popupAnchor: [0, -40]
        });
        markerRef.current = L.marker(markerPosition, { icon, draggable: true }).addTo(mapRef.current);
        markerRef.current.on('dragend', handleMarkerDrag);
      }

      onMarkerPositionChange(markerPosition);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
        markerRef.current = null;
      }
    };
  }, [mapVisible, markerPosition]);

  const handleMarkerDrag = (event: L.LeafletEvent) => {
    const marker = event.target as L.Marker;
    const position = marker.getLatLng();
    setMarkerPosition(position);
    onMarkerPositionChange(position);
  };


  return (
    <div className={sourceView === 'WanderView' ? "edit-wander" : (sourceView === 'Form' ? "edit-recording form-view" : "edit-recording")}>
      {saveSuccess ? (
        <div className="thank-you-message">
          <p>Thank you for your submission!</p>
          <p>Please close this screen or choose an option below:</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <button 
              style={{ width: 'auto', padding: '10px 105px', margin: '5px' }} 
              onClick={() => { resetForm(); setSaveSuccess(false); setRetainArtistDetails(false); }}
            >
              Submit Another
            </button>
            <button 
              style={{ width: 'auto', padding: '10px 20px', margin: '5px' }} 
              onClick={() => { setRetainArtistDetails(true); setSaveSuccess(false); }}
            >
              Submit Another with Same Artist Details
            </button>
            {sourceView === 'Form' && (
              <button 
                style={{ width: 'auto', padding: '10px 75px', margin: '5px' }} 
                onClick={handleGoToAuthorDashboard}
              >
                Go to Author Dashboard
              </button>
            )}
          </div>
        </div>
      ) : (
        <>
          {error && <div className="error-message">{error}</div>} {/* Display error message */}
          {sourceView === 'Form' && config.locationInfo && (
            <div>
              <a className="recording-information1" style={{ marginBottom: 15 }}>Location Information:</a>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <StyledTextField
                  className="input-container"
                  label="Location Address/Postcode/Coordinates"
                  variant="filled"
                  type="text"
                  value={locationDescription}
                  onChange={handleLocationDescriptionChange}
                  style={{ flexGrow: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledButton variant='contained' color="primary" onClick={handleSearchLocation}>
                  Search
                </StyledButton>
              </div>
              {mapVisible && (
                <div ref={mapContainerRef} id="map-container" style={{ height: '300px', marginBottom: '15px' }}></div>
              )}
            </div>
          )}
          {sourceView !== 'WanderView' && config.authorInfo && (
            <div>
              <AuthorInformationFields
                initialAuthorName={authorDetails.authorName}
                initialAuthorBio={authorDetails.authorBio}
                initialAuthorWebsite={authorDetails.authorWebsite}
                initialAuthorTags={authorDetails.authorTags}
                initialAuthorImageUrl={authorDetails.authorImageUrl}
                onAuthorDetailsChange={onAuthorDetailsChange}
                setAuthorTags={handleAuthorTagsChange}
                authorKey={authorDetails.userKey}
                pinKeys={[]}
              />
            </div>
          )}
          <div className="recording-information-container">
            <a className="recording-information1">Recording Information</a>
            {config.uploadFields && (
              <RecordingUploadFields
                initialRecordingURL={recordingDetails.recordingURL}
                initialFileSize={recordingDetails.fileSize}
                initialFileName={recordingDetails.fileName}
                initialTranscription={recordingDetails.transcription}
                onRecordingDetailsChange={onRecordingDetailsChange}
                uploadPath="recordings/"
                showTranscription={true}
                selectedButton={recordingDetails.selectedButton}
              />
            )}
            {config.recordingInfo && (
              <div>
                <RecordingInformationFields
                  onRecordingInfoChange={onRecordingDetailsChange}
                  pinType={pinType}
                  initialTitle={recordingDetails.title}
                  initialDescription={recordingDetails.description}
                  initialNarrator={recordingDetails.narrator}
                  initialWhereQRCode={recordingDetails.whereQRFind}
                  initialContentRating={recordingDetails.contentRating}
                  subjectTags={recordingDetails.subjectTags}
                  genreTags={recordingDetails.genreTags}
                  availableGenreTags={availableGenreTags}
                  setSubjectTags={handleSubjectTagsChange}
                  setGenreTags={handleGenreTagsChange}
                />
              </div>
            )}
          </div>
          <FormButtons
            onCancel={onCancel}
            onSave={handleSave}
            onDelete={onDelete}
            isUploading={isUploading}
            showDelete={showDelete}
          />
        </>
      )}
    </div>
  );
};

export default RecordingForm;
