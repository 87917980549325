import { TagManager } from '../models/TagManager';
import { ContentRating } from '../types';

interface UpdateTagsParams {
  authorKey?: string;
  recordingKey?: string;
  pinKeys: string[];
  authorTags?: string[];
  initialAuthorTags?: string[];
  subjectTags?: string[];
  initialSubjectTags?: string[];
  genreTags?: string[];
  initialGenreTags?: string[];
  contentRating?: ContentRating;
  initialContentRating?: ContentRating;
}

export const updateTags = async ({
  authorKey,
  recordingKey,
  pinKeys,
  authorTags = [],
  initialAuthorTags = [],
  subjectTags = [],
  initialSubjectTags = [],
  genreTags = [],
  initialGenreTags = [],
  contentRating,
  initialContentRating
}: UpdateTagsParams) => {
  if (!authorKey && !recordingKey) {
    console.error('Missing both authorKey and recordingKey for updating tags.');
    return;
  }

  // Update content rating if it has changed
  if (recordingKey && contentRating && contentRating !== initialContentRating) {
    await TagManager.updateContentRating(recordingKey, contentRating);
  }

  // Update author tags
  if (authorKey && authorTags.length > 0) {
    const removedAuthorTags = initialAuthorTags.filter(tag => !authorTags.includes(tag));
    
    // Update tags for each pinKey
    for (const pinKey of pinKeys) {
      await TagManager.updateAuthorTags(authorKey, authorTags, initialAuthorTags, pinKey);

      for (const tag of removedAuthorTags) {
        await TagManager.removeAuthorKeyFromTag(tag, authorKey, pinKey);
      }
    }
  }

  // Update recording tags (this part remains mostly the same, just use the first pinKey if needed)
  if (recordingKey) {
    const pinKey = pinKeys[0]; // Use the first pinKey for recordings
    if (subjectTags.length > 0) {
      const removedSubjectTags = initialSubjectTags.filter(tag => !subjectTags.includes(tag));
      await TagManager.updateRecordingTags(recordingKey, subjectTags, initialSubjectTags, genreTags, initialGenreTags, pinKey);

      for (const tag of removedSubjectTags) {
        await TagManager.removeRecordingKeyFromTag(tag, recordingKey, pinKey);
      }
    }

    if (genreTags.length > 0) {
      const addedGenreTags = genreTags.filter(tag => !initialGenreTags.includes(tag));
      const removedGenreTags = initialGenreTags.filter(tag => !genreTags.includes(tag));

      for (const tag of removedGenreTags) {
        await TagManager.removeRecordingKeyFromGenreTag(tag, recordingKey, pinKey);
      }
      for (const tag of addedGenreTags) {
        await TagManager.addRecordingKeyToGenreTag(tag, recordingKey, pinKey);
      }
    }
  }
};

export const addTagsToNewRecording = async (
  authorKey: string,
  recordingKey: string,
  pinKeys: string[],
  authorTags: string[] = [],
  subjectTags: string[] = [],
  genreTags: string[] = [],
  contentRating?: ContentRating
) => {
  if (!authorKey || !recordingKey || pinKeys.length === 0) {
    console.error('Missing required keys for adding tags to new recording.');
    return;
  }

  // Add content rating if provided
  if (contentRating) {
    await TagManager.updateContentRating(recordingKey, contentRating);
  }

  // Add authorTags to the authorTags subcollection for each pinKey
  for (const pinKey of pinKeys) {
    for (const tagName of authorTags) {
      await TagManager.addAuthorTagToAuthor(authorKey, tagName, pinKey);
    }
  }

  // For recordings, use the first pinKey
  const pinKey = pinKeys[0];

  // Add subjectTags to the recordingTags subcollection
  for (const tagName of subjectTags) {
    await TagManager.addSubjectTagToRecording(recordingKey, tagName, pinKey);
  }

  // Add genreTags to the recordingTags subcollection
  for (const genreTag of genreTags) {
    await TagManager.addRecordingKeyToGenreTag(genreTag, recordingKey, pinKey);
  }
};
