import { FC } from 'react';
import { usePinData } from "../hooks/usePinData";
import { constructUrl } from "../utilities";
import { getBranchColor } from '../utilities/branchColors';
import './BranchingPinCard.css';

interface BranchingPinCardProps {
  pinKey: string;
  dragHandleProps?: any;
  isDragOver: boolean;
  onClick?: () => void;
}

export const BranchingPinCard: FC<BranchingPinCardProps> = ({ 
  pinKey, 
  dragHandleProps, 
  isDragOver,
  onClick 
}) => {

  // Early validation
  if (!pinKey) {
    console.warn('BranchingPinCard: No pinKey provided');
    return null;
  }

  const { pinData } = usePinData(pinKey);

  // Loading state
  if (!pinData) {
    return (
      <div className="branching-pin-card loading">
        <div className="card-content">
          Loading...
        </div>
      </div>
    );
  }

  const imageUrl = pinData.pinIcon ? constructUrl(pinData.pinIcon) : "/default-project-icon.png";
  const branchLabel = pinData.pinOrder ? 
    `${pinData.pinOrder.id}.${pinData.pinOrder.mainBranch}.${pinData.pinOrder.subBranch}` : 
    'No Branch';
  const branchColor = pinData.pinOrder?.mainBranch !== undefined ? 
    getBranchColor(pinData.pinOrder.mainBranch) : undefined;

  return (
    <div 
      className={`branching-pin-card ${isDragOver ? 'drag-over' : ''}`} 
      {...dragHandleProps}
      onClick={onClick}
      style={{
        borderLeft: `4px solid ${branchColor || '#757575'}`,
        cursor: onClick ? 'pointer' : 'default'
      }}
    >
      <div className="card-content">
        <img className="pin-icon" alt={pinData.name || 'pin'} src={imageUrl} />
        <div className="pin-details">
          <h3 className="pin-name">{pinData.name || 'Unnamed Pin'}</h3>
          {pinData.pinOrder && (
            <div className="branch-details">
              <span 
                className="branch-badge"
                style={{ backgroundColor: branchColor }}
              >
                {branchLabel}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
