import { v4 as uuidv4 } from 'uuid';
import { RecordingManager, AuthorManager, ProjectManager, TagManager } from "../models";
import { IRecording, IRecordingWithAuthor, ContentRating } from "../types";
import { handleFileUpload } from "../utilities";
import { storage } from '../firebase';
import { deleteObject, ref } from 'firebase/storage';
import { initiateTranscription } from './transcriptionService';
import { sendEmailNotification } from './emailService';
import QRCode from 'qrcode';
import { updateTags, addTagsToNewRecording } from '../utilities/tagUpdate';
import { SaveRecordingParams } from '../types/SaveRecordingParams';
import RecordingStore from '../stores/RecordingStore';
import { doc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Update the IRecording interface to include contentRating
declare module '../types/Recording' {
  interface IRecording {
    contentRating?: ContentRating;
  }
}

export const saveRecording = async ({
  recordingKey,
  authorDetails,
  recordingDetails,
  projectId,
  pinKey,
  albumKey,
  currentUserKey,
  sourceView,
  pinType,
  markerPosition,
  locationDescription,
  initialAuthorTags,
  shouldInitiateTranscription,
  sequenceNumber,
}: {
  recordingKey?: string;
  authorDetails: {
    authorKey?: string;
    name: string;
    bio: string;
    website: string;
    tags: string[];
    imageFile?: File;
    existingImageUrl?: string;
  };
  recordingDetails: {
    recordingURL: string;
    fileSize?: number;
    fileName?: string;
    transcription: string;
    title: string;
    description: string;
    narrator: string;
    subjectTags: string[];
    genreTags: string[];
    whereQRFind?: string;
    audioFile?: File;
    contentRating?: ContentRating;
  };
  projectId: string;
  pinKey: string;
  albumKey: string | null;
  currentUserKey?: string;
  sourceView: string;
  pinType?: string;
  markerPosition?: { lat: number; lng: number };
  locationDescription?: string;
  initialAuthorTags: string[];
  shouldInitiateTranscription?: boolean;
  sequenceNumber?: number;
}) => {
  const logs: string[] = [];
  logs.push('Starting save recording process...');

  try {
    // 1. Handle author
    let authorKey = authorDetails.authorKey;
    let authorImagePath = authorDetails.existingImageUrl || '/assets/icons/user.svg';
    
    if (authorDetails.imageFile) {
      const timestampedFileName = `${Date.now()}-${authorDetails.imageFile.name}`;
      authorImagePath = await handleFileUpload(authorDetails.imageFile, () => {}, 'images/author/', timestampedFileName);
    }

    if (authorKey) {
      await AuthorManager.updateAuthor(authorKey, {
        name: authorDetails.name,
        bio: authorDetails.bio,
        website: authorDetails.website,
        image: authorImagePath,
        tags: { authorTags: authorDetails.tags }
      });
    } else {
      authorKey = uuidv4();
      await AuthorManager.createAuthor(authorKey, {
        name: authorDetails.name,
        bio: authorDetails.bio,
        website: authorDetails.website,
        image: authorImagePath,
        tags: { authorTags: authorDetails.tags },
        isClaimed: true,
        userKey: currentUserKey
      });
    }

    // 2. Handle recording file
    let recordingPath = '';
    if (recordingDetails.audioFile) {
      const timestampedFileName = `${Date.now()}-${recordingDetails.audioFile.name}`;
      recordingPath = await handleFileUpload(recordingDetails.audioFile, () => {}, `recordings/${projectId}/`, timestampedFileName);
      // Add a log here to confirm the path is set
      if (recordingPath) {
        logs.push(`Recording path set to: ${recordingPath}`);
      } else {
        logs.push(`No new recording path set`);
      }

      // Delete old file if updating
      if (recordingKey) {
        const oldRecording = await RecordingManager.getRecording(recordingKey);
        if (oldRecording?.file?.recordingPath) {
          const oldFileRef = ref(storage, oldRecording.file.recordingPath);
          try {
            await deleteObject(oldFileRef);
            logs.push('Old recording file deleted successfully');
          } catch (deleteError) {
            console.error('Error deleting old recording file:', deleteError);
            logs.push(`Error deleting old recording file: ${deleteError}`);
          }
        }
      }
    }

    // 3. Generate and upload QR code if pinType is 'qrCode'
    let qrCodeImagePath = '';
    if (pinType === 'qrCode') {
      qrCodeImagePath = await generateAndUploadQRCode(recordingKey || uuidv4());
    }

    // 4. Prepare recording object
    let existingRecording: IRecording | null = null;
    if (recordingKey) {
      existingRecording = await RecordingManager.getRecording(recordingKey);
      logs.push(`Existing recording data: ${JSON.stringify(existingRecording, null, 2)}`);
    }

    const updatedRecording: IRecording = {
      key: recordingKey || uuidv4(),
      albumKey: albumKey || null,
      file: {
        description: recordingDetails.description,
        audioURL: recordingDetails.recordingURL || '',
        recordingPath: recordingPath || (existingRecording?.file?.recordingPath || ''),
        title: recordingDetails.title,
        size: recordingDetails.fileSize || existingRecording?.file?.size || 0,
      },
      ownership: {
        recordingNarrator: recordingDetails.narrator,
        recordingAuthor: authorKey,
        recordingOwner: currentUserKey || '',
      },
      project: projectId,
      pinKey: pinKey || '',
      tags: {
        subjectTags: recordingDetails.subjectTags,
        genreTags: recordingDetails.genreTags,
      },
      whereQRFind: recordingDetails.whereQRFind || '',
      transcription: existingRecording?.transcription || '',
      timingData: existingRecording?.timingData || {},
      transcriptionResults: existingRecording?.transcriptionResults || [],
      qrPath: qrCodeImagePath || existingRecording?.qrPath || '',
      contentRating: recordingDetails.contentRating,
    };

    logs.push(`Updated recording data before save: ${JSON.stringify(updatedRecording, null, 2)}`);

    // Create or update recording document
    const recordingData: Partial<IRecording> = {
      file: {
        title: recordingDetails.title,
        description: recordingDetails.description,
        recordingPath: recordingPath || (existingRecording?.file?.recordingPath || ''),
        audioURL: recordingDetails.recordingURL || '',
      },
      ownership: {
        recordingAuthor: authorKey,
        recordingNarrator: recordingDetails.narrator,
        recordingOwner: currentUserKey || '',
      },
      tags: {
        subjectTags: recordingDetails.subjectTags,
        genreTags: recordingDetails.genreTags,
      },
      contentRating: recordingDetails.contentRating,
      whereQRFind: recordingDetails.whereQRFind,
      transcription: recordingDetails.transcription,
      project: projectId,
      pinKey,
      albumKey,
      timingData: existingRecording?.timingData || {},
      transcriptionResults: existingRecording?.transcriptionResults || [],
      qrPath: qrCodeImagePath || existingRecording?.qrPath || '',
    };

    // Only add file size if it exists
    if (recordingDetails.fileSize) {
      (recordingData.file as any).size = recordingDetails.fileSize;
    } else if (existingRecording?.file?.size) {
      (recordingData.file as any).size = existingRecording.file.size;
    }

    // Only add sequenceNumber if it has a value
    if (sequenceNumber !== undefined) {
      recordingData.sequenceNumber = sequenceNumber;
    }

    // Update or create the recording document
    const recordingRef = recordingKey ? doc(db, 'Recordings', recordingKey) : doc(db, 'Recordings', uuidv4());
    const recordingId = recordingRef.id;

    if (recordingKey) {
      await updateDoc(recordingRef, recordingData);
    } else {
      await setDoc(recordingRef, recordingData);
    }

    // Update content rating tags if a content rating is provided
    if (recordingDetails.contentRating) {
      await TagManager.updateContentRating(recordingId, recordingDetails.contentRating);
    }

    // Update RecordingStore
    const updatedRecordingWithAuthor: IRecordingWithAuthor = {
      ...updatedRecording,
      author: {
        authorKey: authorKey,
        name: authorDetails.name,
        bio: authorDetails.bio,
        website: authorDetails.website,
        image: authorImagePath,
        tags: { authorTags: authorDetails.tags },
        isClaimed: true,
        userKey: currentUserKey
      }
    };

    if (recordingKey) {
      RecordingStore.updateRecording(recordingKey, updatedRecordingWithAuthor);
    } else {
      RecordingStore.addRecording(updatedRecordingWithAuthor);
    }

    // 6. Update tags
    const filteredAuthorTags = authorDetails.tags.filter(tag => tag.trim() !== '');
    const filteredSubjectTags = recordingDetails.subjectTags.filter(tag => tag.trim() !== '');
    const filteredGenreTags = recordingDetails.genreTags.filter(tag => tag.trim() !== '');

    if (recordingKey) {
      await updateTags({
        authorKey: updatedRecording.ownership?.recordingAuthor || undefined,
        recordingKey: updatedRecording.key || '',
        pinKeys: [pinKey || ''],
        authorTags: filteredAuthorTags,
        initialAuthorTags: initialAuthorTags,
        subjectTags: filteredSubjectTags,
        initialSubjectTags: [],
        genreTags: filteredGenreTags,
        initialGenreTags: []
      });
    } else {
      if (filteredAuthorTags.length > 0 || filteredSubjectTags.length > 0 || filteredGenreTags.length > 0) {
        await addTagsToNewRecording(
          updatedRecording.ownership?.recordingAuthor || '',
          updatedRecording.key || '',
          [pinKey || ''],
          filteredAuthorTags,
          filteredSubjectTags,
          filteredGenreTags
        );
      }
    }

    // 7. Update project audio size
    if (recordingDetails.audioFile?.size && projectId) {
      await ProjectManager.updateProjectAudioSize(projectId, recordingDetails.audioFile.size);
    }

    // 8. Send email notification for 'Form' source view
    if (sourceView === 'Form' && projectId) {
      const locationDesc = markerPosition 
        ? `Latitude: ${markerPosition.lat}, Longitude: ${markerPosition.lng}`
        : locationDescription;

      const additionalInfo = `
        User: ${currentUserKey || 'Unknown'},
        Author: ${authorDetails.name || 'Unknown'},
        Recording Title: ${recordingDetails.title || 'Untitled'},
        Project ID: ${projectId || 'Unknown'}
      `;

      const emailBody = `
        The form has been submitted with the following details:
        - Location Description: ${locationDesc}
        - Additional Info: ${additionalInfo}
        - Logs: ${logs.join('\n')}
      `;

      if (locationDesc) {
        await sendEmailNotification(locationDesc, emailBody);
      } else {
        await sendEmailNotification('No location description', emailBody);
      }
    }

    // 9. Initiate transcription if needed
    if (shouldInitiateTranscription) {
      const filePathToTranscribe = updatedRecording.file?.recordingPath;
      if (filePathToTranscribe) {
        initiateTranscription({
          fileName: filePathToTranscribe,
          recordingId: updatedRecording.key,
          transcriptionText: updatedRecording.transcription || '',
          timingData: updatedRecording.timingData || {}
        }).catch(error => {
          console.error('Error initiating transcription:', error);
          logs.push(`Error initiating transcription: ${error}`);
        });
      } else {
        console.warn('No file path available for transcription');
        logs.push('No file path available for transcription');
      }
    }

    logs.push('Save successful');
    logs.push(`Final updated recording: ${JSON.stringify(updatedRecordingWithAuthor, null, 2)}`);
    return { updatedRecording: updatedRecordingWithAuthor, logs };

  } catch (error) {
    logs.push(`Error in save process: ${error}`);
    console.error('Error in save process:', error);

    // Send email with error logs
    const errorEmailBody = `
      An error occurred during the save process:
      - Error: ${error}
      - Logs: ${logs.join('\n')}
    `;
    await sendEmailNotification('Save Error Notification', errorEmailBody);

    throw error;
  }
};

const generateAndUploadQRCode = async (data: string): Promise<string> => {
  const qrCode = await QRCode.toDataURL(data, {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    renderer: {
      quality: 0.92
    }
  });
  return qrCode;
};

