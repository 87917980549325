import { db } from '../firebase/firebase';
import { collection, getDocs, getDoc, doc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { Album } from '../types';
import { ContentRating } from '../types/Tags';
import { RecordingManager } from './RecordingManager';

// Define TypeScript interfaces for your album and other types as needed

export const AlbumManager = {
  getAllAlbums: async (): Promise<Album[]> => {
    try {
      const querySnapshot = await getDocs(collection(db, 'albums'));
      const albums: Album[] = [];
      querySnapshot.forEach((doc) => {
        const album = { albumKey: doc.id, ...doc.data() } as Album;
        albums.push(album);
      });
      return albums;
    } catch (error) {
      console.error("Error getting albums:", error);
      return [];
    }
  },

  getAlbum: async (albumKey: string): Promise<Album | null> => {
    try {
      const albumDocRef = doc(db, 'Albums', albumKey);
      const albumDoc = await getDoc(albumDocRef);
      if (!albumDoc.exists()) {
        return null;
      }
      return { albumKey: albumDoc.id, ...albumDoc.data() } as Album;
    } catch (error) {
      console.error("Error fetching album:", error);
      return null;
    }
  },

  removeAlbum: async (albumKey: string): Promise<void> => {
    try {
      const albumDocRef = doc(db, 'Albums', albumKey);
      await deleteDoc(albumDocRef);
    } catch (error) {
      console.error("Error removing album:", error);
      throw error;
    }
  },

  getAlbumByPinKey: async (albumKey: string): Promise<Album | null> => {
    try {
      const albumDocRef = doc(db, 'Albums', albumKey);
      const albumDoc = await getDoc(albumDocRef);
      if (!albumDoc.exists()) {
        return null;
      }
      return { albumKey: albumDoc.id, ...albumDoc.data() } as Album;
    } catch (error) {
      console.error("Error fetching album by album key:", error);
      return null;
    }
  },

  createAlbum: async (newAlbum: Album): Promise<Album> => {
    try {
      const albumDocRef = doc(db, 'Albums', newAlbum.albumKey!);
      await setDoc(albumDocRef, newAlbum);
      return { ...newAlbum, albumKey: albumDocRef.id }; // Ensure to return the new album object
    } catch (error) {
      console.error("Error creating album:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  },

  updateAlbumContentRating: async (albumKey: string): Promise<void> => {
    try {
      // Get all recordings for this album
      const recordings = await RecordingManager.fetchRecordingsByAlbumKey(albumKey);
      
      // Track all unique content ratings and calculate highest rating
      const uniqueRatings = new Set<ContentRating>();
      let highestRating: ContentRating = 'Family';
      let hasAdultContent = false;
      let hasMixedContent = false;
      
      // First pass: collect all ratings
      for (const recording of recordings) {
        if (recording.contentRating) {
          uniqueRatings.add(recording.contentRating);
          if (recording.contentRating === 'Adult') {
            hasAdultContent = true;
            highestRating = 'Adult';
          } else if (recording.contentRating === 'Young Adult' && highestRating === 'Family') {
            highestRating = 'Young Adult';
          }
        }
      }

      // Determine if content is mixed (has both adult and non-adult content)
      if (hasAdultContent && uniqueRatings.size > 1) {
        hasMixedContent = true;
      }

      // Convert Set to array for storage
      const contentRatings = Array.from(uniqueRatings);

      // Update album document
      const albumRef = doc(db, 'Albums', albumKey);
      await updateDoc(albumRef, {
        contentRating: highestRating,
        hasAdultContent,
        hasMixedContent,
        contentRatings
      });

    } catch (error) {
      console.error("Error updating album content rating:", error);
      throw error;
    }
  },
};

export default AlbumManager;
