import { createTheme } from '@mui/material/styles';

export type ThemeContexts = 'light' | 'dark' | undefined;

export interface Theme {
  colors: {
    background: string;
    text: string;
    primary: string;
    secondary: string;
    error: string;
    palette: {
      main: string;
      icon: string;
      border: string;
      input: {
        background: string;
        text: string;
      };
      button: {
        primary: string;
        secondary: string;
        text: string;
      };
      dropdown: {
        background: string;
        hover: string;
        selected: string;
      };
    };
  };
  spacing: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  typography: {
    h1: {
      fontSize: string;
      fontWeight: number;
    };
    h2: {
      fontSize: string;
      fontWeight: number;
    };
    body1: {
      fontSize: string;
      fontWeight: number;
    };
    body2: {
      fontSize: string;
      fontWeight: number;
    };
  };
  borderRadius: {
    sm: string;
    md: string;
    lg: string;
  };
}

export const lightTheme: Theme = {
  colors: {
    background: '#ffffff',
    text: '#000000',
    primary: '#1976d2',
    secondary: '#dc004e',
    error: '#f44336',
    palette: {
      main: '#1976d2',
      icon: '#000000',
      border: '#e0e0e0',
      input: {
        background: '#f5f5f5',
        text: '#000000',
      },
      button: {
        primary: '#1976d2',
        secondary: '#dc004e',
        text: '#ffffff',
      },
      dropdown: {
        background: '#f5f5f5',
        hover: '#e0e0e0',
        selected: '#bbdefb',
      },
    },
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
  borderRadius: {
    sm: '4px',
    md: '8px',
    lg: '16px',
  },
};

export const darkTheme: Theme = {
  colors: {
    background: '#121212',
    text: '#ffffff',
    primary: '#90caf9',
    secondary: '#f48fb1',
    error: '#f44336',
    palette: {
      main: '#90caf9',
      icon: '#ffffff',
      border: '#424242',
      input: {
        background: '#1e1e1e',
        text: '#ffffff',
      },
      button: {
        primary: '#90caf9',
        secondary: '#f48fb1',
        text: '#000000',
      },
      dropdown: {
        background: '#1e1e1e',
        hover: '#424242',
        selected: '#1976d2',
      },
    },
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
  borderRadius: {
    sm: '4px',
    md: '8px',
    lg: '16px',
  },
};
