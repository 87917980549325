import { useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { IPin } from '../types';
import pinStore from '../stores/PinStore';
import { PinManager } from '../models/PinManager';

interface UseBranchingDragDropProps {
  selectedProject: string | null;
  sortedPins: IPin[];
}

export const useBranchingDragDrop = ({ selectedProject, sortedPins }: UseBranchingDragDropProps) => {
  const [isOrphanDragging, setIsOrphanDragging] = useState(false);
  const [dragOverPinKey, setDragOverPinKey] = useState<string | null>(null);

  const onDragStart = () => {
    setIsOrphanDragging(true);
  };

  const onDragUpdate = (update: any) => {
    if (!update.destination) {
      setDragOverPinKey(null);
      return;
    }
    const destinationIndex = update.destination.index;
    const destinationPin = sortedPins[destinationIndex];
    setDragOverPinKey(destinationPin?.pinKey || null);
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;
    setIsOrphanDragging(false);
    setDragOverPinKey(null);

    if (!destination || !selectedProject) return;

    const sourcePin = sortedPins[source.index];
    const destinationPin = sortedPins[destination.index];

    if (!sourcePin?.pinOrder || !destinationPin?.pinOrder) return;

    // Only allow reordering within the same level and parent
    if (sourcePin.pinOrder.mainBranch !== destinationPin.pinOrder.mainBranch ||
        sourcePin.branchParentPinKey !== destinationPin.branchParentPinKey) {
      return;
    }

    // Calculate new subBranch number based on destination
    const newSubBranch = destinationPin.pinOrder.subBranch;
    
    const updatedPin = {
      ...sourcePin,
      pinOrder: {
        ...sourcePin.pinOrder,
        subBranch: newSubBranch
      }
    };

    // Update store and database
    pinStore.setPin(sourcePin.pinKey, updatedPin);
    await PinManager.updatePin(updatedPin);
  };

  return {
    isOrphanDragging,
    dragOverPinKey,
    onDragStart,
    onDragUpdate,
    onDragEnd
  };
};
